import React, { useState, useMemo } from 'react';
const { setDay, setWeek, startOfWeek, endOfWeek } = require('date-fns');
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import { Send as SendIcon } from '@material-ui/icons';
import { CheckCircleOutline, HelpOutline, HighlightOffOutlined, SyncOutlined } from '@material-ui/icons';
import { ITEM_TYPES, FREIGHT_TYPES } from '@/components/ShipmentForm/constants/freightTypes';
import { asBrowserDate } from '@/utilities/convertToISO';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

export const useColumns = ({ callbacks, loading }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Data',
                id: 'data-columns',
                columns: [
                    {
                        Header: 'Transaction',
                        id: 'transaction',
                        span: false,
                        accessor: (error) => error.id,
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper_business_name',
                        span: false,
                        accessor: (error) => error.override?.shipper?.business_name,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Carrier',
                        id: 'carrier_business_name',
                        span: false,
                        accessor: (error) => error.override?.carrier?.business_name,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Raw',
                        id: 'edi_key',
                        accessor: (error) => error.integration.edi_key,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Artifact',
                        id: 'artifact_key',
                        span: false,
                        accessor: (error) => error.integration.artifact_key,
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        span: false,
                        disableSortBy: false,
                        Cell: ({ row }) => {
                            const error = row.original;
                            const label = error.integration.status;
                            let Icon = HelpOutline;
                            let color = 'black';
                            switch (error.integration.status) {
                                case 'FAILED':
                                    color = colors.reds.cancelled;
                                    Icon = HighlightOffOutlined;
                                    break;
                            }

                            return (
                                <Grid
                                    container
                                    direction="row"
                                    css={css`
                                        color: ${color};
                                        height: 100%;
                                        align-items: center;
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                            margin-right: 4px;
                                        `}
                                    >
                                        <Icon />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        css={css`
                                            flex: 0;
                                            flex-basis: 0;
                                        `}
                                    >
                                        <BodyText>{label}</BodyText>
                                    </Grid>
                                </Grid>
                            );
                        },
                    },
                    {
                        Header: 'Created At',
                        id: 'created_at',
                        canSort: false,
                        accessor: (error) => {
                            return error.created_at ? dateShort.format(new Date(error.created_at)) : '--';
                        },
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Message',
                        id: 'message',
                        canSort: false,
                        accessor: (error) => {
                            return error.message;
                        },
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Sticky',
                id: 'sticky-group',
                columns: [
                    {
                        Header: 'Retry',
                        id: 'action-1',
                        width: 75,
                        Cell: ({ row }) => {
                            const error = row.original;

                            return (
                                <div
                                    css={css`
                                        color: #59b863;
                                        font-weight: 600;
                                        display: flex-start;
                                        justify-content: flex-start;
                                        align-items: center;
                                    `}
                                >
                                    <IconButton
                                        color="primary"
                                        disabled={loading.retry}
                                        onClose={() => callbacks.retry(error)}
                                        css={css`
                                            font-weight: 600;
                                            padding: 4px;
                                            justify-content: center;
                                        `}
                                    >
                                        <ReplayIcon />
                                    </IconButton>
                                </div>
                            );
                        },
                        span: false,
                    },
                    {
                        Header: 'Job',
                        id: 'action-2',
                        width: 75,
                        Cell: ({ row }) => {
                            const error = row.original;

                            return (
                                <div
                                    css={css`
                                        color: #59b863;
                                        font-weight: 600;
                                        display: flex-start;
                                        justify-content: flex-start;
                                        align-items: center;
                                    `}
                                >
                                    <IconButton
                                        color="primary"
                                        onClose={() => callbacks.gotoJob(error)}
                                        css={css`
                                            font-weight: 600;
                                            padding: 4px;
                                            justify-content: center;
                                        `}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </div>
                            );
                        },
                        span: false,
                    },
                ],
            },
        ];
    }, [callbacks]);
};
