import React, { useState, useEffect } from 'react';
import { ORDER_BY_ID, ORDER_NOTES } from '../queries';

import { Grid, Typography, Button } from '@material-ui/core';
import { css } from '@emotion/react';

import orderStatusStrings from '@/constants/orderStatusStrings';
import { OnwardCard } from '../Card';
import { useMutation } from '@apollo/client';
import { ADD_ORDER_NOTE } from '../mutations';
import { useClientUser } from '@/hooks';
import { greys } from '@/styles/colors';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { colors } from '@/styles';
import { startCase } from 'lodash';
import AddNoteModal from './AddNoteModal';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const timestamp = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

export const HR = styled.div`
    width: 100%;
    margin-top: 22px;
    margin-bottom: 22px;
    border: 1px solid #e2e2e2;
`;

const Note = (note, isLast, accountTypeCapital) => {
    // Notes for invoices
    if (note?.invoice_id) {
        return (
            <>
                <Grid container direction="row">
                    <Grid container item direction="column" xs={4}>
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                System
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {dateShort.format(new Date(note.created_at))}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {timestamp.format(new Date(note.created_at))}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            css={css`
                                font-weight: 500;
                                font-style: italic;
                            `}
                        >
                            <a
                                href={`${window.location.origin}/carrier/accounting/marketplace/invoice/${note?.invoice_id}`}
                                target="_blank"
                            >
                                An invoice was added to this order
                            </a>
                        </Typography>
                    </Grid>
                </Grid>

                {!isLast && <HR />}
            </>
        );
    }

    const source_user_type = note?.source_user_type;

    return (
        <>
            <Grid container direction="row">
                <Grid container item direction="column" xs={4}>
                    {note?.private_to === accountTypeCapital && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: orange;
                                    font-weight: 500;
                                `}
                            >
                                Private
                            </Typography>
                        </Grid>
                    )}

                    {note?.requires_acknowledgement && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${colors.greens.primary};
                                    font-weight: 500;
                                `}
                            >
                                Onward/Shipper Notified
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                line-break: anywhere;
                            `}
                        >
                            {source_user_type
                                ? source_user_type === 'Admin'
                                    ? 'Onward'
                                    : `(${source_user_type}) ${note?.user?.username}`
                                : 'System'}
                        </Typography>
                    </Grid>

                    {note.source_user_type !== 'Admin' && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                {note?.user?.email}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {dateShort.format(new Date(note.created_at))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {timestamp.format(new Date(note.created_at))}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" item xs={8}>
                    {note?.type && (
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                `}
                            >
                                {note?.type}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                ${note?.is_acknowledgement &&
                                `
                                    font-style: italic;    
                                `}
                            `}
                        >
                            {note?.is_acknowledgement ? `Acknowledged by ${note?.source_user_type}` : note.note}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {!isLast && <HR />}
        </>
    );
};

const initModalState = {
    open: false,
    private: false,
    notify: false,
    text: '',
};

function Notes({ order, notes, privacyExp }) {
    const { user_id, accountType, isOnwardAdmin, client_id } = useClientUser();
    const [modalState, setModalState] = useState(initModalState);

    const [addOrderNote] = useMutation(ADD_ORDER_NOTE, {
        update: (cache, { data: { insert_notes } }) => {
            cache.updateQuery(
                {
                    query: ORDER_NOTES,
                    variables: {
                        order_id: order.order_id,
                        notes_privacy: privacyExp,
                    },
                },
                (data) => {
                    const added = insert_notes?.returning || [];
                    const clone = data.notes || [];
                    return {
                        ...data,
                        notes: [...added, ...clone],
                    };
                }
            );
        },
    });

    const accountTypeCapital = startCase(accountType);

    const handleSave = () => {
        addOrderNote({
            variables: {
                note: {
                    order_id: order.order_id,
                    source_user_type: accountTypeCapital,
                    source_user_id: user_id,
                    note: modalState.text,
                    is_acknowledgement: false,
                    requires_acknowledgement: modalState.notify,
                    acknowledged_by_shipper: false,
                    acknowledged_by_admin: false,
                    private_to: modalState.private ? accountTypeCapital : null,
                    type: modalState.type,
                },
            },
            onError: (err) => {
                console.error(err);
                Sentry.captureException(err);
            },
        });

        setModalState(initModalState);
    };

    return (
        <>
            <Grid
                container
                className="overflow-auto h-100"
                css={css`
                    padding-bottom: 10px;
                `}
            >
                <Grid item sm={1}></Grid>
                <Grid container sm={10}>
                    <Grid
                        item
                        sm={12}
                        className="d-flex justify-content-between align-items-center mt-4"
                        css={css`
                            margin-bottom: 55px;
                        `}
                    >
                        <Typography variant="h1">
                            {order.order_number} / {order.po_number}
                        </Typography>
                        <Button
                            onClick={() =>
                                setModalState((prevState) => ({
                                    ...prevState,
                                    open: true,
                                }))
                            }
                            variant="contained"
                            color="primary"
                            css={css`
                                width: 115px;
                                margin-left: auto;
                                margin-right: 18px;
                            `}
                            disabled={isOnwardAdmin}
                        >
                            <Typography variant="body1">Add Note</Typography>
                        </Button>
                        <Typography variant="subtitle1">{orderStatusStrings[order.order_status]}</Typography>
                    </Grid>

                    {!notes?.length ? (
                        <OnwardCard
                            css={css`
                                margin-bottom: 30px;
                            `}
                        >
                            <span
                                css={css`
                                    font-style: italic;
                                `}
                            >
                                No notes yet
                            </span>
                        </OnwardCard>
                    ) : (
                        <>
                            <OnwardCard>
                                {(notes || [])?.map((note, idx, arr) =>
                                    Note(note, arr.length - 1 === idx, accountTypeCapital)
                                )}
                            </OnwardCard>
                        </>
                    )}
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>

            {modalState.open && (
                <AddNoteModal
                    state={modalState}
                    setState={setModalState}
                    handleSave={handleSave}
                    client_id={client_id}
                    order={order}
                />
            )}
        </>
    );
}

export default Notes;
