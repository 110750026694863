import { gql } from '@apollo/client';
import { LOCATION_FIELDS } from './locations';

const CARRIER_QUERY = gql`
    query GetClients {
        clients(
            where: { approved: { _eq: true }, deactivated: { _eq: false }, user: { roles: { _has_key: "CARRIER" } } }
        ) {
            client_id
            approved
            business_name
            deactivated
            user {
                user_id
                roles
            }
        }
    }
`;

export { CARRIER_QUERY };

export const CLIENT_FIELDS = gql`
    fragment ClientFields on clients {
        approved
        auto_assigned_carrier
        auto_assign_zip_type
        billcom_vendor_id
        billcom_vendor_id
        billing_address
        billing_address_unit
        billing_city
        billing_email
        billing_name
        billing_phone
        billing_state
        billing_zip
        blacklist
        blacklisted_clients
        bol_fine_print
        business_address
        business_address_unit
        business_city
        business_ein
        business_name
        business_phone
        business_state
        business_zip
        cc_valid
        client_id
        commingled_internal_mandatory
        created_at
        customer_comms_range_start
        customer_comms_range_end
        customer_messages
        data_share
        deactivated
        default_filter_by_my_state
        default_store_hours
        delivery_filters
        dispatch_integration_type
        dispatch_integration_meta
        dot_number
        email
        fleet_size
        insurance_form
        insurance_form_expiration
        insurance_form_uploaded_at
        job_title
        last_modified_at
        last_logged_in
        last_logged_in_formatted
        legacy_client_id
        mc_num
        onward_form
        onward_form_expiration
        onward_form_uploaded_at
        order_drafts
        partner_shipper_id
        partnerships_enabled
        payment_type
        personal_email
        preferences_bypass_optimize
        preferences_delivery_timeframe
        preferences_service_time
        preferences_service_time_curbside
        preferences_service_time_first_dry_space
        preferences_service_time_incr
        preferences_service_time_room_of_choice
        preferences_service_time_white_glove
        preferences_timeframe_buffer
        preferences_timeframe_buffer_apply
        preferences_timeframe_round
        preferences_route_eod_limit
        preferences_route_eod_limit_next_day
        preferences_route_time_limit
        preferences_start_estimate
        preferences_end_estimate
        preferences_use_service_default_as_minimum
        preferences_warehouse_return_estimate
        preferences_next_day_returns
        profile_img
        profile_img_color
        qbo_tags
        rnbr
        secondary_email
        secondary_phone
        secondary_username
        settings
        stores
        stripe_user_id
        stripe_payment_method
        support_email
        support_phone
        teammates
        test_acc
        username
        w_form
        w_form_expiration
        w_form_uploaded_at
        workers_comp_form
        workers_comp_form_expiration
        workers_comp_form_uploaded_at
        july_fourth
        xmas_eve
        xmas
        new_years_eve
        new_years_day
        labor_day
        memorial_day
        map_shapes
        thanksgiving
        white_label_color
        white_label_img
    }
`;

export const QUERY_BY_PK_WITH_USER = gql`
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}
    query clientByPkWithUser($client_id: uuid!) {
        clients_by_pk(client_id: $client_id) {
            ...ClientFields
            user {
                circles
                device_settings
                email
                roles
                created_at
                user_id
                username
                legacy_user_id
            }
            locations {
                ...LocationFields
            }
        }
    }
`;
