import { gql } from '@apollo/client';

export const INTEGRATION_ERROR_FIELDS = gql`
    fragment IntegrationErrorFields on integration_errors {
        client_id
        count
        error_id
        id
        message
        type
        created_at
    }
`;
