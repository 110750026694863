import { WMS } from '@/constants/featureFlags';
import { useClientUser } from '@/hooks';
import { enrichManifest } from '@/utilities/enrichManifest';
import { useMutation, useQuery } from '@apollo/client';
import { EXCEPTION_TYPES } from '@onward-delivery/core';
import { captureException } from '@sentry/react';
import React, { createContext, useMemo, useState } from 'react';
import { DAYS_30, MANIFEST_SEARCHABLE, ORDER_SEARCHABLE, TABS } from './constants';
import { INSERT_MANIFEST, GET_MANIFESTS, GET_ORDERS, UPSERT_ITEMS, GET_EXTERNAL_MANIFESTS } from './graphql';
import { omit } from 'lodash';
import { ITEM_READONLY_FIELDS } from '@/constants/readonlyFields';

export const Context = createContext();

const ContextProvider = ({ children }) => {
    const { user_id: client_id, locations: warehouses, circles, shipping_partners } = useClientUser();
    const [tab, setTab] = useState(TABS.INBOUND);
    const [newManifest, setNewManifest] = useState(null);
    const [searchManifests, setSearchManifests] = useState('');
    const [searchOrders, setSearchOrders] = useState('');
    const [filters, setFilters] = useState(tab?.defaultFilters || {});
    const [notification, setNotification] = useState({});
    const [hasExternal, setHasExternal] = useState(false);

    const [manifestCutoff, orderCutoff] = useMemo(() => {
        return [new Date(Date.now() - DAYS_30).toISOString(), new Date(Date.now() - DAYS_30 * 3).toISOString()];
    }, []);

    const manifestFilters = useMemo(() => {
        const baseFilter = { type: { _in: filters.type ? [filters.type] : tab.typeOptions } };

        if (!searchManifests) {
            return [baseFilter];
        }

        const searchFilter = {
            _or: MANIFEST_SEARCHABLE.map((attr) => {
                if (attr.includes('.')) {
                    const parts = attr.split('.');
                    return parts.reduceRight((acc, part, index) => {
                        if (index === parts.length - 1) {
                            return { [part]: { _ilike: `%${searchManifests.trim()}%` } };
                        }
                        // if (part === 'items') {
                        //     return { [part]: { _some: acc } };
                        // }
                        return { [part]: acc };
                    }, {});
                }
                return { [attr]: { _ilike: `%${searchManifests.trim()}%` } };
            }),
        };

        return [baseFilter, searchFilter];
    }, [tab, searchManifests, filters.type]);

    const orderFilters = useMemo(() => {
        if (!newManifest?.type) return null;

        let orderFilters = [];

        if (!circles?.[WMS]) {
            orderFilters.push(...[{ oms: { _eq: false } }, { carrier_id: { _eq: client_id } }]);
        }

        if (searchOrders) {
            orderFilters.push({
                _or: [
                    ...ORDER_SEARCHABLE.map((attr) => {
                        if (attr.includes('.')) {
                            const parts = attr.split('.');
                            return parts.reduceRight((acc, part, index) => {
                                if (index === parts.length - 1) {
                                    return { [part]: { _ilike: `%${searchOrders.trim()}%` } };
                                }
                                return { [part]: acc };
                            }, {});
                        }
                        return { [attr]: { _ilike: `%${searchOrders.trim()}%` } };
                    }),
                ],
            });
        }

        switch (newManifest.type) {
            case 'INBOUND':
                orderFilters.push(
                    ...[
                        {
                            _or: [
                                {
                                    itemsByOrderId: {
                                        _not: { manifests: { manifest: { type: { _in: ['INBOUND', 'CROSS_DOCK'] } } } },
                                    },
                                },
                                {
                                    itemsByOrderId: {
                                        exceptions: {
                                            exception: {
                                                reported_at: { _eq: 'PICKUP' },
                                                type: {
                                                    _in: [
                                                        EXCEPTION_TYPES.OVERAGE,
                                                        EXCEPTION_TYPES.SHORTAGE,
                                                        EXCEPTION_TYPES.DAMAGED,
                                                    ],
                                                },
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            _not: { routes: { type: { _eq: 'PICKUP' } } },
                        },
                    ]
                );
                break;
            case 'CROSS_DOCK':
                orderFilters.push(
                    ...[
                        {
                            itemsByOrderId: {
                                _not: { manifests: { manifest: { type: { _in: ['INBOUND', 'CROSS_DOCK'] } } } },
                            },
                        },
                        { _not: { routes: { type: { _eq: 'PICKUP' } } } },
                        { wh_events: { action: { _ilike: `%:ADD_CD` } } },
                    ]
                );
                break;
            case 'OUTBOUND':
                orderFilters.push(
                    ...[
                        {
                            _not: { routes: {} },
                        },
                        {
                            itemsByOrderId: { _not: { manifests: { manifest: { type: { _eq: 'OUTBOUND' } } } } },
                        },
                        {
                            job_type: { _eq: 'SHIPMENT' },
                        },
                    ]
                );
                break;
            case 'RETURN_TO_SENDER':
                orderFilters.push(
                    ...[
                        {
                            itemsByOrderId: {
                                _not: { manifests: { manifest: { type: { _eq: 'RETURN_TO_SENDER' } } } },
                            },
                        },
                        {
                            itemsByOrderId: {
                                exceptions: {
                                    exception: {
                                        reported_at: { _eq: 'PICKUP' },
                                        type: {
                                            _in: [
                                                EXCEPTION_TYPES.OVERAGE,
                                                EXCEPTION_TYPES.SHORTAGE,
                                                EXCEPTION_TYPES.DAMAGED,
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ]
                );
                break;
            case 'WILL_CALL':
                orderFilters.push(
                    ...[
                        { itemsByOrderId: { _not: { manifests: { manifest: { type: { _eq: 'WILL_CALL' } } } } } },
                        { job_type: { _in: ['WILL_CALL', 'PICKUP_AND_WILL_CALL'] } },
                    ]
                );
                break;
        }

        return orderFilters;
    }, [circles, client_id, searchOrders, newManifest]);

    const { data, loading: queryLoading } = useQuery(GET_MANIFESTS, {
        variables: {
            client_id,
            cutoff: searchManifests ? '2020-01-01T12:00:00+00:00' : manifestCutoff,
            filters: manifestFilters,
        },
    });

    const { data: externalData, loading: externalQueryLoading } = useQuery(GET_EXTERNAL_MANIFESTS, {
        variables: {
            client_id,
            cutoff: manifestCutoff,
            filters: manifestFilters,
        },
        onCompleted: (data) => {
            setHasExternal(data.manifests.length > 0);
        },
    });

    const manifests = useMemo(() => {
        const allManifests = (data?.manifests || []).map((m) => enrichManifest(m));
        return allManifests.filter((manifest) => !filters.status || manifest.status === filters.status);
    }, [data, filters.status]);

    const externalManifests = useMemo(() => {
        const allManifests = (externalData?.manifests || []).map((m) => enrichManifest(m));
        return allManifests.filter((manifest) => !filters.status || manifest.status === filters.status);
    }, [externalData, filters.status]);

    const {
        data: ordersData,
        loading: ordersLoading,
        refetch: refetchOrders,
    } = useQuery(GET_ORDERS, {
        variables: {
            client_id,
            cutoff: searchOrders ? '2020-01-01T12:00:00+00:00' : orderCutoff,
            filters: orderFilters,
        },
        skip: !orderFilters,
    });

    const orders = useMemo(() => {
        return ordersData?.orders || [];
    }, [ordersData]);

    const [insertManifest, { loading: createLoading }] = useMutation(INSERT_MANIFEST, {
        update: (cache, { data: { created } }) => {
            cache.updateQuery(
                {
                    query: GET_MANIFESTS,
                    variables: {
                        client_id,
                        cutoff: manifestCutoff,
                        filters: manifestFilters,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        manifests: [created, ...data.manifests],
                    };
                }
            );
        },
        onError: (error) => {
            console.error(error);
            captureException(error);
            setNotification({ severity: 'error', message: 'Error creating manifest' });
        },
        onCompleted: ({ created }) => {
            setNotification({ severity: 'success', message: `Manifest created - ${created.manifest_number}` });
        },
    });

    const [upsertItems, { loading: splitLoading }] = useMutation(UPSERT_ITEMS, {
        onError: (error) => {
            console.error(error);
            captureException(error);
            setNotification({ severity: 'error', message: 'Error splitting items' });
        },
        onCompleted: ({ created }) => {
            refetchOrders();
        },
    });

    const createManifest = () => {
        const { items: selectedItems, includedShippers, share_manifest, ...rest } = newManifest || {};

        const items = Object.entries(selectedItems || {})
            .filter(([_, selected]) => selected)
            .map(([item_id]) => ({
                item_id,
            }));

        const shareManifest =
            share_manifest ||
            (includedShippers || []).some((shipperId) =>
                shipping_partners.some((partner) => partner.shipper_id === shipperId && partner.share_manifests)
            );

        return insertManifest({
            variables: {
                manifest: {
                    client_id,
                    type: tab.value,
                    source: 'MANUAL',
                    items: {
                        data: items,
                    },
                    share_manifest: shareManifest,
                    ...rest,
                },
            },
        });
    };

    const splitItem = (item, _splitAmount, manifestType) => {
        const splitAmount = parseInt(_splitAmount);
        const palletInfoToCopy = omit(item.pallet, [
            'pallet_id',
            'pallet_number',
            '__typename',
            'warehouse_location',
            'logs',
        ]);
        const { item_id, quantity, pallet_id, ...rest } = omit(item, ITEM_READONLY_FIELDS);

        const item1 = { ...rest, quantity: quantity - splitAmount, item_id: item_id, pallet_id: pallet_id };

        const item2 = {
            ...rest,
            quantity: splitAmount,
            //If creating will-call manifest split onto new pallet for tracking picking/staging separate from original.
            ...(manifestType === 'WILL_CALL'
                ? {
                      pallet: item.pallet
                          ? {
                                data: palletInfoToCopy,
                                on_conflict: {
                                    constraint: 'pallet_items_pkey',
                                    update_columns: [
                                        'pallet_name',
                                        'type',
                                        'warehouse_id',
                                        'warehouse_status',
                                        'is_pool',
                                    ],
                                },
                            }
                          : {},
                  }
                : {}),
        };

        return upsertItems({
            variables: {
                items: [item1, item2],
            },
        });
    };

    return (
        <Context.Provider
            value={{
                state: {
                    tab,
                    newManifest,
                    manifests,
                    externalManifests,
                    searchManifests,
                    orders,
                    searchOrders,
                    notification,
                    filters,
                    warehouses,
                    hasExternal,
                },
                loading: {
                    queryLoading,
                    createLoading,
                    ordersLoading,
                    externalQueryLoading,
                },
                callbacks: {
                    setTab,
                    setNewManifest,
                    createManifest,
                    setSearchManifests,
                    setSearchOrders,
                    clearNotification: () => setNotification({}),
                    setFilters,
                    splitItem,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
