import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { useFormatters } from './hooks';
import { Header1, Body1, Tag, Step, SubStepDesktop, SubStepMobile, Anchor } from './blocks';
import { Alert } from '@material-ui/lab';
import { OnwardLink } from '@/styles/blocks';
import { formatInTimeZone } from 'date-fns-tz';

export default function Received({ isDesktop, isLoading, warehouse, before, after, order, tz, callbacks, readOnly, }) {
    const STEPS = [
        {
            description: `Mark warehouse status as 'Received'`,
            complete: ({ order, warehouse, before, after }) => {
                return before?.received_date && before?.status === 'RECEIVED';
            },
            ...(!readOnly ? {
                cta: 'Mark Received',
                secondaryCta: 'Add Exception',
                callback: ({ order, before, after }) => {
                    callbacks.editReceiveEvent({ event: before });
                },
                secondaryCallback: ({ order, before, after }) => {
                    callbacks.createException({
                        type: 'PICKUP',
                        action: 'START:RECEIVING',
                    });
                },
            } : {})
        },
    ];

    const formatters = useFormatters(order);
    const isComplete = useMemo(() => {
        return STEPS.map((step) => {
            return step.complete({ order, before, after, warehouse });
        });
    }, [before, after, order]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete.every((x) => x)}
            inProgress={!isComplete.every((x) => x)}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Received</Header1>
                        </Grid>
                        {before?.received_date ? (
                            <>
                                <Grid
                                    direction="column"
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                >
                                    <Tag>
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`Received: ${
                                            before?.received_date
                                                ? formatInTimeZone(
                                                      before.received_date,
                                                      tz,
                                                      'EEE, MMM dd, yyyy, h:mm a zzz'
                                                  )
                                                : 'Pending'
                                        }`}</Body1>
                                        {!readOnly && (
                                            <Anchor
                                                disabled={!before.received_date}
                                                onClick={() => {
                                                    callbacks.editReceiveEvent({ event: before });
                                                }}
                                            >
                                                <Header1>Edit</Header1>
                                            </Anchor>
                                        )}
                                    </Tag>
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Tag>
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >{`Est Ship: ${
                                        before?.est_ship_date
                                            ? formatters.dateShort.format(new Date(before.est_ship_date))
                                            : 'Pending'
                                    }`}</Body1>
                                    <Body1>{`Est Received: ${
                                        before?.est_received_date
                                            ? formatters.dateShort.format(new Date(before.est_received_date))
                                            : 'Pending'
                                    }`}</Body1>
                                </Tag>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {STEPS.map(({ description, cta, secondaryCta, callback, secondaryCallback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={secondaryCta}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={secondaryCallback}
                                secondaryOrange={true}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}
