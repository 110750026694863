import { EXCEPTION_TYPES } from '@onward-delivery/core';

export const REQUIRES_CARRIER_INSTRUCTION = [EXCEPTION_TYPES.DAMAGED, EXCEPTION_TYPES.REFUSAL, EXCEPTION_TYPES.OVERAGE];

export const INSTRUCTIONS = {
    RETURN_ITEMS: 'RETURN_ITEMS',
    SENDER_PICKUP: 'SENDER_PICKUP',
    DISPOSE_ITEMS: 'DISPOSE_ITEMS',
    NOOP: 'NOOP',
};

export const ACTIONS = {
    SPLIT_ORDERS: 'SPLIT_ORDERS',
    CREATE_EXCHANGE: 'CREATE_EXCHANGE',
    CREATE_RETURN: 'CREATE_RETURN',
    CREATE_RETURN_WILL_CALL: 'CREATE_RETURN_WILL_CALL',
    OMIT_ITEM: 'OMIT_ITEM',
    CANCEL_ORDER: 'CANCEL_ORDER',
    RESOLVE_NOOP: 'RESOLVE_NOOP',
    RESCHEDULE: 'RESCHEDULE',
    RESET_MARKETPLACE: 'RESET_MARKETPLACE',
    RESET_INTERNAL: 'RESET_INTERNAL',
    CHANGE_CARRIER: 'CHANGE_CARRIER',
};

export const INSTRUCTIONS_DISPLAY_MAPPING = {
    [INSTRUCTIONS.RETURN_ITEMS]: 'Return items',
    [INSTRUCTIONS.SENDER_PICKUP]: 'Sender will pick up items from customer',
    [INSTRUCTIONS.DISPOSE_ITEMS]: 'Donate/Dispose items',
    [INSTRUCTIONS.NOOP]: 'Proceed with delivering damaged items',
};

export const ACTION_DISPLAY_MAPPING = {
    [ACTIONS.SPLIT_ORDERS]: 'Create new order with items that need to be redelivered',
    [ACTIONS.CREATE_RETURN]: 'Create a new return order.',
    [ACTIONS.CREATE_EXCHANGE]: 'Create new order with items that need to be exchanged',
    [ACTIONS.OMIT_ITEM]: 'Complete order and omit items with issues',
    [ACTIONS.CANCEL_ORDER]: 'Cancel order',
    [ACTIONS.RESOLVE_NOOP]: 'No resolution needed',
    [ACTIONS.RESCHEDULE]: 'Reschedule order',
    [ACTIONS.RESET_MARKETPLACE]: 'Return to marketplace',
    [ACTIONS.CHANGE_CARRIER]: 'Select another carrier',
};

export const CLONE_ORDER = [
    'carrier_id',
    'del_elev',
    'del_stairs',
    'delivery_stair_quantity',
    'dropoff_address_is_custom',
    'dropoff_address',
    'dropoff_city',
    'dropoff_email',
    'dropoff_full_address',
    'dropoff_lat',
    'dropoff_lng',
    'dropoff_location_info',
    'dropoff_location_type',
    'dropoff_location',
    'dropoff_name',
    'dropoff_phone',
    'dropoff_state',
    'dropoff_street',
    'dropoff_unit',
    'dropoff_zip',
    'package_seq_id',
    'freight_type',
    'manufacturer',
    'oms',
    'pickup_address_is_custom',
    'pickup_address',
    'pickup_city',
    'pickup_elevator',
    'pickup_email',
    'pickup_full_address',
    'pickup_lat',
    'pickup_lng',
    'pickup_location_info',
    'pickup_location_type',
    'pickup_location',
    'pickup_name',
    'pickup_phone',
    'pickup_stair_quantity',
    'pickup_stairs',
    'pickup_state',
    'pickup_street_address',
    'pickup_unit',
    'pickup_zip',
    'po_number',
    'reference_id',
    'shipper_id',
];

export const CLONE_ITEM = [
    'assembly_type',
    'cubes_per_unit',
    'description',
    'height',
    'is_return',
    'item_type_details',
    'item_type',
    'length',
    'quantity',
    'service_time',
    'sku',
    'total_cubes',
    'total_weight',
    'weight',
    'width',
];
