import debounce from 'lodash/debounce';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import { GET_ALL_ORDER_SHIPPERS, GET_ORDERS_INVENTORY } from './graphql/queries';
import { useTableColumns } from './columns';
import { formatInTimeZone } from 'date-fns-tz';
import csvDownload from 'json-to-csv-export';
import { INVENTORY_CSV_COLUMNS, INVENTORY_TABS } from './constants';
import { genAttributes } from '@onward-delivery/core';
import { PALLET_STATUS_LABELS } from '@/constants/manifestStatuses';
import { INSERT_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();

    const [modal, setModal] = useState(null);
    const [notification, setNotification] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedSku, setSelectedSku] = useState(null);
    const [filters, setFilters] = useState([]);
    const [isPalletView, setIsPalletView] = useState(false);
    const SEARCHABLE = [
        'order.order_number',
        'order.po_number',
        'order.dropoff_phone',
        'order.dropoff_name',
        'order.itemsByOrderId.sku',
        'order.itemsByOrderId.description',
        'order.itemsByOrderId.pallet.pallet_name',
        'order.order_shipper.business_name',
    ];

    const [where, itemsWhere, palletsWhere, shipperWhere] = useMemo(() => {
        const query = [
            {
                _or: [{ carrier_id: { _eq: user_id } }, { shipper_id: { _eq: user_id } }],
            },
            { _or: [{ wh_events: { action: { _ilike: `START:RECEIVING` } } }, { job_type: { _ilike: 'WILL_CALL' } }] },
        ];
        let searchBuckets = {};

        if (search) {
            SEARCHABLE.forEach((attr) => {
                if (attr.includes('.')) {
                    const parts = attr.split('.');
                    return parts.reduceRight((acc, part, index) => {
                        if (index === parts.length - 1) {
                            const searchQuery = { [part]: { _ilike: `%${search.trim()}%` } };

                            const searchTermLevel = parts?.[index - 1];
                            if (searchTermLevel) {
                                if (!searchBuckets[searchTermLevel]) {
                                    searchBuckets[searchTermLevel] = [];
                                }
                                searchBuckets[searchTermLevel].push(searchQuery);
                            }

                            return searchQuery;
                        }
                        const searchQuery = { [part]: acc };
                        const searchTermLevel = parts?.[index - 1];
                        if (searchTermLevel) {
                            if (!searchBuckets[searchTermLevel]) {
                                searchBuckets[searchTermLevel] = [];
                            }
                            searchBuckets[searchTermLevel].push(searchQuery);
                        }
                        return searchQuery;
                    }, {});
                }
                return { [attr]: { _ilike: `%${search.trim()}%` } };
            });
        }

        if (INVENTORY_TABS[tabIndex].itemQuery) {
            query.push(INVENTORY_TABS[tabIndex].itemQuery);
        }
        if (INVENTORY_TABS[tabIndex].orderQuery) {
            query.push(INVENTORY_TABS[tabIndex].orderQuery);
        }

        return [
            { _and: [...query, ...filters, { _or: searchBuckets.order }] },
            searchBuckets?.itemsByOrderId?.length ? { _or: searchBuckets?.itemsByOrderId } : {},
            searchBuckets?.pallet?.length ? { _or: searchBuckets?.pallet } : {},
            searchBuckets?.order_shipper?.length ? { _or: searchBuckets?.order_shipper } : {},
        ];
    }, [search, filters, tabIndex]);

    const [getOrders, { data, loading: initInflight, fetchMore }] = useLazyQuery(GET_ORDERS_INVENTORY, {
        variables: {
            client_id: user_id,
            where: where,
            limit: 30,
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const [getExportData, { loading: exportLoading }] = useLazyQuery(GET_ORDERS_INVENTORY, {
        variables: {
            client_id: user_id,
            where: where,
            limit: 500,
        },
        onError: (e) => {
            console.log(e);
            captureException(e);
        },
    });

    const { data: shipperData } = useQuery(GET_ALL_ORDER_SHIPPERS, {
        variables: {
            user_id: user_id,
        },
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const [insertTemplate] = useMutation(INSERT_CSV_EXPORT_TEMPLATE, {
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const shipperOptions = useMemo(() => {
        return Object.fromEntries(
            (shipperData?.orders || [])
                .map((o) => [o.shipper_id, o.order_shipper.business_name])
                .filter(([shipper_id]) => shipper_id && shipper_id !== user_id)
        );
    }, [shipperData]);

    const orders = useMemo(() => {
        if (!data?.orders) return [];

        const uniqueOrders = new Map();
        data.orders.forEach((order) => {
            if (!uniqueOrders.has(order.order_id)) {
                uniqueOrders.set(order.order_id, order);
            }
        });

        return Array.from(uniqueOrders.values());
    }, [data]);

    const processOrders = (orders, tabIndex) => {
        const skuMap = orders.reduce((acc, order) => {
            const { zip: orderZip } = genAttributes(order);
            let nullSkuCounter = 1;

            //filter out extraneous items if there is a direct sku match to search term
            const matchingSkuItems = order.itemsByOrderId.filter(
                (item) => item.sku && item.sku.toLowerCase().includes(search.trim().toLowerCase())
            );
            const itemsToProcess = matchingSkuItems.length > 0 ? matchingSkuItems : order.itemsByOrderId;

            itemsToProcess.forEach((item) => {
                let sku = item.sku;

                if (!sku) {
                    sku = `no_sku_${nullSkuCounter++}`;
                }
                const uniqueKey = `${order.order_id}_${sku}`;
                if (acc.has(uniqueKey)) {
                    const existingItem = acc.get(uniqueKey);
                    existingItem.totalQuantity += item.quantity;
                    existingItem.receivedQuantity +=
                        item.pallet && item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0;
                    existingItem.outQuantity +=
                        item.pallet &&
                        (item.pallet.warehouse_status === 'STAGED' || item.pallet.warehouse_status === 'PICKED_UP')
                            ? item.quantity
                            : 0;
                    if (item.pallet && !existingItem.palletIds.has(item.pallet.pallet_id)) {
                        existingItem.palletIds.add(item.pallet.pallet_id);
                        existingItem.pallets.push(item.pallet);
                    }
                    if (
                        item.pallet &&
                        item.pallet.warehouse_location &&
                        !existingItem.wh_locations.has(item.pallet.warehouse_location)
                    ) {
                        existingItem.wh_locations.add(item.pallet.warehouse_location);
                    }
                    if (item.pallet && item.pallet.logs) {
                        item.pallet.logs.forEach((log) => {
                            existingItem.palletLogs.push({
                                ...log,
                                quantity: item.quantity,
                                item_id: item.item_id,
                                pallet_label: item.pallet.pallet_name || item.pallet.pallet_number,
                                pallet_id: log.pallet_id,
                            });
                        });
                    }

                    if (item.pallet && item.pallet.warehouse) {
                        existingItem.tzZip = item.pallet.warehouse.business_zip;
                    }

                    if (item.pallet) {
                        const palletId = item.pallet.pallet_id;
                        if (!existingItem.individualPalletInfo[palletId]) {
                            existingItem.individualPalletInfo[palletId] = {
                                totalQuantity: 0,
                                receivedQuantity: 0,
                                outQuantity: 0,
                                warehouseStatus: item.pallet.warehouse_status,
                            };
                        }
                        existingItem.individualPalletInfo[palletId].totalQuantity += item.quantity;
                        existingItem.individualPalletInfo[palletId].receivedQuantity +=
                            item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0;
                        existingItem.individualPalletInfo[palletId].outQuantity +=
                            item.pallet.warehouse_status === 'STAGED' || item.pallet.warehouse_status === 'PICKED_UP'
                                ? item.quantity
                                : 0;
                    }

                    item.manifests.forEach((manifestMapping) => {
                        const manifest = manifestMapping.manifest;
                        if (manifest) {
                            if (['INBOUND', 'CROSS_DOCK'].includes(manifest.type)) {
                                existingItem.dateIn = manifest.receiving_date
                                    ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                    : '-';
                                if (!existingItem.inboundManifests.includes(manifest.manifest_number)) {
                                    existingItem.inboundManifests.push(manifest.manifest_number);
                                }
                            } else if (['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'].includes(manifest.type)) {
                                existingItem.dateOut =
                                    manifest?.route?.scheduled_delivery_formatted ||
                                    (manifest.receiving_date
                                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                        : '-');
                                if (!existingItem.outboundManifests.includes(manifest.manifest_number)) {
                                    existingItem.outboundManifests.push(manifest.manifest_number);
                                }
                            }
                        }
                    });

                    if (item.item_exception) {
                        existingItem.exceptions.push(item.item_exception);
                    }
                } else {
                    const newItem = {
                        sku: sku,
                        status: item.item_sku_status,
                        totalQuantity: item.quantity,
                        receivedQuantity:
                            item.pallet && item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0,
                        outQuantity:
                            item.pallet &&
                            (item.pallet.warehouse_status === 'STAGED' || item.pallet.warehouse_status === 'PICKED_UP')
                                ? item.quantity
                                : 0,
                        order_number: order.order_number,
                        po_number: order.po_number,
                        shipper: order.order_shipper?.business_name || '-',
                        dateIn: '-',
                        dateOut: '-',
                        inboundManifests: [],
                        outboundManifests: [],
                        pallets: item.pallet ? [item.pallet] : [],
                        palletIds: new Set(item.pallet ? [item.pallet.pallet_id] : []),
                        wh_locations: new Set(item.pallet?.warehouse_location ? [item.pallet.warehouse_location] : []),
                        palletLogs: item.pallet?.logs
                            ? item.pallet.logs.map((log) => ({
                                  ...log,
                                  quantity: item.quantity,
                                  item_id: item.item_id,
                                  pallet_label: item.pallet.pallet_name || item.pallet.pallet_number,
                                  pallet_id: log.pallet_id,
                              }))
                            : [],
                        tzZip: item.pallet?.warehouse ? item.pallet.warehouse.business_zip : orderZip,
                        individualPalletInfo: {},
                        exceptions: item.item_exception ? [item.item_exception] : [],
                    };

                    if (item.pallet) {
                        const palletId = item.pallet.pallet_id;
                        newItem.individualPalletInfo[palletId] = {
                            warehouseStatus: item.pallet.warehouse_status,
                            totalQuantity: item.quantity,
                            receivedQuantity: item.pallet.warehouse_status !== 'NOT_RECEIVED' ? item.quantity : 0,
                            outQuantity:
                                item.pallet.warehouse_status === 'STAGED' ||
                                item.pallet.warehouse_status === 'PICKED_UP'
                                    ? item.quantity
                                    : 0,
                        };
                    }

                    item.manifests.forEach((manifestMapping) => {
                        const manifest = manifestMapping.manifest;
                        if (manifest) {
                            if (['INBOUND', 'CROSS_DOCK'].includes(manifest.type)) {
                                newItem.dateIn = manifest.receiving_date
                                    ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                    : '-';
                                if (!newItem.inboundManifests.includes(manifest.manifest_number)) {
                                    newItem.inboundManifests.push(manifest.manifest_number);
                                }
                            } else if (['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'].includes(manifest.type)) {
                                newItem.dateOut =
                                    manifest?.route?.scheduled_delivery_formatted ||
                                    (manifest.receiving_date
                                        ? formatInTimeZone(new Date(manifest.receiving_date), 'UTC', 'EEE, MMM d, yyyy')
                                        : '-');
                                if (!newItem.outboundManifests.includes(manifest.manifest_number)) {
                                    newItem.outboundManifests.push(manifest.manifest_number);
                                }
                            }
                        }
                    });

                    acc.set(uniqueKey, newItem);
                }
            });
            return acc;
        }, new Map());

        const filteredItems = Array.from(skuMap.values()).map((item) => {
            item.palletLogs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            item.wh_locations = Array.from(item.wh_locations);
            return item;
        });

        if (INVENTORY_TABS[tabIndex].value !== 0) {
            return filteredItems.filter(
                (item) => item.status === INVENTORY_TABS[tabIndex].itemQuery.itemsByOrderId.item_sku_status._eq
            );
        }

        return filteredItems;
    };

    const itemsBySku = useMemo(() => processOrders(orders, tabIndex), [orders, tabIndex]);

    const processPallets = (itemsBySku, tabIndex) => {
        const pallets = itemsBySku.flatMap((item) => {
            const processPallet = (palletId) => {
                const palletLogs = item.palletLogs.filter((log) => log.pallet_id === palletId);
                const dateInLog = palletLogs.find((log) => log.warehouse_status === 'RECEIVED');
                const dateOutLog = palletLogs.find((log) => log.warehouse_status === 'STAGED');

                return {
                    ...item,
                    palletId,
                    pallets: item.pallets.filter((pallet) => pallet.pallet_id === palletId),
                    palletLogs,
                    dateIn: dateInLog
                        ? formatInTimeZone(new Date(dateInLog.created_at), 'UTC', 'EEE, MMM d, yyyy')
                        : '-',
                    dateOut: dateOutLog
                        ? formatInTimeZone(new Date(dateOutLog.created_at), 'UTC', 'EEE, MMM d, yyyy')
                        : '-',
                    exceptions: item.exceptions.filter((exception) =>
                        item.pallets.some((pallet) => pallet.pallet_id === palletId)
                    ),
                };
            };

            if (item?.palletIds?.size > 1) {
                return Array.from(item.palletIds).map(processPallet);
            }

            if (item?.palletIds?.size === 1) {
                const palletId = Array.from(item.palletIds)[0];
                return processPallet(palletId);
            }

            return item;
        });

        // Filter if on 'in stock' tab
        if (tabIndex === 2) {
            return pallets.filter((palletItem) =>
                palletItem.pallets.some((pallet) => ['STORED', 'RECEIVED'].includes(pallet.warehouse_status))
            );
        }

        return pallets;
    };

    const palletsBySku = useMemo(() => processPallets(itemsBySku, tabIndex), [itemsBySku, tabIndex]);

    const getOrdersDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getOrders(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getOrders({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getOrdersDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [getOrdersDebounced, initInflight, where]);

    const cursor = useMemo(() => {
        if (orders.length === 0) {
            return null;
        }
        return orders[orders.length - 1].created_at;
    }, [orders]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const newResults = fetchMoreResult.orders;
                return {
                    ...prev,
                    orders: Array.isArray(prev.orders) ? [...prev.orders, ...newResults] : newResults,
                };
            },
        }).then((result) => {
            if (result.data.orders.length < 30) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const exportCsv = useCallback(
        async (selectedColumns, templateName, exportType) => {
            await getExportData({
                onCompleted: (exportData) => {
                    const exportOrders = exportData ? exportData.orders : orders;
                    const exportItemsBySku = processOrders(exportOrders, tabIndex);
                    const exportPalletsBySku = processPallets(exportItemsBySku, tabIndex);

                    const dataToExport = isPalletView ? exportPalletsBySku : exportItemsBySku;

                    const rowData = dataToExport.map((item) => {
                        if (isPalletView) {
                            return {
                                sku: item.sku,
                                palletId: item.pallets[0]
                                    ? item.pallets[0].pallet_name || item.pallets[0].pallet_number
                                    : 'No pallets received yet for sku',
                                order_number: item.order_number,
                                po_number: item.po_number,
                                shipper: item.shipper,
                                qtyIn: item?.individualPalletInfo?.[item.palletId]?.receivedQuantity || 0,
                                qtyOut: item?.individualPalletInfo?.[item.palletId]?.outQuantity || 0,
                                qtyOnHand:
                                    item?.individualPalletInfo?.[item.palletId]?.receivedQuantity -
                                    (item?.individualPalletInfo?.[item.palletId]?.outQuantity || 0),
                                qtyTotal: item?.individualPalletInfo?.[item.palletId]?.totalQuantity || 0,
                                locations: item.pallets[0]?.warehouse_location || '-',
                                dateIn: item.dateIn,
                                dateOut: item.dateOut,
                                inboundManifests: item.inboundManifests.join(', '),
                                outboundManifests: item.outboundManifests.join(', '),
                                status: PALLET_STATUS_LABELS[item.pallets[0]?.warehouse_status] || 'Not Received',
                                exceptions: item.exceptions,
                            };
                        } else {
                            return {
                                sku: item.sku,
                                palletId:
                                    item.pallets.length > 0
                                        ? item.pallets.map((p) => p.pallet_name || p.pallet_number).join(', ')
                                        : 'No pallets received yet for sku',
                                order_number: item.order_number,
                                po_number: item.po_number,
                                shipper: item.shipper,
                                qtyIn: item?.receivedQuantity || 0,
                                qtyOut: item?.outQuantity || 0,
                                qtyOnHand: item?.receivedQuantity - item?.outQuantity || 0,
                                qtyTotal: item?.totalQuantity || 0,
                                locations: item.wh_locations.join(', '),
                                dateIn: item.dateIn,
                                dateOut: item.dateOut,
                                inboundManifests: item.inboundManifests.join(', '),
                                outboundManifests: item.outboundManifests.join(', '),
                                status: item.status,
                                exceptions: item.exceptions,
                            };
                        }
                    });

                    if (templateName) {
                        insertTemplate({
                            variables: {
                                object: {
                                    name: templateName,
                                    user_id: user_id,
                                    export_type: exportType,
                                    template: selectedColumns,
                                },
                            },
                        });
                    }

                    const finalExportCols = INVENTORY_CSV_COLUMNS.filter(
                        (col) => selectedColumns['Inventory'][col.header]
                    );

                    if (finalExportCols.length > 0) {
                        csvDownload({
                            headers: finalExportCols.map((col) => col.header),
                            data: rowData.map((row) => finalExportCols.map((col) => col.value(row))),
                            filename: `onward-Inventory-Export-${new Date().toISOString()}.csv`,
                            delimiter: ',',
                        });
                    }
                },
            });
        },
        [orders, tabIndex, isPalletView]
    );

    const callbacks = {
        setTabIndex,
        setNotification,
        setModal,
        loadMore,
        exportCsv,
        setSearch,
        setSelectedSku,
        setFilters,
        hasMore,
        setIsPalletView,
    };

    const columns = useTableColumns({
        callbacks,
        isPalletView,
    });

    return (
        <Context.Provider
            value={{
                state: {
                    tabIndex,
                    itemsBySku,
                    palletsBySku,
                    orders,
                    notification,
                    modal,
                    columns,
                    hasMore,
                    search,
                    selectedSku,
                    shipperOptions,
                    filters,
                    isPalletView,
                },
                loading: {
                    init: initInflight,
                    export: exportLoading,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
