import React, { createContext, useState, useEffect, useMemo, useRef, useContext } from 'react';
import { defaultRequiredFields, genAttributes, validate } from '@onward-delivery/core';
import { css } from '@emotion/react';
import { Grid, DialogContent } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import { OnwardTabContainer, OnwardTab } from '@/components/Tabs';
import {
    ModalTitle,
    SecondaryButton,
    PrimaryButton,
    ResponsiveSidebarDialog,
    StickyModalActions,
} from '@/styles/blocks';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';

import PickupTab from './PickupTab';
import ItemsEditTab from './ItemsEditTab';
import HaulAwayTab from './HaulAwayTab';
import { useSwappedAttributes } from '../../hooks';
import { useEditModalCallbacks, useEditModalTabFields, useEditModalDynamicFields } from '../../hooks/modal';
import { price, distance } from '../../utilities/processOrders';
import { EDIT_ORDER_TABS, ITEM_REQUIRED_FIELDS, EDIT_ORDER_TABS_DISPLAY } from '../../constants';
import AdminTab from './AdminTab';
import WarehouseTab from './WarehouseTab';
import NotesTab from './NotesTab';
import BrokerTab from './BrokerTab';
import { omit, startCase } from 'lodash';
import { ITEM_READONLY_FIELDS, ORDER_READONLY_FIELDS } from '@/constants/readonlyFields';
import { useMutation } from '@apollo/client';
import { INSERT_MANIFEST } from '@/components/Manifests/graphql';
import { captureException } from '@sentry/react';
import OrderDetailsTab from './OrderDetailsTab';
import CustomerTab from './CustomerTab';
import OriginTab from './OriginTab';
import { post } from '@/utilities/onwardClient';
import { NOTIFY_CARRIER_OF_ADMIN_ASSIGNMENT } from '@/constants/apiRoutes';

export const ModalContext = createContext();

const DEFAULT_CALLBACKS = {
    onError: () => {},
    onSave: () => {},
    onClose: () => {},
};

const DEFAULT_OPTIONS = {
    disableAddressEditing: false,
    enablePickupDateEditing: false,
    enableAdminTestAccounts: false,
    disableGeocoding: false,
    disableDistancingAndPricing: false,
    startDirty: true,
    customerAvailabilityToggle: false,
    readOnly: false,
};

const EditOrderModal = ({
    order,
    open,
    initTab = EDIT_ORDER_TABS.CUSTOMER,
    callbacks,
    requiredAttributes,
    tabs,
    opt,
}) => {
    const [tab, setTab] = useState(0);
    const [saveInflight, setIsSaving] = useState(false);
    const [geoCache, setGeocache] = useState({});
    const [overrides, modifyClone] = useState({});
    const [errors, setErrors] = useState({});
    const [isDirty, setDirty] = useState({});
    const [initDone, setInitDone] = useState(false);
    const [createSingleOrderManifest, setCreateSingleOrderManifest] = useState(null);
    const [sendCarrierAssignedEmail, setSendCarrierAssignedEmail] = useState(false);

    const contentRef = useRef();

    const clone = useMemo(() => {
        return { ...order, ...overrides };
    }, [order, overrides]);

    const options = useMemo(() => {
        return { ...DEFAULT_OPTIONS, ...(opt || {}) };
    }, [opt]);

    const combinedTabs = useMemo(() => {
        return tabs
            ? tabs
            : [
                  ...(options.enableBrokerTab ? [EDIT_ORDER_TABS.BROKER] : []),
                  ...(clone?.has_middle_mile_origin ? [EDIT_ORDER_TABS.ORIGIN] : []),
                  ...(!clone?.is_middle_mile || clone.carrier_id ? [EDIT_ORDER_TABS.PICKUP] : []),
                  EDIT_ORDER_TABS.CUSTOMER,
                  EDIT_ORDER_TABS.ORDER_DETAILS,
                  EDIT_ORDER_TABS.ITEMS,
                  ...(clone?.job_type !== 'WILL_CALL' ? [EDIT_ORDER_TABS.HAUL_AWAY] : []),
                  ...(options.enableAdminTab ? [] : [EDIT_ORDER_TABS.NOTES]),
                  EDIT_ORDER_TABS.WAREHOUSE,
                  ...(options.enableAdminTab ? [EDIT_ORDER_TABS.ADMIN] : []),
              ];
    }, [tabs, clone, options]);

    useEffect(() => {
        modifyClone({ ...(order || {}) });
        setDirty({});
        setInitDone(false);
    }, [order]);

    useEffect(() => {
        if (initTab && !initDone) {
            const idx = combinedTabs.indexOf(initTab);
            setTab(idx > -1 ? idx : 0);
            setInitDone(true);
        }
    }, [combinedTabs, initTab, initDone]);

    const { geocode_failed, address } = useSwappedAttributes(clone);
    const { address: pu_address, geocode_failed: pu_geocode_failed } = useSwappedAttributes(clone, true);

    const required = useMemo(() => {
        //custom required attributes
        if (requiredAttributes) {
            if (Array.isArray(requiredAttributes)) {
                return requiredAttributes;
            }

            if (typeof requiredAttributes === 'function') {
                return requiredAttributes(clone);
            }
        }

        // Don't filter req fields with options such as disable geocode/pricing
        // EditModal has custom validation for each field -> anything calculated async should pass validation if related fields are dirty
        return defaultRequiredFields(clone);
    }, [requiredAttributes, clone]);

    useEditModalDynamicFields(clone, modifyClone);

    const modalCallbacks = useEditModalCallbacks(
        {
            tab: combinedTabs[tab],
            geoCache,
            clone,
            errors,
        },
        {
            modifyClone,
            setGeocache,
            setErrors,
            onError: callbacks.onError || DEFAULT_CALLBACKS.onError,
            setDirty,
        }
    );

    useEffect(() => {
        if (contentRef?.current) {
            contentRef.current.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }, [tab]);

    const [insertManifest] = useMutation(INSERT_MANIFEST, {
        onError: (error) => {
            console.log(error.message);
            captureException(error);
        },
    });

    const fieldsByTab = useEditModalTabFields(clone);

    const hasError = useMemo(() => {
        if (clone) {
            return Object.fromEntries(
                required
                    .filter((field) => {
                        const isEmpty = !clone[field] || clone[field].length === 0;
                        switch (field) {
                            case 'dropoff_lat':
                            case 'dropoff_lng':
                                if (
                                    clone.job_type === 'WILL_CALL' ||
                                    (!combinedTabs.includes(EDIT_ORDER_TABS.PICKUP) && clone.order_type === 'return')
                                ) {
                                    return false;
                                }
                                if (clone.dropoff_address_is_custom) {
                                    return isEmpty;
                                }
                                if (clone.dropoff_address_geocode_failed) {
                                    return !isDirty.dropoff_address;
                                }
                                return options.disableGeocoding ? false : isEmpty;
                            case 'pickup_lat':
                            case 'pickup_lng':
                                if (!combinedTabs.includes(EDIT_ORDER_TABS.PICKUP) && clone.order_type !== 'return') {
                                    return false;
                                }
                                if (clone.pickup_address_is_custom) {
                                    return isEmpty;
                                }
                                if (clone.pickup_address_geocode_failed) {
                                    return !isDirty.pickup_address;
                                }
                                return options.disableGeocoding ? false : isEmpty;
                            case 'shipper_rate':
                            case 'carrier_rate':
                                return false;
                            case 'duration_seconds':
                            case 'distance':
                            case 'miles':
                                if (clone.job_type === 'WILL_CALL') {
                                    return false;
                                }
                                if (clone.distance_failed || clone[field] === 0) {
                                    return !(isDirty.pickup_address || isDirty.dropoff_address);
                                }
                                return options.disableDistancingAndPricing ? false : isEmpty;
                            case 'pickup_phone':
                                return isEmpty || !validatePhoneNumber(clone.pickup_phone);
                            case 'dropoff_phone':
                                return isEmpty || !validatePhoneNumber(clone.dropoff_phone);
                            case 'preferred_delivery_date':
                            case 'alternative_delivery_dates':
                                return false;
                            case 'warehouse_delivery_status':
                            case 'warehouse_estimated_delivery_date':
                                return clone.is_middle_mile && isEmpty;

                            default:
                                return isEmpty;
                        }
                    })
                    .map((field) => [field, true])
            );
        }
        return {};
    }, [clone, required, options, isDirty, address, pu_address, geocode_failed, pu_geocode_failed]);

    console.debug(required);
    console.debug(hasError);
    console.debug(clone);

    const itemHasError = useMemo(() => {
        if (clone) {
            return (clone.itemsByOrderId || []).map((item) =>
                Object.fromEntries(
                    [
                        ...ITEM_REQUIRED_FIELDS,
                        ...(!clone.oms && clone.freight_type === 'household'
                            ? ['item_type_details', 'assembly_type']
                            : []),
                        ...(!clone.oms && clone.source_form === 'CUSTOMER' ? ['item_piece_count'] : []),
                        ...(clone.assembly_required ? ['assembly_type'] : []),
                    ]
                        .filter((field) => {
                            const isEmpty = (attr) => !item[attr] || item[attr].length === 0;
                            switch (field) {
                                case 'height':
                                case 'width':
                                case 'length':
                                    return (
                                        isEmpty(field) &&
                                        isEmpty('cubes_per_unit') &&
                                        (['height', 'width', 'length'].some((field) => !isEmpty(field)) ||
                                            ['height', 'width', 'length'].every((field) => isEmpty(field)))
                                    );
                                case 'cubes_per_unit':
                                    return (
                                        isEmpty('cubes_per_unit') &&
                                        ['height', 'width', 'length'].every((field) => isEmpty(field))
                                    );
                                case 'assembly_type':
                                    return clone.assembly_required
                                        ? isEmpty(field) || item[field] === 'no_assembly'
                                        : isEmpty(field);
                                default:
                                    return isEmpty(field);
                            }
                        })
                        .map((field) => [field, true])
                )
            );
        }
        return [];
    }, [clone]);

    const adminHasError = useMemo(() => {
        if (combinedTabs.includes(EDIT_ORDER_TABS.ADMIN)) {
            const dropoffBeforePickup =
                clone.del_window_start &&
                clone.pickup_window_end &&
                new Date(clone.del_window_start) < new Date(clone.pickup_window_start);

            return {
                delivery_date: dropoffBeforePickup,
                pickup_date: dropoffBeforePickup,
                delivery_window:
                    clone.del_window_start &&
                    clone.del_window_end &&
                    new Date(clone.del_window_start) > new Date(clone.del_window_end),
                pickup_window:
                    clone.pickup_window_start &&
                    clone.pickup_window_end &&
                    new Date(clone.pickup_window_start) > new Date(clone.pickup_window_end),
            };
        }
        return {};
    }, [clone]);

    const hasErrorTab = useMemo(() => {
        let errorObject = Object.fromEntries(
            Object.keys(fieldsByTab).map((tab) => {
                if (tab === EDIT_ORDER_TABS.ITEMS) {
                    return [
                        tab,
                        fieldsByTab[tab].some((field) => hasError[field] && (options.startDirty || isDirty[field])) ||
                            itemHasError.some((item, idx) => {
                                return Object.entries(item).some(([key, isInvalid]) => {
                                    return isInvalid && (options.startDirty || isDirty.itemsByOrderId?.[idx]?.[key]);
                                });
                            }),
                    ];
                }

                return [
                    tab,
                    fieldsByTab[tab].some((field) => hasError[field] && (options.startDirty || isDirty[field])),
                ];
            })
        );
        return errorObject;
    }, [hasError, fieldsByTab, isDirty]);

    const isValid = useMemo(() => {
        return (
            !Object.values(hasError).some((isInvalid) => isInvalid) &&
            !Object.values(itemHasError).some((item) => Object.values(item).some((isInvalid) => isInvalid)) &&
            !Object.values(adminHasError).some((isInvalid) => isInvalid)
        );
    }, [hasError, itemHasError, adminHasError]);

    let content;
    switch (combinedTabs[tab]) {
        case EDIT_ORDER_TABS.BROKER:
            content = <BrokerTab hasError={adminHasError} opt={options} />;
            break;
        case EDIT_ORDER_TABS.ORIGIN:
            content = <OriginTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.PICKUP:
            content = <PickupTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.CUSTOMER:
            content = <CustomerTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.ORDER_DETAILS:
            content = <OrderDetailsTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.ITEMS:
            content = <ItemsEditTab hasError={hasError} itemHasError={itemHasError} isDirty={isDirty} opt={options} />;
            break;
        case EDIT_ORDER_TABS.HAUL_AWAY:
            content = <HaulAwayTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.ADMIN:
            content = <AdminTab hasError={adminHasError} opt={options} />;
            break;
        case EDIT_ORDER_TABS.NOTES:
            content = <NotesTab opt={options} />;
            break;
        case EDIT_ORDER_TABS.WAREHOUSE:
            content = <WarehouseTab opt={options} />;
            break;
    }

    const singleTab = combinedTabs.length === 1 && initTab === combinedTabs[0];

    const orderLabel = useMemo(() => {
        switch (clone.job_type) {
            case 'WILL_CALL':
                return 'Will Call';
            case 'PICKUP_AND_WILL_CALL':
                return 'Return Pickup & Will Call';
            case 'INVENTORY':
                return 'Inventory';
            case 'SERVICE':
                return 'Service';
            case 'SHIPMENT':
            default:
                return startCase(clone.order_type || 'delivery');
        }
    }, [clone]);

    if (!order) {
        return null;
    }

    return (
        <ResponsiveSidebarDialog
            open={open}
            onClose={callbacks.onClose}
            fullWidth={true}
            maxWidth="md"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle
                border
                title={options.title || `Order ${clone.order_number || ''} - ${orderLabel}`}
                onClose={callbacks.onClose}
            />
            <ModalContext.Provider
                value={{
                    state: {
                        order: clone,
                        hasError,
                        errors,
                        isDirty,
                        createSingleOrderManifest,
                        setCreateSingleOrderManifest,
                        sendCarrierAssignedEmail,
                        setSendCarrierAssignedEmail,
                    },
                    callbacks: {
                        ...DEFAULT_CALLBACKS,
                        ...callbacks,
                        ...modalCallbacks,
                    },
                }}
            >
                {!singleTab ? (
                    <Grid
                        item
                        css={css`
                            border-bottom: 1px solid #a6a6a6;
                        `}
                    >
                        <OnwardTabContainer
                            value={tab}
                            onChange={(e, val) => {
                                setTab(val);
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            {combinedTabs.map((tab, idx) => {
                                return (
                                    <OnwardTab
                                        key={tab}
                                        label={EDIT_ORDER_TABS_DISPLAY[tab]}
                                        value={idx}
                                        error={hasErrorTab[tab]}
                                    />
                                );
                            })}
                        </OnwardTabContainer>
                    </Grid>
                ) : null}
                <DialogContent
                    css={css`
                        width: 100%;
                        padding: 0;
                    `}
                    ref={contentRef}
                >
                    <Grid
                        css={css`
                            padding-top: 0px;
                            padding-bottom: 0px;
                            margin-left: 16px;
                            margin-right: 16px;
                            margin-bottom: 32px;
                            padding-top: 20px;
                        `}
                    >
                        {content}
                    </Grid>
                </DialogContent>
            </ModalContext.Provider>
            {order.order_status === 'on_hold_awaiting_payment' && (
                <StickyModalActions border="true">
                    <Grid container direction="row" justifyContent="center">
                        <Grid
                            item
                            css={css`
                                color: red;
                            `}
                        >
                            Order is on hold pending payment authorization from customer. You can continue to make edits
                            and the price will automatically adjust, but the order status cannot be updated until
                            payment is authorized.
                        </Grid>
                    </Grid>
                </StickyModalActions>
            )}
            {options.enableAdminTab && order.payment_status === 'CAPTURED' && order.source_form === 'CUSTOMER' && (
                <StickyModalActions border="true">
                    <Grid container direction="row" justifyContent="center">
                        <Grid
                            item
                            css={css`
                                color: red;
                            `}
                        >
                            Payment has already been captured for this customer shipment form order. Further edits will
                            not result in price changing and could lead to a loss of revenue.
                        </Grid>
                    </Grid>
                </StickyModalActions>
            )}
            <StickyModalActions border="true">
                <Grid>
                    {!singleTab ? (
                        <PrimaryButton
                            disabled={tab === 0 || options.readOnly}
                            onClick={() => {
                                setTab((prev) => prev - 1);
                            }}
                        >
                            Prev
                        </PrimaryButton>
                    ) : (
                        <SecondaryButton disabled={saveInflight} onClick={callbacks.onClose}>
                            Cancel
                        </SecondaryButton>
                    )}
                </Grid>
                <Grid>
                    {!singleTab ? (
                        <PrimaryButton
                            disabled={tab === combinedTabs.length - 1 || options.readOnly}
                            onClick={() => {
                                setTab((prev) => prev + 1);
                            }}
                            css={css`
                                margin-right: 20px;
                            `}
                        >
                            Next
                        </PrimaryButton>
                    ) : null}

                    {isValid ? (
                        <PrimaryButton
                            disabled={saveInflight || options.readOnly}
                            onClick={async () => {
                                setIsSaving(true);
                                let priced = clone;
                                let failedPricing = {};
                                if (!options.disableDistancingAndPricing) {
                                    let failed;
                                    const [_, [distancedOrder]] = await distance([clone]);
                                    [failed, [priced]] = await price([distancedOrder]);
                                    failedPricing = failed[order.key || order.order_id];
                                }

                                const removed = order.itemsByOrderId
                                    .filter(
                                        (item) =>
                                            !clone.itemsByOrderId.map((item) => item.item_id).includes(item.item_id)
                                    )
                                    .map((item) => item.item_id);

                                const {
                                    itemsByOrderId,
                                    tags = [],
                                    documents = [],
                                    notes,
                                    ...modified
                                } = omit(priced, ORDER_READONLY_FIELDS);

                                const palletNameCount = itemsByOrderId.reduce((acc, item) => {
                                    if (item.pallet?.pallet_name) {
                                        acc[item.pallet.pallet_name] = (acc[item.pallet.pallet_name] || 0) + 1;
                                    }
                                    return acc;
                                }, {});

                                const modifiedItems = itemsByOrderId.map((item) => {
                                    const { pallet, pallet_id, ...rest } = omit(
                                        item,
                                        ITEM_READONLY_FIELDS.filter((field) => field !== 'pallet')
                                    );

                                    return {
                                        ...rest,
                                        ...(pallet
                                            ? {
                                                  pallet: {
                                                      data: {
                                                          pallet_name: pallet.pallet_name,
                                                          pallet_id: pallet.pallet_id,
                                                          type:
                                                              palletNameCount[pallet.pallet_name] > 1
                                                                  ? 'CONSOLIDATED'
                                                                  : 'SINGLE',
                                                      },
                                                      on_conflict: {
                                                          constraint: 'pallet_items_pkey',
                                                          update_columns: ['pallet_name', 'type'],
                                                      },
                                                  },
                                              }
                                            : {}),
                                    };
                                });

                                const modifiedTags = (tags || []).map((mapping) => {
                                    const { __typename, order, service_level, tag, ...rest } = mapping;
                                    return rest;
                                });

                                const modifiedDocuments = documents.map((mapping) => {
                                    const { __typename, order, document, ...rest } = mapping;
                                    return rest;
                                });

                                const removedTags = (order.tags || [])
                                    .filter(
                                        (mapping) => !modifiedTags.map(({ tag_id }) => tag_id).includes(mapping.tag_id)
                                    )
                                    .map((mapping) => mapping.mapping_id);

                                const removedDocuments = (order.documents || [])
                                    .filter(
                                        (mapping) =>
                                            !modifiedDocuments
                                                .map(({ document_id }) => document_id)
                                                .includes(mapping.document_id)
                                    )
                                    .map((mapping) => mapping.mapping_id);

                                const modifiedNotes = (notes || []).map((note) => {
                                    const { __typename, order_id, user, ...rest } = note;
                                    return rest;
                                });

                                await callbacks.onSave({
                                    errors: {
                                        ...errors,
                                        hasMissingField: [],
                                        failedPricing: failedPricing,
                                    },
                                    order: {
                                        ...modified,
                                        notes: modifiedNotes,
                                    },
                                    items: modifiedItems,
                                    itemsRemoved: removed,
                                    tags: modifiedTags,
                                    tagsRemoved: removedTags,
                                    documents: modifiedDocuments,
                                });

                                if (createSingleOrderManifest) {
                                    await insertManifest({
                                        variables: {
                                            manifest: createSingleOrderManifest,
                                        },
                                    });

                                    setCreateSingleOrderManifest(null);
                                }

                                if (sendCarrierAssignedEmail && modified.carrier_id !== order.carrier_id) {
                                    const assignedResponse = await post(NOTIFY_CARRIER_OF_ADMIN_ASSIGNMENT, {
                                        order: order,
                                        new_carrier_id: modified.carrier_id,
                                    });
                                    setSendCarrierAssignedEmail(false);
                                }

                                setIsSaving(false);
                            }}
                        >
                            Done
                        </PrimaryButton>
                    ) : null}
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default EditOrderModal;
