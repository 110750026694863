import { gql } from '@apollo/client';

export const INTEGRATION_OVERRIDE_FIELDS = gql`
    fragment IntegrationOverrideFields on integration_overrides {
        carrier_id
        override_id
        shipper_id
        type
    }
`;
