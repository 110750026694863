import React, { useMemo, useContext } from 'react';
import { compose } from 'recompose';
import { css } from '@emotion/react';
import { Grid, IconButton } from '@material-ui/core';

import Table from './Table';
import { Context, ContextProvider } from './store';
import { Title, Body1 } from './blocks';

const AdminIntegrations = ({ context }) => {
    const { state, loading, callbacks } = useContext(context);

    return (
        <Grid
            direction="column"
            container
            css={css`
                width: 100%;
                height: 100%;
            `}
        >
            <Grid
                container
                item
                direction="row"
                css={css`
                    padding: 40px;
                    padding-bottom: 0;
                    margin-bottom: 12px;
                `}
            >
                <Grid
                    direction="column"
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <Title>Integrations</Title>
                </Grid>
            </Grid>

            <Grid
                item
                css={css`
                    overflow: hidden;
                    flex-grow: 1;
                    padding: 0 40px;
                `}
            >
                <Table state={state} loading={loading} callbacks={callbacks} />
            </Grid>
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withContext)(AdminIntegrations);
