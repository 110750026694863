import { USER_FIELDS } from '@/graphql/queries/users';
import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    ${USER_FIELDS}
    mutation SaveUser($user_id: uuid!, $user_update: users_set_input = {}) {
        update_users(where: { user_id: { _eq: $user_id } }, _set: $user_update) {
            affected_rows
            returning {
                ...UserFields
            }
        }
    }
`;
