import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { get } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PageTitle, PrimaryButton } from '@/styles/blocks';
import { UserContext } from '../App';
import { useNavigate } from 'react-router';
import SearchInput from '../misc/SearchInput';
import { colors } from '@/styles';

const SEARCHABLE = ['email', 'business_name', 'client_id'];
const COLUMNS = [
    {
        label: 'Account Name',
        value: (client) => client?.business_name || '--',
    },
    {
        label: 'Primary Email',
        value: (client) => client?.email || '--',
    },
    {
        label: 'Account ID',
        value: (client) => client?.client_id || '--',
    },
];

const HeaderCell = styled.span`
    font-size: 16px;
    font-weight: 600;
`;

const CorporateUsers = () => {
    const [search, setSearch] = useState('');
    const { admin_clients, setClientId } = useContext(UserContext);
    const navigate = useNavigate();

    const clients = useMemo(() => {
        return (admin_clients || []).filter((client) => {
            if (search) {
                return SEARCHABLE.some((field) => {
                    const value = get(client, field);
                    return value && value.toString().includes(search);
                });
            }
            return true;
        });
    }, [admin_clients, search]);

    const signInAsUser = (client) => {
        window?.sessionStorage?.setItem('client_id', client.client_id);
        setClientId(client.client_id);
        navigate('/account');
    };

    return (
        <Grid
            container
            css={css`
                padding: 5rem;
                flex-direction: column;
                flex-wrap: nowrap;
                height: 100%;
            `}
        >
            <Grid
                item
                css={css`
                    justify-content: space-between;
                    display: flex;
                `}
            >
                <PageTitle>Accounts</PageTitle>
                <SearchInput onChange={(value) => setSearch(value)} placeholder="Search Users" />
            </Grid>

            <Grid
                item
                css={css`
                    display: flex;
                    flex-grow: 1;
                    overflow-y: hidden;
                `}
            >
                <Paper
                    css={css`
                        width: 100%;
                        height: 100% !important;
                        overflow-y: scroll;
                        border: none;
                        border-radius: 0;
                    `}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {COLUMNS.map((column) => (
                                    <TableCell key={column.label}>
                                        <HeaderCell>{column.label}</HeaderCell>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map((client) => (
                                <TableRow
                                    key={client.client_id}
                                    css={css`
                                        :hover {
                                            background-color: ${colors.greys.selected};
                                        }
                                    `}
                                >
                                    {COLUMNS.map((column) => (
                                        <TableCell key={`${client.client_id}-${column.label}`}>
                                            {column.value(client)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <PrimaryButton onClick={() => signInAsUser(client)}>Sign&nbsp;In</PrimaryButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CorporateUsers;
