import hubspotControls from '@/utilities/hubspotControls';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { css } from '@emotion/react';
import useQuery from '@/utilities/useQuery';
import { postUnauth } from '@/utilities/onwardClient';
import { VERIFY_TOKEN } from '@/constants/apiRoutes';
import { captureException } from '@sentry/react';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { UserContext } from '../App';
import { guestToken } from '../Customer/queries/guestToken';

const verifyToken = (role, token) => {
    return postUnauth(`${VERIFY_TOKEN}/${role}`, { token }).then((result) => result.data);
};

const withTokenAuth =
    ({ role, reqClaims = {}, redirectLocation }) =>
    (Component) =>
    (props) => {
        const { search } = useLocation();
        const pathParams = useParams();
        const isTrackByPO = location.pathname.startsWith('/customer/trackbypo');
        const navigate = useNavigate();
        const [authenticated, setAuthenticated] = useState(false);
        const [claims, setClaims] = useState({});
        const [token, setToken] = useState(null);
        const mobile = useMediaQuery('(max-width: 600px)');

        const { firebaseUser } = useContext(UserContext);

        const handleNavigation = () => {
            if (redirectLocation) {
                navigate(redirectLocation);
            } else {
                navigate('/signin');
            }
        };

        const [verify, { data, loading }] = useQuery(verifyToken, {
            onError: (error) => {
                captureException(error);
                console.error(error);
                handleNavigation();
            },
            onComplete: ({ verified, token }) => {
                if (token) {
                    setToken(token);
                    setClaims(verified);
                    if (!localStorage?.getItem('onward.already-signed-in')) {
                        console.debug('signing in with custom token');
                        signInWithCustomToken(getAuth(), token);
                    }
                } else {
                    handleNavigation();
                }
            },
        });

        useEffect(() => {
            hubspotControls.hide();
            return () => hubspotControls.show();
        }, []);

        const jwt = useMemo(() => {
            const searchParams = new URLSearchParams(search);
            return searchParams.get('jwt') || searchParams.get('token');
        }, [search]);

        useEffect(() => {
            if (isTrackByPO) {
                guestToken().then(({ token }) => {
                    if (token) {
                        setToken(token);
                        signInWithCustomToken(getAuth(), token);
                    } else {
                        handleNavigation();
                    }
                });
            } else if (jwt) {
                verify(role, jwt);
            } else {
                handleNavigation();
            }
        }, [jwt, isTrackByPO]);

        useEffect(() => {
            if (firebaseUser && isTrackByPO) {
                firebaseUser.getIdTokenResult().then(({ claims: authClaims }) => {
                    const hasuraClaims = authClaims?.['https://hasura.io/jwt/claims'];
                    const authRole = hasuraClaims?.['x-hasura-default-role'];

                    if (authRole) {
                        setAuthenticated(true);
                    } else {
                        console.log('token doesnt match, signing out and trying again');
                        signOut(getAuth()).then(() => {
                            signInWithCustomToken(getAuth(), token);
                        });
                    }
                });
            } else if (firebaseUser && claims && token) {
                firebaseUser.getIdTokenResult().then(({ claims: authClaims }) => {
                    const hasuraClaims = authClaims?.['https://hasura.io/jwt/claims'];
                    const authRole = hasuraClaims?.['x-hasura-default-role'];

                    if (
                        authRole === 'user' ||
                        (authRole === role &&
                            (reqClaims?.path || []).every(
                                (claim) => claims[claim] === hasuraClaims?.[`x-hasura-${claim}`]
                            ))
                    ) {
                        setAuthenticated(true);
                    } else {
                        console.log('token doesnt match, signing out and trying again');
                        signOut(getAuth()).then(() => {
                            signInWithCustomToken(getAuth(), token);
                        });
                    }
                });
            }
        }, [firebaseUser, claims, token, isTrackByPO]);

        return authenticated ? (
            <Component {...props} claims={claims} jwt={jwt} />
        ) : (
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <CircularProgress size={mobile ? 100 : 200} />
            </div>
        );
    };

export default withTokenAuth;
