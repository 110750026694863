import React, { useState, useCallback, useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { Grid, TextField, InputAdornment, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, LocalShippingOutlined as LocalShippingOutlinedIcon } from '@material-ui/icons';
import { ROUTE_COLORS } from '../constants';
import generateCSV from '@/utilities/createCSV';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { ROUTE_COLUMNS } from '@/constants/csvRouteColumns';
import { ROUTE_COLUMNS_EXT } from '@/constants/csvRouteColumnsExt';
import { useClientUser } from '@/hooks';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import { useQuery } from '@apollo/client';
import { PlanningContext } from '../context';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { MODALS } from '../constants';
import { Body2, GridItemRow, ModalActions, ModalContent, ModalTitle, PrimaryButton, SecondaryButton } from '../blocks';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { genAttributes } from '@onward-delivery/core';
import { capitalize } from 'lodash';

const RouteExportModal = () => {
    const {
        modalOpen,
        setModalOpen,
        deliveryDate,
        updateDeliveryDate,
        state: { routes, loading },
    } = useContext(PlanningContext);
    const { user_id, userType } = useClientUser();

    const { data: { results: allDrivers } = {} } = useQuery(QUERY_BY_LEADER, {
        variables: { leader_id: user_id },
        skip: !user_id,
    });

    const [selectedRoutes, setSelectedRoutes] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [itemExtension, setItemExtension] = useState(false);

    const visibleRoutes = useMemo(() => {
        return searchString ? routes.filter((r) => r.route_number.toString().includes(searchString)) : routes;
    }, [routes, searchString]);

    const handleModalClose = () => {
        setModalOpen(null);
        setSelectedRoutes([]);
    };

    const toggleRoutes = useCallback(() => {
        if (selectedRoutes.length !== visibleRoutes.length) {
            setSelectedRoutes(visibleRoutes.map((route) => route.route_id));
        } else {
            setSelectedRoutes([]);
        }
    }, [visibleRoutes, selectedRoutes]);

    const toggleRoute = (route, isSelected) =>
        setSelectedRoutes((prevState) =>
            isSelected ? prevState.filter((r) => r !== route.route_id) : [...prevState, route.route_id]
        );

    const updateVisibleRoutes = (e) => {
        const searchString = e.target.value;

        setSearchString(searchString);
    };

    const generateRoutesCSV = () => {
        const data = visibleRoutes
            .filter((route) => selectedRoutes.includes(route.route_id))
            .reduce((acc, route) => {
                const driver =
                    allDrivers.find((driver) => {
                        return driver.teammate_id === route.driver_id;
                    }) || {};

                const order = route?.orders?.[0]?.order || {};
                const { zip } = genAttributes(order);
                const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;

                return [
                    ...acc,
                    ...FTLStopHelpers.getStopSequence(route.stopsByRouteId).reduce((data, stop) => {
                        // Skip pickup stops
                        if (stop.type === 'PICKUP' && !stop.returns?.length) return data;

                        let orderData = [];
                        let ordersOnStop = route.orders
                            .filter((mapping) => stop.orders?.includes?.(mapping.order_id))
                            .map((mapping) => mapping.order);

                        ordersOnStop.forEach((_order) => {
                            // Grab dropoff comments
                            const comments = [];
                            (_order?.notes || []).forEach((n) => {
                                if (n.private_to && n.private_to !== capitalize(userType)) {
                                    return;
                                }
                                if (n.type !== 'Delivery') return;
                                comments.push(n.note);
                            });

                            orderData.push({
                                route,
                                stop,
                                orders: [
                                    {
                                        ..._order,
                                        comments: comments,
                                    },
                                ],
                                driver,
                                opt: {
                                    tz,
                                },
                            });
                        });

                        return data.concat(orderData);
                    }, []),
                ];
            }, []);

        const blob = generateCSV(itemExtension ? ROUTE_COLUMNS_EXT : ROUTE_COLUMNS, data);
        const filename = `${deliveryDate} Routes`;
        const autoclick = document.createElement('a');
        const payload = URL.createObjectURL(blob);
        autoclick.setAttribute('href', payload);
        autoclick.setAttribute('download', filename);
        autoclick.style.visibility = 'hidden';
        document.body.appendChild(autoclick);
        autoclick.click();
        document.body.removeChild(autoclick);

        handleModalClose();
    };

    return (
        <NavResponsiveModal open={modalOpen === MODALS.ROUTE_EXPORT} onClose={handleModalClose}>
            <ModalTitle>Download Route Summaries</ModalTitle>
            <ModalContent>
                <GridItemRow
                    css={css`
                        input {
                            font-size: 14px;
                            font-weight: 400;
                            padding: 8px 12px 12px 12px;
                        }
                    `}
                >
                    <Grid
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-right: 0.5rem;
                        `}
                    >
                        <Body2>Select All</Body2>
                        <Checkbox
                            onChange={toggleRoutes}
                            color="primary"
                            checked={selectedRoutes.length === visibleRoutes.length}
                        />
                    </Grid>
                    <TextField
                        type="date"
                        variant="outlined"
                        disabled={loading}
                        value={deliveryDate}
                        onChange={(e) => updateDeliveryDate(e.target.value)}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        color="primary"
                        value={searchString}
                        onChange={updateVisibleRoutes}
                        placeholder="Search..."
                        InputProps={{
                            style: { width: '160px' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        css={css`
                            margin-left: 0.5rem;
                        `}
                    />
                </GridItemRow>
                {visibleRoutes.map((route, idx) => {
                    const isSelected = !!selectedRoutes.find((r) => r === route.route_id);

                    return (
                        <GridItemRow
                            key={route.route_id}
                            css={css`
                                ${isSelected &&
                                `
                                    background: rgba(141, 141, 141, 0.2);
                                    border-top: 1px solid #d1d1d1;
                                    border-bottom: 1px solid #d1d1d1;
                                `}
                            `}
                        >
                            <Grid item>
                                <Checkbox
                                    onChange={() => toggleRoute(route, isSelected)}
                                    color="primary"
                                    checked={isSelected}
                                />
                                <Body2>Route {route.route_number}</Body2>
                            </Grid>
                            <LocalShippingOutlinedIcon
                                css={css`
                                    color: ${ROUTE_COLORS[idx]};
                                    margin-right: 0.5rem;
                                `}
                            />
                        </GridItemRow>
                    );
                })}
            </ModalContent>
            <GridItemRow
                css={css`
                    input {
                        font-size: 14px;
                        font-weight: 400;
                        padding: 8px 12px 12px 12px;
                        margin-top: 0.5rem !important;
                    }
                `}
            >
                <Grid item>
                    <Checkbox
                        onChange={() => setItemExtension((prev) => !prev)}
                        color="primary"
                        checked={itemExtension}
                    />
                    <Body2>Include Stop Level Item Information</Body2>
                </Grid>
            </GridItemRow>
            <ModalActions>
                <SecondaryButton onClick={handleModalClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={generateRoutesCSV} disabled={loading || !selectedRoutes.length}>
                    Download Routes ({selectedRoutes.length})
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RouteExportModal;
