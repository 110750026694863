import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { RATING_FIELDS } from '@/graphql/queries/ratings';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { USER_FIELDS } from '@/graphql/queries/users';
import { STOP_FIELDS } from '@/graphql/queries/stops';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
import { BID_FIELDS } from '@/graphql/queries/bids';
import { TEAMMATE_FIELDS } from '@/graphql/queries/teammates';
import { ORDER_ROUTE_MAPPINGS } from '@/graphql/queries/order_route_mappings';

export const ORDER_BY_ID = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${ROUTE_FIELDS}
    ${RATING_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${STOP_FIELDS}
    ${LOCATION_FIELDS}
    ${LISTING_FIELDS}
    ${BID_FIELDS}
    ${TEAMMATE_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}

    subscription orders($order_id: uuid!) {
        orders(where: { order_id: { _eq: $order_id } }) {
            ...OrderFields

            itemsByOrderId {
                ...ItemFields
            }
            routes {
                ...OrderRouteMappings
                route {
                    ...RouteFields
                    stopsByRouteId {
                        ...StopFields
                    }
                    teammateByDriverId {
                        ...TeammateFields
                    }
                }
            }
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
                locations {
                    ...LocationFields
                }
            }
            order_carrier {
                ...ClientFields
            }
            itemsByOrderId {
                ...ItemFields
            }
            listing {
                ...ListingFields
                bids {
                    ...BidFields
                }
            }
            ratings {
                ...RatingFields
            }
            messages {
                message_id
                link_type
            }
        }
    }
`;
