import { OnwardTooltip, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid, IconButton, MenuItem, TextField, Tooltip, Button } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { indexOf } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import NavResponsiveModal, { ModalActions, ModalContent, ModalHeader } from '../../Navigation/NavResponsiveModal';
import { Context } from '../store';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';
import SearchInput from '../../misc/SearchInput';
import { OnwardCheckbox } from '../../ShipmentForm/blocks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { colors } from '@/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import { MANIFEST_TYPE_LABELS, MANIFEST_TYPE_TOOLTIPS } from '@/constants/manifestStatuses';

const STAGES = {
    DETAILS: 'DETAILS',
    ITEMS: 'ITEMS',
};

const PROGRESSION = [STAGES.DETAILS, STAGES.ITEMS];

const SplitItem = ({ item, manifestType }) => {
    const { state, callbacks } = useContext(Context);
    const [splitAmount, setSplitAmount] = useState(0);
    return (
        <Grid container direction="row" alignItems="center">
            <TextField
                style={{ width: '20%', marginBottom: '10px', marginLeft: '39px', marginTop: '10px' }}
                variant="outlined"
                label="Split Qty"
                type="number"
                value={splitAmount}
                InputProps={{ inputProps: { min: 0, max: item.quantity - 1 } }}
                onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (!isNaN(value) && value >= 0 && value <= item.quantity - 1) {
                        setSplitAmount(value);
                    }
                }}
            />
            <Tooltip
                style={{ color: '#59B863', marginLeft: '5px' }}
                title={
                    'Optionally choose to split this item by specified quantity. This allows you to split items onto different manifests or pallets.'
                }
                placement="top"
            >
                <IconButton size="small">
                    <InfoOutlined />
                </IconButton>
            </Tooltip>
            <PrimaryButton
                css={css`
                    width: auto;
                    height: 50%;
                    margin-bottom: 10px;
                    margin-left: 20px;
                    margin-top: 8px;
                `}
                onClick={() => callbacks.splitItem(item, splitAmount, manifestType)}
                disabled={splitAmount < 1}
            >
                Split
            </PrimaryButton>
        </Grid>
    );
};

const OrderCheckbox = ({ order, idx }) => {
    const { state, callbacks } = useContext(Context);
    const [open, setOpen] = useState(false);

    const isAlreadyInManifest = (item) =>
        item.manifests?.some((manifest) => manifest?.manifest?.type === state.newManifest?.type);

    return (
        <Grid
            item
            container
            direction="column"
            css={css`
                flex-wrap: nowrap;
                ${idx > 0 ? `border-top: 1px solid ${colors.greys.border};` : ''}
            `}
        >
            <Grid
                item
                container
                direction="row"
                css={css`
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid item>
                    <OnwardCheckbox
                        checked={order.itemsByOrderId
                            .filter((item) => !isAlreadyInManifest(item))
                            .every((item) => !!state.newManifest?.items?.[item.item_id])}
                        onChange={(e) => {
                            callbacks.setNewManifest((prev) => ({
                                ...prev,
                                items: {
                                    ...(prev.items || {}),
                                    ...Object.fromEntries(
                                        order.itemsByOrderId
                                            .filter((item) => !isAlreadyInManifest(item))
                                            .map((item) => [item.item_id, e.target.checked])
                                    ),
                                },
                                includedShippers: prev.includedShippers?.includes(order.shipper_id)
                                    ? prev.includedShippers
                                    : [...(prev.includedShippers || []), order.shipper_id],
                            }));
                        }}
                        label={
                            <Grid item>
                                {`${order.order_number} / ${order.po_number || '--'} / ${(
                                    order.itemsByOrderId || []
                                ).reduce((acc, item) => {
                                    return acc + (item.quantity || 1);
                                }, 0)} Items`}
                                {(order.itemsByOrderId || []).some((item) =>
                                    ['itemDamaged', 'shortage', 'overage'].includes(item.item_exception?.type)
                                ) ? (
                                    <WarningIcon
                                        css={css`
                                            color: ${colors.oranges.primary};
                                        `}
                                    />
                                ) : null}
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen((prev) => !prev);
                        }}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
                    </IconButton>
                </Grid>
            </Grid>
            {(open ? order.itemsByOrderId : []).map((item) => {
                const itemAlreadyInManifest = isAlreadyInManifest(item);

                return (
                    <Grid
                        item
                        container
                        direction="column"
                        css={css`
                            padding-left: 2rem;
                            border-top: 1px solid ${colors.greys.border};
                        `}
                    >
                        {itemAlreadyInManifest ? (
                            <Grid
                                item
                                css={css`
                                    font-weight: 500;
                                    margin-top: 7px;
                                    margin-bottom: 7px;
                                    padding-left: 40px;
                                `}
                            >
                                {`${item.description || item.item_type_details || 'NO DESCRIPTION'}${
                                    item.sku ? ` / ${item.sku}` : ''
                                }`}
                                <span
                                    css={css`
                                        color: ${colors.greys.subtitle};
                                        margin-left: 10px;
                                    `}
                                >
                                    {` Quantity: ${item.quantity || 1}`}
                                </span>
                                {['itemDamaged', 'shortage', 'overage'].includes(item.item_exception?.type) ? (
                                    <WarningIcon
                                        css={css`
                                            color: ${colors.oranges.primary};
                                        `}
                                    />
                                ) : null}
                                <span
                                    css={css`
                                        color: ${colors.greys.subtitle};
                                        margin-left: 10px;
                                    `}
                                >
                                    - (Already in a manifest)
                                </span>
                            </Grid>
                        ) : (
                            <OnwardCheckbox
                                checked={!!state.newManifest?.items?.[item.item_id]}
                                onChange={(e) =>
                                    callbacks.setNewManifest((prev) => ({
                                        ...prev,
                                        items: {
                                            ...(prev.items || {}),
                                            [item.item_id]: e.target.checked,
                                        },
                                    }))
                                }
                                label={
                                    <Grid item>
                                        {item.pallet
                                            ? item.pallet.pallet_name
                                                ? `Pallet ${item.pallet.pallet_name} - `
                                                : `Pallet ${item.pallet.pallet_number} - `
                                            : ''}
                                        {`${item.description || item.item_type_details || 'NO DESCRIPTION'}${
                                            item.sku ? ` / ${item.sku}` : ''
                                        }`}
                                        <span
                                            css={css`
                                                color: ${colors.greys.subtitle};
                                                margin-left: 10px;
                                            `}
                                        >
                                            {` Quantity: ${item.quantity || 1}`}
                                        </span>
                                        {['itemDamaged', 'shortage', 'overage'].includes(item.item_exception?.type) ? (
                                            <WarningIcon
                                                css={css`
                                                    color: ${colors.oranges.primary};
                                                `}
                                            />
                                        ) : null}
                                    </Grid>
                                }
                                disabled={itemAlreadyInManifest}
                            />
                        )}
                        {!!state.newManifest?.items?.[item.item_id] &&
                            item.quantity > 1 &&
                            ['INBOUND', 'WILL_CALL', 'CROSS_DOCK'].includes(state.newManifest?.type) && (
                                <SplitItem item={item} manifestType={state.newManifest?.type} />
                            )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

const CreateManifest = () => {
    const { state, loading, callbacks } = useContext(Context);
    const [stage, setStage] = useState(PROGRESSION[0]);
    const [dirty, setDirty] = useState({});

    const makeDirty = (patch) => setDirty((prev) => ({ ...prev, ...patch }));

    const isValid = useMemo(() => {
        switch (stage) {
            case STAGES.DETAILS: {
                return ['receiving_date', 'warehouse_id', 'type'].every((attr) => state.newManifest?.[attr]);
            }
            case STAGES.ITEMS: {
                return Object.values(state.newManifest?.items || {}).some((x) => x);
            }
            default: {
                return false;
            }
        }
    }, [stage, state.newManifest]);

    let content;
    switch (stage) {
        case STAGES.DETAILS: {
            content = (
                <Grid
                    container
                    css={css`
                        flex-direction: column;
                        flex-wrap: nowrap;
                        height: 100%;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Origin"
                        name="origin"
                        fullWidth
                        value={state.newManifest?.origin || ''}
                        onChange={(e) => callbacks.setNewManifest((prev) => ({ ...prev, origin: e.target.value }))}
                        onBlur={() => makeDirty({ origin: true })}
                    />
                    <TextField
                        variant="outlined"
                        label="Destination"
                        name="origin"
                        fullWidth
                        value={state.newManifest?.destination || ''}
                        onChange={(e) => callbacks.setNewManifest((prev) => ({ ...prev, destination: e.target.value }))}
                        onBlur={() => makeDirty({ destination: true })}
                        css={css`
                            margin-top: 1rem;
                        `}
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        required
                        label={state.tab.value === 'INBOUND' ? 'Receiving Date' : 'Staging Date'}
                        name="receiving_date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={
                            state.newManifest?.receiving_date
                                ? formatInTimeZone(new Date(state.newManifest.receiving_date), 'UTC', 'yyyy-MM-dd')
                                : '--'
                        }
                        onChange={(e) =>
                            callbacks.setNewManifest((prev) => ({
                                ...prev,
                                receiving_date: asDateInTZ(e.target.value, 'UTC').toISOString(),
                            }))
                        }
                        onBlur={() => makeDirty({ receiving_date: true })}
                        css={css`
                            margin-top: 1rem;
                        `}
                        error={dirty.receiving_date && !state.newManifest?.receiving_date}
                    />
                    {state.warehouses.length > 0 ? (
                        <TextField
                            variant="outlined"
                            required
                            select
                            label="Warehouse"
                            name="warehouse_id"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={state.newManifest?.warehouse_id || ''}
                            onChange={(e) =>
                                callbacks.setNewManifest((prev) => ({ ...prev, warehouse_id: e.target.value }))
                            }
                            onClick={() => makeDirty({ warehouse_id: true })}
                            error={dirty.warehouse_id && !state.newManifest?.warehouse_id}
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            {state.warehouses.map((warehouse) => (
                                <MenuItem key={warehouse.location_id} value={warehouse.location_id}>
                                    {warehouse.location_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Alert
                            severity="warning"
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            <AlertTitle>No Warehouse(s)</AlertTitle>Please add a new warehouse to get started
                        </Alert>
                    )}
                    {state.tab.typeOptions.length > 1 ? (
                        <TextField
                            variant="outlined"
                            required
                            select
                            label="Type"
                            name="type"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={state.newManifest?.type || ''}
                            onChange={(e) => callbacks.setNewManifest((prev) => ({ ...prev, type: e.target.value }))}
                            onClick={() => makeDirty({ type: true })}
                            error={dirty.type && !state.newManifest?.type}
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            {state.tab.typeOptions.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {[MANIFEST_TYPE_LABELS[type], MANIFEST_TYPE_TOOLTIPS[type]]
                                        .filter((x) => !!x)
                                        .join(' ')}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                    <>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={11}>
                                <TextField
                                    variant="outlined"
                                    select
                                    label="Share Manifest"
                                    name="share"
                                    defaultValue={false}
                                    value={state.newManifest?.share_manifest}
                                    onChange={(e) =>
                                        callbacks.setNewManifest((prev) => ({
                                            ...prev,
                                            share_manifest: e.target.value,
                                        }))
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    css={css`
                                        margin-top: 1rem;
                                        width: 100%;
                                    `}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                css={css`
                                    margin-top: 1rem;
                                `}
                            >
                                <Tooltip
                                    style={{ color: '#59B863' }}
                                    title={
                                        'Optionally choose to share the status of this manifest with the shippers of the included orders. They will only be able to see details for their own orders/items.'
                                    }
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                </Grid>
            );
            break;
        }
        case STAGES.ITEMS: {
            content = (
                <div
                    css={css`
                        width: 100%;
                        height: 600px;
                        overflow-y: hidden;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            height: 100%;
                            flex-wrap: nowrap;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                padding-bottom: 0.5rem;
                            `}
                        >
                            <SearchInput
                                placeholder="Search Orders"
                                onChange={(search) => callbacks.setSearchOrders(search)}
                                fullWidth
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            direction="column"
                            css={css`
                                flex-wrap: nowrap;
                                flex: 1;
                                overflow-y: scroll;
                            `}
                        >
                            {state.orders.map((order, idx) => (
                                <OrderCheckbox key={order.order_id} order={order} idx={idx} />
                            ))}
                        </Grid>
                    </Grid>
                </div>
            );
            break;
        }
    }

    const isLast = indexOf(PROGRESSION, stage) === PROGRESSION.length - 1;
    const isFirst = indexOf(PROGRESSION, stage) === 0;

    const onClose = () => {
        setStage(PROGRESSION[0]);
        setDirty({});
        callbacks.setNewManifest(null);
    };

    const advance = () => {
        if (isLast) {
            callbacks.createManifest().then(() => {
                setStage(PROGRESSION[0]);
                setDirty({});
                callbacks.setNewManifest(null);
            });
        } else {
            setStage((prev) => PROGRESSION[indexOf(PROGRESSION, prev) + 1]);
        }
    };

    const revert = () => {
        if (isFirst) {
            callbacks.setNewManifest(null);
        } else {
            setStage((prev) => PROGRESSION[indexOf(PROGRESSION, prev) - 1]);
        }
    };

    return (
        <NavResponsiveModal open={!!state.newManifest} onClose={onClose}>
            <ModalHeader title={'Create Manifest'} onClose={onClose} />
            <ModalContent
                css={css`
                    width: 600px;
                `}
            >
                {content}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.createLoading} onClick={revert}>
                    {isFirst ? 'Cancel' : 'Prev'}
                </SecondaryButton>
                <PrimaryButton disabled={loading.createLoading || !isValid} onClick={advance}>
                    {isLast ? 'Create' : 'Next'}
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default CreateManifest;
