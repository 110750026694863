import React, { createContext, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, DialogContent } from '@material-ui/core';
import { ModalTitle, PrimaryButton, ResponsiveSidebarDialog, StickyModalActions } from '@/styles/blocks';
import { colors } from '@/styles';

import { Body1, Body2, HR } from './blocks';
import { useExceptionActions, useExceptionState, useExceptionCallbacks, useExceptionInstructions } from './hooks';
import ResolutionRadioGroup from './ResolutionRadioGroup';
import ResolutionSummary from './ResolutionSummary';
import OrderInfo from './OrderInfo';
import ItemInfo from './ItemInfo';
import ExceptionInfo from './ExceptionInfo';
import { useClientUser } from '@/hooks';

export const ModalContext = createContext();

const DEFAULT_OPTIONS = {
    readOnly: false,
};

const ExceptionResolutionModal = ({ order, exception, loading, items = [], opt = {}, callbacks, route_id }) => {
    const [inFlight, setInflight] = useState(false);
    const { user_id } = useClientUser();

    const options = useMemo(() => {
        return { ...DEFAULT_OPTIONS, ...opt };
    }, [opt]);

    const [
        {
            instruction,
            isAdmin,
            isCarrier,
            isShipper,
            isValid,
            itemDelivered,
            itemExchanged,
            itemReturned,
            carrierCleared,
            omitted,
            resolution,
            tz,
            updates,
        },
        { setAction, setUpdates, setInstruction },
    ] = useExceptionState({
        order,
        exception,
        items,
    });

    const actions = useExceptionActions({ order, omitted, exception, items, isAdmin, isShipper, isCarrier });
    const instructions = useExceptionInstructions({ order, exception, resolution });
    const { resolve, acknowledge } = useExceptionCallbacks({
        order,
        itemDelivered,
        instruction,
        itemExchanged,
        itemReturned,
        carrierCleared,
        omitted,
        exception,
        items,
        updates,
        resolution,
        route_id,
        callbacks: {
            ...callbacks,
            setInflight,
        },
        user_id,
    });

    if (!exception) {
        return null;
    }

    let resolutions;
    if (options.readOnly) {
        resolutions = null;
    } else {
        switch (exception.status) {
            case 'RESOLVED': {
                resolutions = (
                    <>
                        <ResolutionSummary exception={exception} route_id={route_id} order={order} />

                        <HR />
                    </>
                );
                break;
            }
            case 'PENDING_CONFIRMATION': {
                resolutions = (
                    <>
                        <ResolutionSummary exception={exception} route_id={route_id} order={order} />

                        {order.oms || isCarrier || isAdmin ? (
                            <Grid
                                container
                                direction="row"
                                css={css`
                                    margin-top: 20px;
                                `}
                            >
                                <PrimaryButton onClick={acknowledge}>Resolve</PrimaryButton>
                            </Grid>
                        ) : null}

                        <HR />
                    </>
                );
                break;
            }
            case 'OPEN': {
                if (!order.oms && isCarrier && !isAdmin) {
                    resolutions = (
                        <>
                            <Grid container direction="row">
                                <Body2
                                    css={css`
                                        color: ${colors.greys[3]};
                                    `}
                                >
                                    Awaiting shipper response
                                </Body2>
                            </Grid>

                            <HR />
                        </>
                    );
                } else {
                    resolutions = (
                        <>
                            <ResolutionRadioGroup
                                isValid={isValid}
                                route_id={route_id}
                                loading={inFlight || loading}
                                actions={actions}
                                updates={updates}
                                exception={exception}
                                resolution={resolution}
                                instruction={instruction}
                                instructions={instructions}
                                tz={tz}
                                callbacks={{
                                    resolve,
                                    setUpdates,
                                    setAction,
                                    setInstruction,
                                }}
                            />

                            <HR />
                        </>
                    );
                }
                break;
            }
        }
    }

    return (
        <ResponsiveSidebarDialog
            open={!!exception}
            onClose={callbacks.onClose}
            fullWidth={true}
            maxWidth="md"
            css={css`
                .MuiDialog-container {
                    align-items: normal;
                }
            `}
        >
            <ModalTitle
                border
                title={`Order ${order.order_number || ''}${order.po_number ? ` / ${order.po_number}` : ''} - ${
                    order.order_type
                }`}
                onClose={callbacks.onClose}
            />
            <DialogContent
                css={css`
                    width: 100%;
                    padding: 0;
                `}
            >
                <Grid
                    constainer
                    direction="column"
                    css={css`
                        height: 100%;
                        padding: 0 24px;

                        padding-top: 32px;
                    `}
                >
                    {resolutions}

                    <OrderInfo order={order} exception={exception} />

                    {items ? <ItemInfo items={items} /> : null}

                    <ExceptionInfo exception={exception} />
                </Grid>
            </DialogContent>
            <StickyModalActions
                border="true"
                css={css`
                    justify-content: flex-end;
                `}
            >
                <Grid>
                    <PrimaryButton onClick={callbacks.onClose}>Done</PrimaryButton>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ExceptionResolutionModal;
