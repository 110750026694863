import { gql } from '@apollo/client';
import { EXCEPTION_FIELDS } from './db_exceptions';
import { PALLET_ITEM_FIELDS } from './pallet_items';

export const ONLY_ITEM_FIELDS = gql`
    fragment OnlyItemFields on items {
        app_type
        assembled
        assembled_height
        assembled_length
        assembled_width
        assembly_type
        bed_item
        bed_item_size
        category
        class
        comes_assembled
        comms
        cubes
        cubes_per_unit
        custom_dimensions
        custom_fields
        description
        dimensions_as_ranges
        fb
        file
        from_csv
        furniture_type
        hb
        height
        is_oven_cabinet
        is_packaged
        is_return
        item_id
        item_piece_count
        item_type
        item_type_details
        legacy_order_id
        length
        name
        nfmc_no
        order_id
        package_seq_id
        pallet_id
        pic
        picture_file_name
        quantity
        return_or_dispose
        service_time
        side
        sku
        stack
        staged
        standard_pallet
        total_cubes
        total_cubes_only
        total_weight
        trim_parts_quantity
        weight
        width
        item_sku_status
    }
`;

export const ITEM_FIELDS = gql`
    ${ONLY_ITEM_FIELDS}
    ${EXCEPTION_FIELDS}
    ${PALLET_ITEM_FIELDS}
    fragment ItemFields on items {
        ...OnlyItemFields
        exceptions {
            created_at
            exception_type
            event_id
            exception {
                ...ExceptionFields
            }
        }
        item_exception @client {
            ...ExceptionFields
        }
        pallet {
            ...PalletItemFields
        }
    }
`;

export const FULL_QUERY = gql`
    ${ITEM_FIELDS}

    query GetItems {
        items(limit: 100) {
            ...ItemFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${ITEM_FIELDS}

    query items($item_id: String) {
        items(limit: 100, where: { item_id: { _eq: $item_id } }) {
            ...ItemFields
        }
    }
`;
