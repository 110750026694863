import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Grid, MenuItem, FormControl, IconButton, TextField, Tooltip } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    StickyModalActions,
    ModalContent,
    ResponsiveSidebarDialog,
    Row,
    PrimaryButton,
    SecondaryButton,
    SectionTitle,
    SectionSubtitle,
    ModalBody,
    ModalActions,
} from '@/styles/blocks';
import { typography } from '@/styles';
import { useClientUser } from '@/hooks/useClientUser';
import { OnwardCheckbox, Select } from '../ShipmentForm/blocks';
import { DELETE_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';
import { captureException } from '@sentry/react';
import { QUERY_EXPORT_TEMPLATES_BY_USER } from '@/graphql/queries/csv_export_templates';
import { InfoCircleFilled } from '@ant-design/icons';

const SelectLabel = styled.span`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
`;

const ExportCsvTemplateModal = ({ open, setOpen, exportType, csvExportColumns, callbacks }) => {
    const { user_id } = useClientUser();
    const [openNamingModal, setOpenNamingModal] = useState(false);
    const [templateKey, setTemplateKey] = useState('');
    const [selectedColumns, setSelectedColumns] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [willSave, setWillSave] = useState(false);
    const [selectAll, setSelectAll] = useState({});
    const [includeAccessorials, setIncludeAccessorials] = useState(true);

    const reset = () => {
        setTemplateName('');
        setTemplateKey('');
        setWillSave(false);
        setOpenNamingModal(false);
        setOpen(false);
    };

    useEffect(() => {
        if (!user_id || !open) return;

        fetchCsvTemplates({
            variables: {
                where: { _and: { user_id: { _eq: user_id }, export_type: { _eq: exportType } } },
            },
        });
    }, [user_id, open]);

    useEffect(() => {
        const initialSelectedColumns = {};
        const initialSelectAll = {};

        Object.keys(csvExportColumns).forEach((colType) => {
            initialSelectedColumns[colType] = csvExportColumns[colType].reduce((acc, column) => {
                acc[column.header] = true;
                return acc;
            }, {});
            initialSelectAll[colType] = true;
        });

        setSelectedColumns(initialSelectedColumns);
        setSelectAll(initialSelectAll);
    }, [csvExportColumns]);

    const [fetchCsvTemplates, { data: templateData, loading: templatesLoading }] = useLazyQuery(
        QUERY_EXPORT_TEMPLATES_BY_USER,
        {
            onError: (error) => {
                captureException(error);
                console.log(error.message);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    const [deleteTemplate] = useMutation(DELETE_CSV_EXPORT_TEMPLATE, {
        update: (cache, { data: { removed } }) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'csv_export_templates' });
        },
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const templates = useMemo(() => {
        if (templateData) {
            return Object.fromEntries(
                templateData.csv_export_templates.map((template) => [template.template_id, template])
            );
        }

        return {};
    }, [templateData]);

    const selectTemplate = (e) => {
        const templateKey = e.target.value;
        setTemplateKey(templateKey);
        if (templateKey) {
            setSelectedColumns(templates[templateKey].template);
            setIncludeAccessorials(templates[templateKey].template?.Order?.Accessorials);
        } else {
            const initialSelectedColumns = {};
            Object.keys(csvExportColumns).forEach((colType) => {
                initialSelectedColumns[colType] = csvExportColumns[colType].reduce((acc, column) => {
                    acc[column.header] = true;
                    return acc;
                }, {});
            });
            setSelectedColumns(initialSelectedColumns);
        }
    };

    const handleTemplateDelete = (e, key) => {
        e.stopPropagation();
        deleteTemplate({ variables: { template_id: key } });
    };

    const handleCheckboxChange = (header, colType) => {
        setSelectedColumns((prev) => {
            const newSelectedColumns = {
                ...prev,
                [colType]: {
                    ...prev[colType],
                    [header]: !prev[colType][header],
                },
            };

            // lump item columns together
            if (colType === 'Order' && header === 'Item') {
                const relatedHeaders = ['SKU', 'Quantity', 'Weight (lb)', 'L (in)', 'W (in)', 'H (in)'];
                relatedHeaders.forEach((relatedHeader) => {
                    newSelectedColumns[colType][relatedHeader] = newSelectedColumns[colType][header];
                });
            }

            return newSelectedColumns;
        });
    };

    const handleSelectAllChange = (colType) => {
        const newSelectAll = !selectAll[colType];
        setSelectAll((prev) => ({
            ...prev,
            [colType]: newSelectAll,
        }));

        setSelectedColumns((prev) => ({
            ...prev,
            [colType]: csvExportColumns[colType].reduce((acc, column) => {
                acc[column.header] = newSelectAll;
                return acc;
            }, {}),
        }));

        if (colType === 'Order') {
            setIncludeAccessorials(newSelectAll);
        }
    };

    useEffect(() => {
        if (selectedColumns) {
            const newSelectAll = {};
            Object.keys(csvExportColumns).forEach((colType) => {
                newSelectAll[colType] = csvExportColumns[colType].every((col) => selectedColumns[colType][col.header]);
                if (colType === 'Order') {
                    newSelectAll[colType] = newSelectAll[colType] && includeAccessorials;
                }
            });
            setSelectAll(newSelectAll);
        }
    }, [selectedColumns, includeAccessorials]);

    return (
        <>
            <ResponsiveSidebarDialog
                open={open}
                onClose={() => {
                    reset();
                }}
            >
                <ModalContent width={exportType !== 'ROUTE' ? 600 : 1000}>
                    <Grid
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <SectionTitle>Export</SectionTitle>
                    </Grid>

                    <FormControl
                        variant="outlined"
                        css={css`
                            width: 50%;
                            margin-bottom: 40px;
                            & .MuiSelect-selectMenu {
                                padding: 8px;
                            }
                        `}
                    >
                        <SelectLabel>Use template</SelectLabel>
                        <Select
                            value={templateKey}
                            onChange={selectTemplate}
                            displayEmpty
                            renderValue={(e) => {
                                return templates[templateKey]?.name || 'Select template...';
                            }}
                        >
                            <MenuItem value={''}>Select template...</MenuItem>
                            {!templatesLoading &&
                                Object.entries(templates).map(([key, template]) => (
                                    <MenuItem
                                        key={`template-menu-${key}`}
                                        value={key}
                                        css={css`
                                            justify-content: space-between;
                                        `}
                                    >
                                        {template.name}
                                        {key !== 'default' && (
                                            <IconButton
                                                css={css`
                                                    padding: 0;
                                                `}
                                            >
                                                <DeleteForeverIcon
                                                    onClick={(e) => handleTemplateDelete(e, key)}
                                                    css={css`
                                                        color: black;
                                                    `}
                                                />
                                            </IconButton>
                                        )}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Grid className="mb-4" container>
                        <SectionTitle
                            css={css`
                                font-weight: 800;
                            `}
                        >
                            Select fields to export
                        </SectionTitle>
                        {exportType === 'ROUTE' && (
                            <div
                                css={css`
                                    margin-bottom: 1rem;
                                `}
                            >
                                Each section will export as a separate CSV. Deselect all values in a section to not
                                export a csv for that type.
                            </div>
                        )}
                        <Grid container direction="row">
                            {Object.keys(csvExportColumns).map((colType) => (
                                <Grid item container direction="column" xs={4} key={colType}>
                                    <SectionSubtitle>{colType} Fields</SectionSubtitle>
                                    <Grid item>
                                        <OnwardCheckbox
                                            label="Select All"
                                            checked={selectAll[colType]}
                                            onChange={() => handleSelectAllChange(colType)}
                                        />
                                    </Grid>
                                    {csvExportColumns[colType].map((col) => {
                                        if (
                                            colType === 'Order' &&
                                            ['SKU', 'Quantity', 'Weight (lb)', 'L (in)', 'W (in)', 'H (in)'].includes(
                                                col.header
                                            )
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <Grid item key={col.header}>
                                                <Grid container alignItems="center">
                                                    <OnwardCheckbox
                                                        label={col.header}
                                                        checked={
                                                            selectedColumns
                                                                ? selectedColumns[colType][col.header]
                                                                : false
                                                        }
                                                        onChange={() => handleCheckboxChange(col.header, colType)}
                                                    />
                                                    {colType === 'Order' && col.header === 'Item' && (
                                                        <Tooltip title="Selecting this will create a new row for each item on the order and include various item fields.">
                                                            <InfoCircleFilled
                                                                css={css`
                                                                    margin-left: 8px;
                                                                    font-size: 16px;
                                                                `}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    {colType === 'Order' && (
                                        <Grid item>
                                            <OnwardCheckbox
                                                label={'Accessorials'}
                                                checked={includeAccessorials}
                                                onChange={() => setIncludeAccessorials(!includeAccessorials)}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </ModalContent>
                {/* Modal Actions  */}
                <StickyModalActions border="true">
                    <SecondaryButton
                        onClick={() => {
                            reset();
                        }}
                    >
                        {'Back'}
                    </SecondaryButton>
                    <OnwardCheckbox
                        label="Save as template"
                        checked={willSave}
                        onChange={() => {
                            setWillSave((prev) => !prev);
                        }}
                    />
                    <PrimaryButton
                        onClick={() => {
                            if (willSave) {
                                setOpenNamingModal(true);
                            } else {
                                callbacks.onExport(selectedColumns, templateName, exportType, includeAccessorials);
                                reset();
                            }
                        }}
                    >
                        Export
                    </PrimaryButton>
                </StickyModalActions>
            </ResponsiveSidebarDialog>
            <ResponsiveSidebarDialog
                open={openNamingModal}
                onClose={() => {
                    setOpenNamingModal(false);
                }}
            >
                <ModalContent width={490}>
                    <SectionTitle>Give Template a Name</SectionTitle>

                    <ModalBody>
                        <TextField
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            variant="outlined"
                        />
                    </ModalBody>

                    <ModalActions>
                        <SecondaryButton
                            onClick={() => {
                                setTemplateName('');
                                setOpenNamingModal(false);
                            }}
                        >
                            {'Cancel'}
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => {
                                callbacks.onExport(selectedColumns, templateName, exportType, includeAccessorials);
                                reset();
                            }}
                            disabled={!templateName}
                        >
                            Next
                        </PrimaryButton>
                    </ModalActions>
                </ModalContent>
            </ResponsiveSidebarDialog>
        </>
    );
};

export default ExportCsvTemplateModal;
