import React, { useState, useEffect, useContext } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { Row, Container, Card, Form, Col, Modal } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { css } from '@emotion/react';
import { TextField } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import './styles.css';
import * as Sentry from '@sentry/react';
import { UPDATE_SHIPPER_CLIENT_INFO, UPDATE_USER_EMAIL } from '../mutations';
import { useMutation } from '@apollo/client';
import LocationPermissions from '../LocationPermissions';
import DataSharing from '../DataSharing';
import Communications from '../Communications';
import ProfileImageUpload from '../ProfileImageUpload';
import FleetSizeInput from '../FleetSizeInput';
import { UserContext } from '@/components/App';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import { CUSTOM_CUSTOMER_COMMS_RANGE } from '@/constants/featureFlags';
import CommunicationsRange from '../CommunicationsRange';
import { useClientUser } from '@/hooks';

const userComms = [
    {
        featureFlag: 'disable-review-sms-messages',
        display: 'Disable Carrier Review SMS Messages',
        description: 'Disables carrier review SMS messages sent to customers upon order completion.',
    },
    {
        featureFlag: 'sms-alert-next-stop-customers',
        display: 'Next Stop Customer Alert',
        description: 'This is an sms alert to customers on an FTL route when they are the next stop on the route',
    },
    {
        featureFlag: 'customer-emails-with-sms',
        display: 'Send Email Notifications in Addition to SMS',
        description:
            'Whenever SMS communications are sent to customers, an email will be sent to the customer as well with the corresponding information.',
    },
    {
        featureFlag: 'disable-confirm-timeframe-email',
        display: 'Disable Confirm Time Frame Email',
        description:
            'Disables the email that is sent to customers that asks them to confirm their set time frame from when you submit a route from the planning page',
    },
    {
        featureFlag: 'disable-customer-availability',
        display: 'Disable Predelivery Survey and Customer Availability',
        description: 'Disables the SMS asking users basic predelivery and customer availability information',
    },
    {
        featureFlag: 'delay-predelivery-comms',
        display: 'Delay Predelivery SMS until warehouse receipt',
        description: 'Dont send predelivery survey until the carrier has received the order',
    },
    {
        featureFlag: 'business-type-autoconfirm',
        display: 'Auto confirm timeframes for business type deliveries',
        description: 'Automatically confirms stop timeframes in planning for business type deliveries.',
    },
    {
        featureFlag: 'hide-driver-phone-number',
        display: 'Hide driver phone number on customer tracking links',
        description: 'On the customer tracking link, do not show the driver phone number.',
    },
];

function Profile(props) {
    const [supportEmail, setSupportEmail] = useState('');
    const [supportPhone, setSupportPhone] = useState('');
    const [primaryName, setPrimaryName] = useState('');
    const [secondaryName, setSecondaryName] = useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [emailModal, setEmailModal] = useState(false);
    const [fleetSize, setFleetSize] = useState(null);
    const [validated, setValidated] = useState(false);
    const { device_settings } = props;
    const { circles, isImposter } = useClientUser();

    const [updateClientInfo] = useMutation(UPDATE_SHIPPER_CLIENT_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const EmailModal = (props) => {
        const { show, onHide } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>

                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {isImposter ? 'Admins cannot change account emails at this time.' : ''}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            let userUpdatePayload = {
                username: primaryName,
                secondary_username: secondaryName,
                email: isImposter ? props.currentShipper.email : primaryEmail,
                secondary_email: secondaryEmail,
                business_phone: validate(primaryPhone) ? toE164(primaryPhone) : primaryPhone,
                secondary_phone: validate(secondaryPhone) ? toE164(secondaryPhone) : secondaryPhone,
                support_email: supportEmail,
                support_phone: validate(supportPhone) ? toE164(supportPhone) : supportPhone,
                ...(Number.isInteger(fleetSize) ? { fleet_size: fleetSize } : {}),
            };

            try {
                await updateClientInfo({
                    variables: {
                        client_id: props.currentShipperRef,
                        ...userUpdatePayload,
                    },
                });

                if (!isImposter && primaryEmail !== props.currentShipper.email) {
                    // Update firebase email
                    const auth = getAuth();
                    const user = auth.currentUser;
                    await updateEmail(user, primaryEmail);

                    // Updates the email in the user table
                    await updateUserEmail({
                        variables: {
                            user_id: props.currentShipperRef,
                            email: primaryEmail,
                        },
                    });
                }

                setUpdateMessage('Profile updated!');
            } catch (error) {
                setUpdateMessage(error);
                console.log(error);
                Sentry.captureException(error);
            }
        } else {
            setUpdateMessage('Please fill out required fields');
        }
        setValidated(true);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    useEffect(() => {
        setSupportEmail(props.currentShipper.support_email || '');
        setPrimaryName(props.currentShipper.username);
        setSecondaryName(props.currentShipper.secondary_username || '');
        setPrimaryEmail(props.currentShipper.email);
        setSecondaryEmail(props.currentShipper.secondary_email || '');
        setFleetSize(props.currentShipper.fleet_size);

        const _primaryPhone = props.currentShipper.business_phone
            ? validate(props.currentShipper.business_phone)
                ? toNational(props.currentShipper.business_phone)
                : props.currentShipper.business_phone
            : '';
        const _secondaryPhone = props.currentShipper.secondary_phone
            ? validate(props.currentShipper.secondary_phone)
                ? toNational(props.currentShipper.secondary_phone)
                : props.currentShipper.secondary_phone
            : '';
        const _supportPhone = props.currentShipper.support_phone
            ? validate(props.currentShipper.support_phone)
                ? toNational(props.currentShipper.support_phone)
                : props.currentShipper.support_phone
            : '';
        setPrimaryPhone(_primaryPhone);
        setSecondaryPhone(_secondaryPhone);
        setSupportPhone(_supportPhone);
    }, [props.currentShipper]);

    return (
        <>
            <Card className="tabCard">
                <Card.Body className="overflow-auto">
                    <Container fluid>
                        <ThemeProvider theme={theme}>
                            <Form id="accountDetails" noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row
                                    css={css`
                                        display: flex;
                                    `}
                                >
                                    <Col>
                                        <Form.Group
                                            css={css`
                                                margin-top: 8px;
                                                margin-bottom: 16px;
                                            `}
                                        >
                                            <TextField
                                                fullWidth
                                                value={supportEmail || ''}
                                                variant="outlined"
                                                label={'Support Contact Email'}
                                                onChange={(e) => setSupportEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            css={css`
                                                margin-top: 8px;
                                                margin-bottom: 16px;
                                            `}
                                        >
                                            <TextField
                                                fullWidth
                                                value={supportPhone || ''}
                                                variant="outlined"
                                                label={'Support Contact Phone'}
                                                onChange={(e) => setSupportPhone(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                error={!primaryName && validated}
                                                fullWidth
                                                className=""
                                                value={primaryName || ''}
                                                variant="outlined"
                                                label={'Name'}
                                                onChange={(e) => setPrimaryName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                required
                                                fullWidth
                                                className=""
                                                error={!primaryEmail && validated}
                                                value={primaryEmail || ''}
                                                variant="outlined"
                                                label={'Email'}
                                                onChange={(e) => setPrimaryEmail(e.target.value)}
                                                onClick={(e) => setEmailModal(true)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                label="Phone"
                                                variant="outlined"
                                                className="w-100"
                                                required
                                                onChange={(e) => setPrimaryPhone(e.target.value)}
                                                name="phone"
                                                error={!primaryPhone && validated}
                                                value={primaryPhone}
                                                id="formatted-phone-input"
                                                InputProps={{
                                                    inputComponent: PhoneNumberInput,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                fullWidth
                                                className=""
                                                value={secondaryName || ''}
                                                variant="outlined"
                                                label={'Secondary Contact Name'}
                                                onChange={(e) => setSecondaryName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                fullWidth
                                                className=""
                                                value={secondaryEmail || ''}
                                                variant="outlined"
                                                label={' Secondary Email'}
                                                onChange={(e) => setSecondaryEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="my-2 w-100">
                                            <TextField
                                                label="Secondary Phone"
                                                variant="outlined"
                                                className="w-100"
                                                onChange={(e) => setSecondaryPhone(e.target.value)}
                                                name="Secondary Phone"
                                                value={secondaryPhone}
                                                id="formatted-phone-input"
                                                InputProps={{
                                                    inputComponent: PhoneNumberInput,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FleetSizeInput fleetSize={fleetSize} setFleetSize={setFleetSize} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="submit" form="accountDetails" className="my-2 ml-3 account-btn">
                                            Save
                                        </Button>
                                        {updateMessage && <div>{updateMessage}</div>}
                                    </Col>
                                </Row>
                            </Form>
                            {/* removed for the time being at Drew's request. May add back in later.
                        <h3>Blacklist</h3>
                        <div>
                          <p>
                            Here you may select carrier companies that do not wish to work with.
                            Orders that you place will not be visible to blacklisted carriers.
                          </p>
                        </div>
                        <Blacklist
                            blacklistType='shipper'
                            firebase={props.firebase}
                            currentUser={props.currentShipperRef}
                            update={addToBlacklist}
                            /> */}
                        </ThemeProvider>
                    </Container>
                </Card.Body>
                <EmailModal show={emailModal} onHide={() => setEmailModal(false)} />
            </Card>

            <Communications comms={userComms} />
            <LocationPermissions device_settings={device_settings} />
            <DataSharing dataSharing={props.currentShipper?.data_share} />
            <ProfileImageUpload client={props.currentShipper} />
            {isImposter && <ProfileImageUpload client={props.currentShipper} whiteLabel={true} />}
            {circles?.[CUSTOM_CUSTOMER_COMMS_RANGE] && <CommunicationsRange client={props.currentShipper} />}
        </>
    );
}

export default Profile;
