import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { asBrowserDate } from '@/utilities/convertToISO';

import { TableBody } from './blocks';

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateFull = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
});

export const useJobColumns = (accounts = []) => {
    return useMemo(
        () => [
            ...(accounts?.length > 0
                ? [
                      {
                          id: 'client_id',
                          Header: 'Account',
                          accessor: (job) => {
                              const client = accounts.find((client) => client.client_id === job.client_id);
                              return client?.business_name || '--';
                          },
                          sortType: 'string',
                      },
                  ]
                : []),
            {
                id: 'created_at',
                Header: 'Date',
                accessor: (job) => {
                    return new Date(job.created_at);
                },
                Cell: ({ value }) => {
                    if (value.getTime() === 0) {
                        return '--';
                    }

                    return dateFull.format(value);
                },
                sortType: 'datetime',
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: (job) => {
                    return job.type;
                },
                Cell: ({ value, row }) => {
                    switch (value) {
                        case 'API':
                            return 'API';
                        case 'MANUAL':
                            return 'Manual';
                        case 'CSV':
                            return row.original.filename || '--';
                    }
                },
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: (job) => {
                    return job.status;
                },
                Cell: ({ value, row }) => {
                    switch (value) {
                        case 'PROCESSING':
                            return (
                                <TableBody
                                    css={css`
                                        color: #007bff;
                                    `}
                                >
                                    {`Processing - ${percentFormatter.format(
                                        row.original.batches_complete / row.original.batches_total
                                    )} Done`}
                                </TableBody>
                            );
                        case 'COMPLETE': {
                            const confirmed = !row.original.orders.some((order) =>
                                ['duplicate', 'pending_confirmation', 'processing', 'incomplete'].includes(
                                    order.order_status
                                )
                            );

                            if (confirmed) {
                                return (
                                    <TableBody
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    >
                                        Completed
                                    </TableBody>
                                );
                            }

                            return (
                                <TableBody
                                    css={css`
                                        color: ${colors.golds.primary};
                                    `}
                                >
                                    Pending final review
                                </TableBody>
                            );
                        }
                        case 'FAILED':
                            return (
                                <TableBody
                                    css={css`
                                        color: #d23e3e;
                                    `}
                                >
                                    Failed
                                </TableBody>
                            );
                    }
                },
            },
            {
                id: 'errors',
                Header: 'Errors',
                accessor: (job) => {
                    return job.orders.filter((order) => order.order_status === 'incomplete').length;
                },
            },
        ],
        [accounts]
    );
};
