import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router';

import { Grid, TextField, MenuItem, Checkbox, InputAdornment, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import { ACCOUNTING_V2 } from '@/constants/featureFlags';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import * as ROUTES from '@/constants/routes';
import SearchIcon from '@material-ui/icons/Search';

import CreateReceivableInvoice from './modals/CreateReceivableInvoice';
import Footer from './Footer';
import Table from './Table';
import { Context, ContextProvider } from './store';
import { H1, Body1 } from './blocks';

const InternalToggle = ({ state, loading, callbacks }) => {
    const navigate = useNavigate();
    const { circles, roles } = useClientUser();
    const enabled = !!circles?.[ACCOUNTING_V2] || roles['ONWARD_ADMIN'];

    return (
        <Grid
            direction="row"
            item
            css={css`
                display: flex;
                background-color: white;
            `}
        >
            <Grid
                direction="column"
                container
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-content: center;
                `}
            >
                <OnwardTabContainer value={1} textColor="primary" indicatorColor="primary" centered>
                    <OnwardTab
                        label={
                            !enabled ? (
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        justify-content: center;
                                    `}
                                >
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex-basis: 0;
                                        `}
                                    >
                                        <LockIcon
                                            css={css`
                                                font-size: 20px;
                                                justify-content: center;

                                                margin-right: 8px;
                                            `}
                                        />
                                    </Grid>
                                    <Grid
                                        direction="column"
                                        container
                                        css={css`
                                            flex-basis: 0;
                                        `}
                                    >
                                        Internal
                                    </Grid>
                                </Grid>
                            ) : (
                                <>Internal</>
                            )
                        }
                        disabled={!enabled}
                        value={0}
                        onClick={() => {
                            navigate(ROUTES.CARRIER_INVOICES);
                        }}
                    />
                    <OnwardTab label="Onward" value={1} />
                </OnwardTabContainer>
            </Grid>
        </Grid>
    );
};

export const Accounting = ({ context, title, TopBar, Footer }) => {
    const { state, loading, callbacks } = useContext(context);

    return (
        <>
            <Grid
                direction="column"
                container
                css={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <TopBar state={state} loading={loading} callbacks={callbacks} />

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <H1>{title}</H1>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid container direction="row">
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    overflow: hidden;
                                    margin-right: 16px;
                                `}
                            >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Search"
                                    placeholder="Search..."
                                    value={state?.filter?.searchTerm || ''}
                                    onChange={(e) => {
                                        callbacks.setFilter((prev) => {
                                            return {
                                                ...prev,
                                                searchTerm: e.target.value,
                                            };
                                        });
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    css={css`
                                        background-color: white;
                                        width: 100%;
                                        margin-top: 0.2rem;
                                    `}
                                />
                            </Grid>
                            {state.shippers ? (
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        overflow: hidden;
                                        margin-right: 16px;
                                    `}
                                >
                                    <TextField
                                        variant="outlined"
                                        select
                                        fullWidth
                                        label="Shipper"
                                        value={state?.filter?.shippers || []}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            callbacks.setFilter((prev) => {
                                                return {
                                                    ...prev,
                                                    shippers: e.target.value,
                                                };
                                            });
                                        }}
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) => {
                                                const map = Object.fromEntries(
                                                    state.shippers.map(({ label, value }) => {
                                                        return [value, label];
                                                    })
                                                );
                                                return selected.map((opt) => map[opt]).join(', ');
                                            },
                                        }}
                                        css={css`
                                            background-color: white;
                                            width: 100%;
                                            padding-right: 2px;
                                            margin-top: 0.2rem;
                                        `}
                                    >
                                        {state.shippers.map(({ label, value }) => (
                                            <MenuItem key={value} value={value}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={(state?.filter?.shippers || []).indexOf(value) > -1}
                                                />
                                                <Body1>{label}</Body1>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : null}

                            {state.carriers ? (
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        overflow: hidden;
                                        margin-right: 16px;
                                    `}
                                >
                                    <TextField
                                        variant="outlined"
                                        select
                                        fullWidth
                                        label="Carrier"
                                        value={state?.filter?.carriers || []}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            callbacks.setFilter((prev) => {
                                                return {
                                                    ...prev,
                                                    carriers: e.target.value,
                                                };
                                            });
                                        }}
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) => {
                                                const map = Object.fromEntries(
                                                    state.carriers.map(({ label, value }) => {
                                                        return [value, label];
                                                    })
                                                );
                                                return selected.map((opt) => map[opt]).join(', ');
                                            },
                                        }}
                                        css={css`
                                            background-color: white;
                                            width: 100%;
                                            padding-right: 2px;
                                            margin-top: 0.2rem;
                                        `}
                                    >
                                        {state.carriers.map(({ label, value }) => (
                                            <MenuItem key={value} value={value}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={(state?.filter?.carriers || []).indexOf(value) > -1}
                                                />
                                                <Body1>{label}</Body1>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : null}

                            {state.weeks ? (
                                <Grid
                                    container
                                    direction="column"
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                        overflow: hidden;
                                    `}
                                >
                                    <TextField
                                        variant="outlined"
                                        select
                                        fullWidth
                                        label="Pay Period"
                                        value={state?.filter?.week?.week_number || ''}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    css={css`
                                                        margin-right: 20px;
                                                    `}
                                                >
                                                    <IconButton
                                                        css={css`
                                                            padding: 4px;
                                                        `}
                                                        onClick={() => {
                                                            callbacks.setFilter((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    week: undefined,
                                                                };
                                                            });
                                                        }}
                                                    >
                                                        <CloseIcon
                                                            css={css`
                                                                font-size: 14px;
                                                            `}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => {
                                            const obj = state?.weeks.find(({ week_number }) => {
                                                return week_number === e.target.value;
                                            });
                                            callbacks.setFilter((prev) => {
                                                return {
                                                    ...prev,
                                                    week: obj,
                                                };
                                            });
                                        }}
                                        css={css`
                                            background-color: white;
                                            width: 100%;
                                            padding-right: 2px;
                                            margin-top: 0.2rem;
                                        `}
                                    >
                                        {state?.weeks.map(({ week_number, label }) => (
                                            <MenuItem key={week_number} value={week_number}>
                                                <Body1>{label}</Body1>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    css={css`
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 0 40px;
                    `}
                >
                    <Table state={state} loading={loading} callbacks={callbacks} />
                </Grid>

                <Footer state={state} loading={loading} callbacks={callbacks} />
            </Grid>

            <CreateReceivableInvoice
                open={state.shippers && state.createReceivable}
                shippers={state.shippers}
                loading={loading}
                callbacks={callbacks}
            />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} title="Invoices" TopBar={InternalToggle} Footer={Footer} />
        </ContextProvider>
    );

export default compose(withContext)(Accounting);
