import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import cache from '@/graphql';
import { subMonths } from 'date-fns';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import useAction from '@/utilities/useQuery';
import { useClientUser } from '@/hooks';
import { genResources } from '@/components/Resources/utils';

import { GET_ORDERS, GET_RESOURCES, GET_SHIPPERS, SET_DELIVERY_DATE } from './graphql';
import { retryMessages } from './queries/retryMessages';
import { sendPredelivery } from './queries/sendPredelivery';
import { scheduleCall } from './queries/scheduleCall';
import { COLUMNS } from './columns';

export const Context = createContext();

const TODAY = new Date().toISOString();
const cutoff = subMonths(new Date(TODAY), 1);

export const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [toSchedule, setToSchedule] = useState([]);
    const [selected, setSelected] = useState({});
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState({
        search: null,
        shipper: null,
        resources: [],
    });

    const { data: resourcesData, loading: resourcesLoading } = useQuery(GET_RESOURCES, {
        variables: {
            client_id: user_id,
            cutoff,
        },
    });

    const [getOrders, { data, loading: initInflight, fetchMore }] = useLazyQuery(GET_ORDERS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        onError: (err) => {
            setNotification({ message: 'Failed to retrieve orders', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [getShippers, { data: shipperData }] = useLazyQuery(GET_SHIPPERS, {
        onError: (err) => {
            setNotification({ message: 'Failed to retrieve shippers', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [setDeliveryDate, { loading: scheduleInflight }] = useMutation(SET_DELIVERY_DATE, {
        onError: (err) => {
            setNotification({ message: 'Failed to set delivery date', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const orders = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const resources = useMemo(() => {
        return resourcesData?.results || [];
    }, [resourcesData]);

    const shippers = useMemo(() => {
        return shipperData?.results?.map((order) => order.order_shipper) || [];
    }, [shipperData]);

    const SEARCHABLE = [
        'order_number',
        'po_number',
        'dropoff_name',
        'dropoff_state',
        'dropoff_city',
        'dropoff_zip',
        'pickup_state',
        'pickup_city',
        'pickup_zip',
        'order_type',
    ];

    const ordersById = useMemo(() => {
        return Object.fromEntries(
            orders.map((order) => {
                const { default: defaultResources, override: overrideResources } = genResources({
                    client: { available_resources: resources },
                    order,
                });
                const valid = overrideResources.length > 0 ? overrideResources : defaultResources;

                return [order.order_id, { ...order, resources: valid }];
            })
        );
    }, [orders]);

    const filtered = useMemo(() => {
        return Object.values(ordersById).filter((order) => {
            if (filter.resources.length > 0) {
                const filtered = filter.resources.map((resource) => resource.resource_id);
                return (order.resources || []).some((resource) => {
                    return filtered.includes(resource.resource_id);
                });
            }
            return true;
        });
    }, [ordersById, filter]);

    const where = useMemo(() => {
        return [
            {
                _or: [
                    { wh_events: { action: { _eq: 'START:RECEIVING' }, status: { _eq: 'RECEIVED' } } },
                    { order_type: { _eq: 'return' } },
                ],
            },
            ...(filter.search
                ? [
                      {
                          _or: SEARCHABLE.map((field) => ({ [field]: { _ilike: `%${filter.search}%` } })),
                      },
                  ]
                : []),
            ...(filter.shipper ? [{ shipper_id: { _eq: filter.shipper } }] : []),

            { delivery_date: { _is_null: true } },
            { planning: { _eq: false } },
            { order_status: { _in: ['pending', 'active', 'open', 'claimed'] } },
            {
                _or: [
                    {
                        _and: [{ oms: { _eq: true } }, { shipper_id: { _eq: user_id } }],
                    },
                    { carrier_id: { _eq: user_id } },
                ],
            },
        ];
    }, [filter]);

    const [retry, { loading: retryInflight }] = useAction(retryMessages, {
        onComplete: (resp) => {
            const added = resp?.data?.messages || [];

            cache.updateQuery(
                {
                    query: GET_ORDERS,
                    variables: {
                        cutoff,
                        where: {
                            _and: where,
                        },
                    },
                },
                (data) => {
                    const clone = [...data.results];

                    added.forEach((message) => {
                        const idx = clone.findIndex((order) => order.order_id === message.order_id);
                        if (idx >= 0) {
                            clone[idx] = {
                                ...clone[idx],
                                messages: [message, ...(clone[idx]?.messages || [])],
                            };
                        }
                    });

                    return {
                        results: clone,
                    };
                }
            );
        },
        onError: (err) => {
            setNotification({ message: 'Failed to retry sms message', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [predelivery, { loading: sendInflight }] = useAction(sendPredelivery, {
        onError: (err) => {
            setNotification({ message: 'Failed to send predelivery survey', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    const [scheduleOrderDelivery, { loading: scheduleCallInflight }] = useAction(scheduleCall, {
        onError: (err) => {
            setNotification({ message: 'Failed to create schedule delivery call', severity: 'error' });
            Sentry.captureException(err);
        },
    });

    useEffect(() => {
        getOrders({
            variables: {
                cutoff,
                where: {
                    _and: where,
                },
            },
        });

        getShippers({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, [where]);

    const getOrdersDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getOrders(payload);
            }, 500),
        []
    );

    useEffect(() => {
        if (!initInflight) {
            getOrdersDebounced({
                variables: {
                    cutoff,
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getOrdersDebounced, initInflight]);

    const cursor = useMemo(() => {
        if (orders.length === 0) {
            return null;
        }

        return orders[orders.length - 1].created_at;
    }, [orders]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(data.results.map((order) => [order.order_id, true]));
                const clone = [...data.results, ...fetchMoreResult.results.filter((order) => !prev[order.order_id])];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const selectedIds = useMemo(() => {
        return Object.keys(selected).filter((attr) => selected[attr]);
    }, [selected]);

    const selectedResources = useMemo(() => {
        const [all, count] = selectedIds
            .map((id) => ordersById[id])
            .reduce(
                ([all, count], order) => {
                    const { default: defaultResources, override: overrideResources } = genResources({
                        client: { available_resources: resources },
                        order,
                    });

                    let orderResources = overrideResources.length > 0 ? overrideResources : defaultResources;

                    orderResources.forEach((resource) => {
                        all[resource.resource_id] = resource;
                        count[resource.resource_id] = count[resource.resource_id] ? count[resource.resource_id] + 1 : 1;
                    });

                    return [all, count];
                },
                [{}, {}]
            );

        return Object.entries(count)
            .filter(([, count]) => {
                return count === selectedIds.length;
            })
            .map(([id]) => all[id]);
    }, [selectedIds, ordersById, resources]);

    const callbacks = {
        closeModal: () => {
            setToSchedule([]);
        },
        selectOrders: setSelected,
        scheduleOrder: setToSchedule,
        setFilter,
        loadMore,
        setNotification,
        scheduleCall: (ids) => {
            return Promise.all(
                ids.map((id) => {
                    const order = ordersById[id];
                    return scheduleOrderDelivery({ order });
                })
            );
        },
        retryMessages: (ids) => {
            return predelivery({ order_ids: ids });
        },
        setDeliveryDate: ({ updates }) => {
            return setDeliveryDate({
                variables: {
                    updates: updates.map(({ order, updates }) => {
                        return {
                            where: {
                                order_id: { _eq: order.order_id },
                            },
                            _set: updates,
                        };
                    }),
                },
            });
        },
        clearNotification: () => {
            setNotification({});
        },
    };

    return (
        <Context.Provider
            value={{
                state: {
                    columns: COLUMNS,
                    filter,
                    hasMore,
                    notification,
                    orders: filtered,
                    ordersById,
                    selected: selectedIds,
                    shippers,
                    toSchedule,
                    selectedResources,
                    resources,
                },
                loading: {
                    scheduling: scheduleInflight,
                    retry: retryInflight || sendInflight,
                    call: scheduleCallInflight,
                    init: initInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
