import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router';
import { css } from '@emotion/react';
import { asBrowserDate } from '@/utilities/convertToISO';
import { TextField, Grid, MenuItem, FormControl, Select, Checkbox, ListItemText, InputLabel } from '@material-ui/core';
import { format } from 'date-fns';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import withAdminRights from '@/components/Auth/withAdminRights';
import { EXCEPTION_TYPES, EXCEPTION_DISPLAY } from '@onward-delivery/core';

import ExceptionResolutionModal from './modals/ExceptionResolutionModal';
import Table from './Table';
import { Context, ContextProvider } from './store';
import { TABS } from './constants';
import { H1 } from './blocks';
import { PrimaryButton } from '@/styles/blocks';

export const AdminExceptions = ({ context }) => {
    const navigate = useNavigate();
    const { state, loading, callbacks } = useContext(context);

    return (
        <>
            <Grid
                direction="column"
                container
                css={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <Grid
                    direction="row"
                    item
                    css={css`
                        display: flex;
                        background-color: white;
                    `}
                >
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            align-content: center;
                        `}
                    >
                        <OnwardTabContainer
                            value={state.filter.status}
                            onChange={(e, t) => {
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        status: t,
                                    };
                                });
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            {TABS.map((tab) => (
                                <OnwardTab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </OnwardTabContainer>
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                            padding: 0 16px;
                        `}
                    >
                        <PrimaryButton loading={loading.export} onClick={callbacks.exportCSV}>
                            Export
                        </PrimaryButton>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Exception Manager</H1>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        flex-wrap: nowrap;
                        padding: 0 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 16px;
                        `}
                    >
                        <TextField
                            type="date"
                            variant="outlined"
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            label="Start Date"
                            value={state?.filter?.start ? format(state?.filter?.start, 'yyyy-MM-dd') : ''}
                            onChange={(e) =>
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        start: asBrowserDate(e.target.value),
                                    };
                                })
                            }
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 16px;
                        `}
                    >
                        <TextField
                            type="date"
                            variant="outlined"
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            label="End Date"
                            value={state?.filter?.end ? format(state?.filter?.end, 'yyyy-MM-dd') : ''}
                            onChange={(e) =>
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        end: asBrowserDate(e.target.value),
                                    };
                                })
                            }
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        flex-wrap: nowrap;
                        padding: 0 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                            margin-right: 16px;
                        `}
                    >
                        {state.shippers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Exception Type"
                                value={state?.filter?.type || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            type: e.target.value,
                                        };
                                    });
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {Object.values(EXCEPTION_TYPES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {EXCEPTION_DISPLAY[key]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                            margin-right: 16px;
                        `}
                    >
                        {state.shippers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Occurred At"
                                value={state?.filter?.occurred_at || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            occurred_at: e.target.value,
                                        };
                                    });
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {['DROPOFF', 'PICKUP'].map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                            margin-right: 16px;
                        `}
                    >
                        {state.shippers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Shipper"
                                value={state?.filter?.shippers || []}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            shippers: e.target.value,
                                        };
                                    });
                                }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => {
                                        const map = Object.fromEntries(
                                            state.shippers.map(({ label, value }) => {
                                                return [value, label];
                                            })
                                        );
                                        return selected.map((opt) => map[opt]).join(', ');
                                    },
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {Object.values(state.shippers).map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        <Checkbox
                                            checked={(state?.filter?.shippers || []).indexOf(value) > -1}
                                            color="primary"
                                        />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                        `}
                    >
                        {state.carriers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Carrier"
                                value={state?.filter?.carriers || []}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            carriers: e.target.value,
                                        };
                                    });
                                }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => {
                                        const map = Object.fromEntries(
                                            state.carriers.map(({ label, value }) => {
                                                return [value, label];
                                            })
                                        );
                                        return selected.map((opt) => map[opt]).join(', ');
                                    },
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {Object.values(state.carriers).map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        <Checkbox
                                            checked={(state?.filter?.carriers || []).indexOf(value) > -1}
                                            color="primary"
                                        />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                </Grid>

                <Grid
                    item
                    css={css`
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 0 40px;
                    `}
                >
                    <Table state={state} loading={loading} callbacks={callbacks} />
                </Grid>
            </Grid>
            <ExceptionResolutionModal
                {...state.toResolve}
                loading={loading.resolve}
                callbacks={{
                    onClose: () => {
                        callbacks.closeModal({});
                    },
                    onResolve: ({ toSubmit, items = [], order = {}, exception = {}, event = {} }) => {
                        const toRemove = items.map((item) => item.item_id);
                        const { order_id, ...orderUpdates } = order;
                        const { event_id, ...eventUpdates } = event;
                        const { exception_id, ...exceptionUpdates } = exception;

                        return Promise.all([
                            callbacks.resolve({
                                variables: {
                                    orders: order_id ? [order_id] : [],
                                    events: event_id ? [event_id] : [],
                                    exceptions: exception_id ? [exception_id] : [],
                                    items: toRemove,
                                    order_update: orderUpdates,
                                    event_update: eventUpdates,
                                    exception_update: exceptionUpdates,
                                    include_route_update: false,
                                },
                            }),
                            ...(toSubmit
                                ? [
                                      callbacks.submitOrders({
                                          client_id: toSubmit.shipper_id,
                                          orders: [toSubmit],
                                          filename: undefined,
                                          type: 'MANUAL',
                                      }),
                                  ]
                                : []),
                        ]).then(async ([, resp]) => {
                            if (resp?.data?.jobs?.length > 0) {
                                if (exception.resolution === 'CREATE_RETURN') {
                                    await callbacks.saveAssociatedReturnOrder(order_id, resp.data.jobs[0]);
                                }
                                navigate(`/job/${resp.data.jobs[0]}`);
                            } else {
                                callbacks.closeModal({});
                            }
                        });
                    },
                }}
            />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withAdminRights, withContext)(AdminExceptions);
