import { MANIFEST_FIELDS } from '@/graphql/queries/manifests';
import { INBOUND_ITEM_FIELDS } from '@/graphql/queries/manifests_inbound_items';
import { ROUTE_MANIFEST_FIELDS } from '@/graphql/queries/routes';
import { EXCEPTION_FIELDS } from '@/graphql/queries/db_exceptions';
import { gql } from '@apollo/client';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { PALLET_ITEM_FIELDS } from '@/graphql/queries/pallet_items';

export const GET_MANIFESTS = gql`
    ${MANIFEST_FIELDS}
    ${INBOUND_ITEM_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    query GetManifests($client_id: uuid!, $cutoff: timestamptz!, $filters: [manifests_bool_exp!] = []) {
        manifests(
            where: {
                client_id: { _eq: $client_id }
                created_at: { _gte: $cutoff }
                _or: [{ route_id: { _is_null: true } }, { route: { status: { _neq: "deleted" } } }]
                _and: $filters
            }
            order_by: { created_at: desc }
        ) {
            ...ManifestFields
            items {
                ...InboundItemFields
            }
            route {
                ...RouteManifestFields
            }
        }
    }
`;

export const GET_EXTERNAL_MANIFESTS = gql`
    ${MANIFEST_FIELDS}
    ${INBOUND_ITEM_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    query GetManifests($client_id: uuid!, $cutoff: timestamptz!, $filters: [manifests_bool_exp!] = []) {
        manifests(
            where: {
                items: { item: { order: { shipper_id: { _eq: $client_id } } } }
                client_id: { _neq: $client_id }
                share_manifest: { _eq: true }
                created_at: { _gte: $cutoff }
                _or: [{ route_id: { _is_null: true } }, { route: { status: { _neq: "deleted" } } }]
                _and: $filters
            }
            order_by: { created_at: desc }
        ) {
            ...ManifestFields
            items {
                ...InboundItemFields
            }
            route {
                ...RouteManifestFields
            }
        }
    }
`;

export const INSERT_MANIFEST = gql`
    ${MANIFEST_FIELDS}
    ${INBOUND_ITEM_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    mutation InsertManifest($manifest: manifests_insert_input!) {
        created: insert_manifests_one(object: $manifest) {
            ...ManifestFields
            items {
                ...InboundItemFields
            }
            route {
                ...RouteManifestFields
            }
        }
    }
`;

export const GET_ORDERS = gql`
    ${EXCEPTION_FIELDS}
    ${ITEM_FIELDS}
    ${MANIFEST_FIELDS}
    ${PALLET_ITEM_FIELDS}

    query GetManifestOrders($client_id: uuid!, $cutoff: timestamptz, $filters: [orders_bool_exp!] = []) {
        orders(
            where: {
                _or: [
                    { carrier_id: { _eq: $client_id } }
                    { _and: [{ shipper_id: { _eq: $client_id } }, { carrier_id: { _is_null: true } }] }
                ]
                order_status: { _in: ["pending", "active", "routed", "claimed", "inProgress", "routedInProgress"] }
                _and: $filters
                created_at: { _gte: $cutoff }
            }
            order_by: { created_at: desc }
        ) {
            order_id
            order_number
            order_status
            po_number
            shipper_id
            itemsByOrderId {
                ...ItemFields
                manifests {
                    mapping_id
                    manifest_id
                    item_id
                    manifest {
                        ...ManifestFields
                    }
                }
            }
        }
    }
`;

export const UPSERT_ITEMS = gql`
    ${ITEM_FIELDS}
    mutation UpsertItems($items: [items_insert_input!]!) {
        insert_items(objects: $items, on_conflict: { constraint: items_pkey, update_columns: [quantity, pallet_id] }) {
            returning {
                ...ItemFields
                pallet {
                    pallet_id
                    type
                    pallet_number
                    pallet_name
                    is_pool
                    warehouse_id
                    warehouse_location
                    warehouse_status
                }
            }
        }
    }
`;
