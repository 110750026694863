export const sidebarCollapsedWidth = 50;
export const sidebarWidth = 247;

export const ADMIN_PATH_RESETS = [
    {
        pathRx: /^\/import/,
        redirect: '/import',
    },
    {
        pathRx: /^\/job/,
        redirect: '/import',
    },
];

export const ADMIN_PATH_LOGINS = [
    {
        pathRx: /^\/corporate/,
        redirect: '/account',
    },
];
