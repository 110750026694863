import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { css } from '@emotion/react';
import { Card, Select, Grid, CardActionArea, Button, Menu, MenuItem, IconButton } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { MoreVert } from '@material-ui/icons';
import { useLazyQuery, useMutation } from '@apollo/client';

import { colors } from '@/styles';
import { UserContext } from '@/components/App';
import { QUERY_BY_ROUTE as GET_RATING } from '@/graphql/queries/ratings';
import { SET_DRIVER_BY_ID as SET_DRIVER } from './graphql/mutations';
import { SET_FINAL_RETURN_DRIVER_BY_ID as SET_FINAL_RETURN_DRIVER } from './graphql/mutations';
import routeHelpers from '@/utilities/routeHelpers';
import CarrierReviewModal from '@/components/CarrierReviewModal';

import SendToPlanningModal from './modals/SendToPlanningModal';
import { useMenuOptions } from './hooks';
import { H2, H4, Body1, Body2, Subtitle1, Status, local, PrimaryButton, FinalReturnBadge } from './blocks';
import { OWNERSHIP_TYPES, getRouteOwnership } from './utils';
import add from 'date-fns/add';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';

import { useClientUser } from '@/hooks';

const DriverSelect = ({ value, options, callbacks }) => {
    return (
        <Select
            fullWidth
            variant="outlined"
            value={value || {}}
            displayEmpty={true}
            onChange={callbacks.onChange}
            renderValue={(selected) => (selected ? selected.username : 'Pick a driver')}
            css={css`
                & .MuiOutlinedInput-input {
                    padding: 12px;
                }
            `}
        >
            {options.map((driver) => (
                <MenuItem key={driver.teammate_id} value={driver.teammate_id}>
                    <Body2>{driver.username}</Body2>
                </MenuItem>
            ))}
        </Select>
    );
};

export default function RouteCard({ route, orders = [], routeType, callbacks = {}, drivers = [] }) {
    const { sendBackToPlan, generateRouteCSV, generateRoutePDF } = callbacks;
    const navigate = useNavigate();
    const [showPlanningModal, setShowPlanningModal] = useState(false);
    const [carrierReviewOpen, setCarrierReviewOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useContext(UserContext);
    const [userRole, setUserRole] = useState(null);

    const [willFinishReturnsNextDay, setWillFinishReturnsNextDay] = useState(null);

    const { partner_carriers } = useClientUser();

    const partneredCarrier = useMemo(() => {
        return partner_carriers ? partner_carriers.find((_carrier) => _carrier.client_id === route?.carrier_id) : null;
    }, [route, partner_carriers]);

    const partnerDrivers = useMemo(() => {
        if (!partner_carriers?.length || !partneredCarrier) return [];
        //iterate through partnereredCarrier and get all users who usertype as driver
        const partnerDrivers = [];
        partneredCarrier?.user?.teammatesByLeaderId?.forEach((user) => {
            if (user?.roles?.driver) {
                partnerDrivers.push(user);
            }
        });
        return partnerDrivers;
    }, [partneredCarrier]);

    const isPartnerRoute = useMemo(() => {
        if (
            partner_carriers &&
            partner_carriers.length &&
            partner_carriers.some((_carrier) => _carrier.client_id === route?.carrier_id)
        )
            return true;
        return false;
    }, [route, partner_carriers]);

    const [init, { data: ratings }] = useLazyQuery(GET_RATING, {
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { orders: orders.map((order) => order.order_id) },
    });
    const [setDriver] = useMutation(SET_DRIVER);
    const [setFinalReturnDriver] = useMutation(SET_FINAL_RETURN_DRIVER);
    useEffect(() => {
        init();
        localUserRole();
    }, [user]);

    const hasPartneredCarrier = (route) => {
        return route.partnered_carrier ? true : false;
    };

    const localUserRole = () => {
        if (user) {
            if (user.roles?.['ONWARD_ADMIN']) {
                setUserRole('admin');
            } else if (user.roles?.CARRIER) {
                setUserRole('carrier');
            } else if (user.roles?.SHIPPER) {
                setUserRole('shipper');
            }
        }
    };

    const subtitleMessage = useMemo(() => {
        switch (routeType) {
            case 'preTransit':
            case 'inTransit':
                return `Estimated Delivery: ${route.scheduled_delivery_formatted || 'TBD'}`;
            case 'completed':
                return `Completed: ${route.completed ? new Date(route.completed).toLocaleDateString() : 'TBD'}`;
        }
    }, [route, routeType]);

    const warehouseStatus = useMemo(() => {
        if (route?.stopsByRouteId?.length > 0) {
            const pickups = route?.stopsByRouteId.filter(
                (stop) => stop.type === 'PICKUP' && stop?.orders?.length > 0 && stop.arrival_time
            );
            if (pickups.some((stop) => stop.arrival_time > stop.del_window_end)) {
                return 'Late';
            }

            if (
                pickups.some(
                    (stop) => stop.arrival_time > stop.del_window_start && stop.arrival_time < stop.del_window_end
                )
            ) {
                return 'On Time';
            }

            if (pickups.some((stop) => stop.arrival_time < stop.del_window_start)) {
                return 'Early';
            }
        }

        return 'Pending';
    }, [route]);

    const routeStatus = useMemo(() => {
        if (route.status === 'complete') {
            return 'Complete';
        }

        if (!route.scheduled_delivery_formatted) {
            return 'Did Not Finish';
        }
        let datePadding = add(new Date(route.scheduled_delivery_formatted), { days: 1 }).toISOString();
        if (datePadding < new Date().toISOString()) {
            return 'Did Not Finish';
        }

        const arrivedStops = FTLStopHelpers.getStopSequence(route.stopsByRouteId).filter((stop) => stop.arrival_time);

        if (arrivedStops.length > 0) {
            const lastStop = arrivedStops[arrivedStops.length - 1];

            const [complete, arrived, start, end] = [
                'stop_completion_time',
                'arrival_time',
                'del_window_start',
                'del_window_end',
            ].map((field) => (lastStop[field] ? new Date(lastStop[field]) : null));

            if (complete) {
                if (complete > end) {
                    return 'Running Behind';
                }

                if (complete < end && complete > start) {
                    return 'On Time';
                }

                if (complete < start) {
                    return 'Ahead of Schedule';
                }
            }

            if (arrived > end) {
                return 'Running Behind';
            }

            if (arrived < end && arrived > start) {
                return 'On Time';
            }

            if (arrived < start) {
                return 'Ahead of Schedule';
            }
        }

        return 'Not Started';
    }, [route]);

    const firstReview = useMemo(() => {
        if (ratings?.results?.length > 0) {
            return ratings.results[0];
        }
    }, [ratings]);

    const driver = useMemo(() => {
        if (!isPartnerRoute) {
            return drivers.find((driver) => route.driver_id === driver.teammate_id);
        } else {
            return partnerDrivers.find((driver) => route.driver_id === driver.teammate_id);
        }
    }, [route, drivers]);

    const finalReturnDriver = useMemo(() => {
        if (!isPartnerRoute) {
            return drivers.find((driver) => route.driver_id_final_return === driver.teammate_id);
        } else {
            return partnerDrivers.find((driver) => route.driver_id_final_return === driver.teammate_id);
        }
    }, [route, drivers]);

    const handleChangeDriver = (e) => {
        setDriver({
            variables: {
                route_id: route.route_id,
                driver_id: e.target.value,
            },
        });
    };

    const handleChangeFinalReturnDriver = (e) => {
        setFinalReturnDriver({
            variables: {
                route_id: route.route_id,
                driver_id_final_return: e.target.value,
            },
        });
    };

    const openMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(null);
    };

    const options = useMenuOptions(route, user, {
        sendBackToPlanning:
            sendBackToPlan &&
            (() => {
                setShowPlanningModal(true);
            }),
        generateRouteCSV:
            generateRouteCSV &&
            (() => {
                generateRouteCSV(route);
            }),
        generateRoutePDF:
            generateRoutePDF &&
            (() => {
                generateRoutePDF(route);
            }),
    });

    const carrierReviewFlag = route?.carrier_id && user.user_id !== route?.carrier_id;

    const { label, fragment, ownershipType } = useMemo(() => {
        const type = getRouteOwnership({ user, route, orders });
        let label;
        let color;

        switch (type) {
            case OWNERSHIP_TYPES.INTERNAL:
                label = 'Internal';
                color = local.gold;
                break;
            case OWNERSHIP_TYPES.ONWARD:
                label = 'Onward';
                color = colors.greens.primary;
                break;
            case OWNERSHIP_TYPES.COMINGLED:
                label = 'Comingled';
                color = colors.greens.primary;
                break;
        }

        return {
            label,
            fragment: css`
                color: ${color};
            `,
            type,
        };
    }, [user, route, orders]);

    return (
        <>
            <Card
                onClick={() =>
                    navigate(`/${userRole ? userRole : 'shipper'}/routedetails/${route.route_id}`, {
                        state: { source: 'Tracking' },
                    })
                }
                css={css`
                    margin: 16px 0;
                    background: ${colors.white.primary};
                `}
            >
                <CardActionArea
                    css={css`
                        padding: 16px;
                    `}
                >
                    <Grid container direction="column">
                        <Grid container>
                            <Grid
                                item
                                md={8}
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <H2>{route.route_alias || `Route ${route.route_number}`}</H2>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                `}
                            >
                                <H4
                                    css={css`
                                        ${fragment}
                                    `}
                                >
                                    {label}
                                </H4>
                                <IconButton
                                    disabled={options.length === 0}
                                    onClick={openMenu}
                                    css={css`
                                        margin-left: 4px;
                                        padding: 4px;
                                    `}
                                >
                                    <MoreVert />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu}>
                                    {options.map((option, i) => (
                                        <MenuItem
                                            key={i}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                option.callback();
                                            }}
                                        >
                                            {option.display}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {user.roles?.['ONWARD_ADMIN'] && (
                                <Grid>
                                    <Grid item xs={12}>
                                        <Body1>Shipper: {route?.route_shipper?.business_name}</Body1>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Body1>Carrier: {route?.route_carrier?.business_name}</Body1>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Body1>Stops: {routeHelpers.getNumberOfStops(route)}</Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Body1>Start: {routeHelpers.getRouteStartAddress(route)}</Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Body1>Final Stop: {routeHelpers.getRouteEndAddress(route)}</Body1>
                            </Grid>
                            {route.status === 'inProgress' && (
                                <Grid>
                                    <Grid item xs={12}>
                                        <Body1>
                                            {`${routeHelpers.getCompleteStopCount(
                                                route
                                            )} of ${routeHelpers.getNumberOfStops(route)} stops complete.`}
                                        </Body1>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        css={css`
                                            display: flex;
                                            margin-top: 3px;
                                        `}
                                    >
                                        <Body1>Arrived at Warehouse: </Body1>
                                        <Status status={warehouseStatus}>{warehouseStatus}</Status>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        css={css`
                                            display: flex;
                                            margin-top: 3px;
                                        `}
                                    >
                                        <Body1>Route Status: </Body1>
                                        <Status status={routeStatus}>{routeStatus}</Status>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Subtitle1>{subtitleMessage}</Subtitle1>
                            </Grid>

                            {(route.finish_returns_next_day || route.status === 'complete_final_return') && (
                                <>
                                    <FinalReturnBadge>Final Return</FinalReturnBadge>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                        style={{ marginBottom: '4px' }}
                                    >
                                        <Grid item xs={4}>
                                            <Body1>Return Driver: </Body1>
                                        </Grid>
                                        <Grid item xs={8} onClick={(e) => e.stopPropagation()}>
                                            <DriverSelect
                                                options={isPartnerRoute ? partnerDrivers : drivers}
                                                callbacks={{ onChange: handleChangeFinalReturnDriver }}
                                                value={finalReturnDriver}
                                                disableLabel={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {routeType === 'inTransit' && (
                                <Grid item xs={12}>
                                    <Subtitle1>
                                        {route.finish_returns_next_day ? 'Returns: Tomorrow' : 'Returns: Today'}
                                    </Subtitle1>
                                </Grid>
                            )}
                            {(routeType === 'inTransit' || routeType === 'completed') &&
                                (route.oms || isPartnerRoute) && (
                                    <Grid item xs={12}>
                                        <Subtitle1>Driver: {route?.teammateByDriverId?.username}</Subtitle1>
                                    </Grid>
                                )}

                            {!!drivers.length && routeType === 'preTransit' && route.oms && (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Grid item xs={4}>
                                        <Body1>Driver: </Body1>
                                    </Grid>
                                    <Grid item xs={8} onClick={(e) => e.stopPropagation()}>
                                        <DriverSelect
                                            options={drivers}
                                            callbacks={{ onChange: handleChangeDriver }}
                                            value={driver}
                                            disableLabel={true}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {!!partnerDrivers.length && routeType === 'preTransit' && isPartnerRoute && (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Grid item xs={4}>
                                        <Body1>Driver: </Body1>
                                    </Grid>
                                    <Grid item xs={8} onClick={(e) => e.stopPropagation()}>
                                        <DriverSelect
                                            options={partnerDrivers}
                                            callbacks={{ onChange: handleChangeDriver }}
                                            value={driver}
                                            disableLabel={true}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {route.status === 'complete' &&
                                user.roles?.SHIPPER &&
                                !user.roles?.['ONWARD_ADMIN'] &&
                                carrierReviewFlag &&
                                (firstReview ? (
                                    <div
                                        css={css`
                                            display: flex;
                                        `}
                                    >
                                        <Rating
                                            value={firstReview.rating}
                                            readOnly
                                            style={{
                                                marginTop: 6,
                                            }}
                                        />
                                        <Button
                                            css={css`
                                                color: ${colors.greens.primary};
                                                width: 100%;
                                                margin-left: 15px;
                                            `}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setCarrierReviewOpen(true);
                                            }}
                                        >
                                            Edit Review
                                        </Button>
                                    </div>
                                ) : (
                                    <PrimaryButton
                                        variant="contained"
                                        css={css`
                                            flex: 1;
                                            margin-top: 10px;
                                        `}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCarrierReviewOpen(true);
                                        }}
                                    >
                                        Leave Carrier Review
                                    </PrimaryButton>
                                ))}
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
            <SendToPlanningModal
                text={`Are you sure you want to send route ${route.route_number} back to planning?`}
                open={showPlanningModal}
                callbacks={{
                    onClose: () => setShowPlanningModal(false),
                    onConfirm: (e) => {
                        e.stopPropagation();
                        setShowPlanningModal(false);
                        sendBackToPlan({
                            variables: {
                                route_id: route.route_id,
                            },
                        });
                    },
                }}
            />
            <CarrierReviewModal
                isOpen={carrierReviewOpen}
                callbacks={{
                    onClose: () => setCarrierReviewOpen(false),
                }}
                reviewObject={firstReview}
                routeId={route.route_id}
                revieweeId={route.carrier_id ? route.carrier_id : route.shipper_id}
                revieweeType={route.carrier_id ? 'CARRIER' : 'SHIPPER'}
                driverId={route?.driver_id}
            />
        </>
    );
}
