import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { post } from '@/utilities/onwardClient';
import { RETRY_INTEGRATION } from '@/constants/apiRoutes';

import { useColumns } from './columns';
import { LIST_FAILED_INTEGRATIONS } from './graphql';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(false);
    const [retryInflight, setRetryInflight] = useState(false);
    const where = {};

    const [getIntegrations, { data, loading: initInflight, fetchMore }] = useLazyQuery(LIST_FAILED_INTEGRATIONS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const integrations = useMemo(() => {
        return data?.results || [];
    }, [data]);

    useEffect(() => {
        getIntegrations({ variables: { where } });
    }, []);

    const cursor = useMemo(() => {
        if (integrations.length === 0) {
            return null;
        }

        return integrations[integrations.length - 1].created_at;
    }, [integrations]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(data.results.map((error) => [error.error_id, true]));
                const clone = [...data.results, ...fetchMoreResult.results.filter((error) => !prev[error.error_id])];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const callbacks = {
        retry: (error) => {
            setRetryInflight(true);

            post(RETRY_INTEGRATION, {
                transaction_id: error.integration.transaction_id,
            }).then(({ status }) => {
                setRetryInflight(false);
            });
        },
        gotoJob: (error) => {
            navigate(`/job/${error.integration.job_id}`);
        },
        getRowId: (error) => {
            return error.error_id;
        },
        loadMore,
    };

    const loading = {
        init: initInflight,
        retry: retryInflight,
    };

    const COLUMNS = useColumns({ loading, callbacks });

    return (
        <Context.Provider
            value={{
                state: {
                    hasMore,
                    integrations,
                    columns: COLUMNS,
                },
                loading,
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
