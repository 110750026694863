import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import { DELETE_SERVICE_LEVEL, UPSERT_SERVICE_LEVEL } from './graphql';
import { captureException } from '@sentry/react';
import { TabCard } from '../blocks';
import Snackbar from '@/components/Snackbar';
import { Grid } from '@material-ui/core';
import ServiceLevel from './ServiceLevel';
import { useMutation } from '@apollo/client';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Body1 } from '../blocks';

const ServiceLevels = () => {
    const { tags, service_levels, user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);

    const [upsertServiceLevel, { loading: upsertLoading }] = useMutation(UPSERT_SERVICE_LEVEL, {
        refetchQueries: [QUERY_BY_ID],
        awaitRefetchQueries: true,
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error created service level. Please try again.',
            });
        },
    });

    const [deleteServiceLevel, { loading: deleteLoading }] = useMutation(DELETE_SERVICE_LEVEL, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error deleting service level. Please try again.',
            });
        },
        update: (cache, { data: { delete_service_levels_by_pk } }) => {
            cache.evict(cache.identify(delete_service_levels_by_pk));
        },
    });

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const handleModalDelete = () => {
        deleteServiceLevel({ variables: { id: deleteModal } })
        setDeleteModal(false);
    }

    const loading = upsertLoading || deleteLoading;

    return (
        <>
        <NavResponsiveModal open={!!deleteModal} onClose={closeDeleteModal}>
            <ModalHeader title="Delete Service Type?" onClose={closeDeleteModal} />
            <ModalContent>
                <Body1>Are you sure you want to delete this service type?</Body1>
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={closeDeleteModal}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleModalDelete}>Delete</PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
        <TabCard
            css={css`
                padding: 2rem;
            `}
        >
            <Snackbar open={!!notification.message} handleClose={() => setNotification({})} {...notification} />
            <Grid
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid item>
                    <PrimaryButton
                        disabled={loading}
                        onClick={() =>
                            upsertServiceLevel({
                                variables: {
                                    service_level: {
                                        client_id: user_id,
                                        service_level: `Service Type ${service_levels.length + 1}`,
                                        onward_tags: [],
                                    },
                                },
                            })
                        }
                    >
                        Add Service Type
                    </PrimaryButton>
                </Grid>
                {service_levels.map((serviceLevel) => (
                    <Grid
                        key={serviceLevel.service_level_id}
                        item
                        css={css`
                            margin-top: 2rem;
                        `}
                    >
                        <ServiceLevel
                            serviceLevel={serviceLevel}
                            tags={tags}
                            loading={loading}
                            callbacks={{
                                onSave: ({ __typename, tags, ...updated }) =>
                                    upsertServiceLevel({
                                        variables: {
                                            service_level: updated,
                                            tags: (tags || []).map((t) => ({
                                                ...t,
                                                service_level_id: serviceLevel.service_level_id,
                                            })),
                                            tag_removals: (serviceLevel.tags || [])
                                                .filter(
                                                    (mapping) =>
                                                        !(tags || []).map((t) => t.tag_id).includes(mapping.tag_id)
                                                )
                                                .map((mapping) => mapping.mapping_id),
                                        },
                                    }),
                                onDelete: (id) => setDeleteModal(id),
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </TabCard>
        </>
    );
};

export default ServiceLevels;
