import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import FooterComponent from '../Footer';
import { Card } from 'react-bootstrap';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import { PrimaryButton } from '@/styles/blocks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { captureException } from '@sentry/react';
import { postUnauth } from '@/utilities/onwardClient';
import { CREATE_CORPORATE_ADMIN } from '@/constants/apiRoutes';

const InputField = styled((props) => <TextField variant="outlined" size="small" {...props} />)`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const PW_ERROR_MESSAGES = {
    containsSpecial: 'Password must contain at least one special character',
    containsUpper: 'Password must contain at least one upper case letter',
    containsLower: 'Password must contain at least one lower case letter',
    containsNumber: 'Password must contain at least one number',
    withinLength: 'Password must be at least 8 characters long and no longer than 32 characters',
};

const CorporateSignup = () => {
    const [signupInfo, setSignupInfo] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const passwordValidation = useMemo(() => {
        const { password } = signupInfo;
        return {
            containsSpecial: /(?=.*?[?!@#$%^&*])/.test(password),
            containsUpper: /(?=.*?[A-Z])/.test(password),
            containsLower: /(?=.*?[a-z])/.test(password),
            containsNumber: /(?=.*?[0-9])/.test(password),
            withinLength: password.length >= 8 && password.length < 32,
        };
    }, [signupInfo]);

    const onSubmit = () => {
        setSubmitted(true);

        if (
            Object.values(signupInfo).some((x) => !x) ||
            Object.values(passwordValidation).some((x) => !x) ||
            signupInfo.password !== signupInfo.confirmPassword
        ) {
            setError('Please fix the following errors');
            return;
        }

        setError('');
        setLoading(true);
        postUnauth(CREATE_CORPORATE_ADMIN, {
            ...signupInfo,
        })
            .then(() => {
                setSuccess(true);
            })
            .catch((error) => {
                setError(error?.response?.data?.error || `We're sorry, something went wrong`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const { username, email, password, confirmPassword } = signupInfo;

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                height: 100%;
            `}
        >
            <div
                css={css`
                    margin-top: 3rem;
                    display: flex;
                    justify-content: center;
                `}
            >
                <Card
                    css={css`
                        padding: 2rem;
                        width: 480px;
                    `}
                >
                    {success ? (
                        <>
                            <Alert
                                severity="success"
                                css={css`
                                    margin: 0.5rem 0rem;
                                `}
                            >
                                <AlertTitle>Congratulations!</AlertTitle>
                                Your corporate admin account has been created - click <a href="/signin">here</a> to sign
                                in
                            </Alert>
                        </>
                    ) : (
                        <>
                            <span
                                css={css`
                                    font-weight: 800;
                                    font-size: 20px;
                                    text-align: center;
                                `}
                            >
                                Create your Onward Corporate Admin Account
                            </span>

                            {error && (
                                <Alert severity="error">
                                    <AlertTitle>{error}</AlertTitle>

                                    {Object.entries(passwordValidation).map(([key, valid]) =>
                                        valid ? null : <li>{PW_ERROR_MESSAGES[key]}</li>
                                    )}
                                </Alert>
                            )}

                            <InputField
                                label="Full Name"
                                required={true}
                                value={username}
                                onChange={(e) => setSignupInfo((prev) => ({ ...prev, username: e.target.value }))}
                                error={submitted && !username}
                                disabled={loading}
                            />
                            <InputField
                                label="Email"
                                required={true}
                                value={email}
                                onChange={(e) => setSignupInfo((prev) => ({ ...prev, email: e.target.value }))}
                                error={submitted && !email}
                                disabled={loading}
                            />
                            <InputField
                                label="Password"
                                required={true}
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setSignupInfo((prev) => ({ ...prev, password: e.target.value }))}
                                error={submitted && !Object.values(passwordValidation).every((x) => x)}
                                disabled={loading}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                                                {showPassword ? (
                                                    <VisibilityOff color="primary" />
                                                ) : (
                                                    <Visibility color="primary" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <InputField
                                label="Confirm Password"
                                required={true}
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) =>
                                    setSignupInfo((prev) => ({ ...prev, confirmPassword: e.target.value }))
                                }
                                error={submitted && (!confirmPassword || password !== confirmPassword)}
                                disabled={loading}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                                                {showPassword ? (
                                                    <VisibilityOff color="primary" />
                                                ) : (
                                                    <Visibility color="primary" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <PrimaryButton
                                css={css`
                                    margin-top: 0.5rem;
                                `}
                                onClick={onSubmit}
                                disabled={loading}
                            >
                                Sign Up
                            </PrimaryButton>
                        </>
                    )}
                </Card>
            </div>
            <FooterComponent />
        </div>
    );
};

export default CorporateSignup;
