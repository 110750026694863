import React, { useContext } from 'react';
import ShipperAccount from './ShipperAccount';
import CarrierAccount from './CarrierAccount';
import TeammateAccount from './TeammateAccount';
import CorporateAccount from './CorporateAccount';
import { useClientUser } from '@/hooks';

const Account = () => {
    const { roles } = useClientUser();

    if (roles?.SHIPPER) {
        return <ShipperAccount />;
    } else if (roles?.CARRIER) {
        return <CarrierAccount />;
    } else if (roles?.TEAMMATE) {
        return <TeammateAccount />;
    } else if (roles?.['ADMIN'] || roles?.['ONWARD_ADMIN']) {
        return <CorporateAccount />;
    }
};

export default Account;
