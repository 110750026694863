import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { genAttributes } from '@onward-delivery/core';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { useLazyQuery } from '@apollo/client';

import { Body1, Body2, Header1, Header2, Hr } from './blocks';

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const PricingInfo = ({ order }) => {
    const { city } = genAttributes(order);
    const { city: pu_city } = genAttributes(order, true);

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);
    useEffect(() => {
        if (order) {
            fetchOverrides({
                variables: {
                    shipper_ids: [order.shipper_id],
                    carrier_ids: [],
                    client_ids: [],
                    partner_client_ids: [],
                },
            });
        }
    }, [order]);

    const [type, breakdown] = useMemo(() => {
        if (!order || !resp) {
            return [null, null];
        }

        const { shipper, internal, shipper_defaults } = resp;
        let overrides = shipper;
        let defaults = shipper_defaults;
        let breakdown = 'shipperBreakdown';

        if (order.oms) {
            overrides = internal;
            defaults = [{}];
            breakdown = 'internalBreakdown';
        }

        return [overrides.length > 0 ? overrides[0]?.algo_type : 'DEFAULT', breakdown];
    }, [order, resp]);

    const accessorials = useAccessorials(type);
    const accessorialsMap = useMemo(() => {
        return Object.fromEntries(accessorials.map((accessorial) => [accessorial.type, accessorial]));
    }, [accessorials]);

    return (
        <Grid container>
            <Grid
                item
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Header1>
                    {order?.[pu_city]} - {order?.[city]}
                </Header1>
            </Grid>

            {FIXED_CHARGES.map(({ display, key }) => {
                return order?.price_breakdown?.[breakdown]?.[key] > 0 ? (
                    <Grid
                        item
                        container
                        key={key}
                        css={css`
                            justify-content: space-between;
                        `}
                    >
                        <Grid item>
                            <Body1>{display}</Body1>
                        </Grid>
                        <Grid
                            item
                            css={css`
                                color: #2b2b2b;
                            `}
                        >
                            <Body2>{dollarFormatter.format(order?.price_breakdown?.[breakdown]?.[key])}</Body2>
                        </Grid>
                    </Grid>
                ) : null;
            })}

            <Hr />

            {(order?.price_breakdown?.[breakdown]?.accessorials || []).map(({ type, quantity, rate }) => {
                const meta = accessorialsMap[type];

                return (
                    <Grid
                        item
                        container
                        key={type}
                        css={css`
                            justify-content: space-between;
                        `}
                    >
                        <Grid item>
                            <Body1>{meta.label}</Body1>
                        </Grid>
                        <Grid item>
                            <Body1>{dollarFormatter.format(quantity * rate)}</Body1>
                        </Grid>
                    </Grid>
                );
            })}

            <Hr />

            <Grid
                item
                container
                key={type}
                css={css`
                    justify-content: space-between;
                `}
            >
                <Grid item>
                    <Body1>Total</Body1>
                </Grid>
                <Grid item>
                    <Header2
                        css={css`
                            color: #2b2b2b;
                        `}
                    >
                        {dollarFormatter.format(order?.price_breakdown?.[breakdown]?.total_charge)}
                    </Header2>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PricingInfo;
