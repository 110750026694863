import { gql } from '@apollo/client';

export const TEAMMATE_FIELDS = gql`
    fragment TeammateFields on teammates {
        auth_user_roles
        circles
        created_at
        email
        hourly_rate
        key
        last_modified_at
        legacy_team_leader_uid
        legacy_teammate_id
        phone
        profile_image
        roles
        team_leader_id
        team_leader_type
        username
        teammate_id
        teammate_type
    }
`;

export const FULL_QUERY = gql`
    ${TEAMMATE_FIELDS}
    query GetTeammates {
        teammates(limit: 100) {
            ...TeammateFields
        }
    }
`;

export const QUERY_BY_LEADER = gql`
    ${TEAMMATE_FIELDS}
    query teammates($leader_id: uuid) {
        results: teammates(where: { team_leader_id: { _eq: $leader_id } }) {
            ...TeammateFields
        }
    }
`;
