import React, { useContext, useEffect, useMemo } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { css } from '@emotion/react';
import { compose } from 'recompose';
import { addDays } from 'date-fns';
import { Document, Page } from 'react-pdf';

import { colors } from '@/styles';
import { useClientUser } from '@/hooks';
import { PDF_IMPORT_V1 } from '@/constants/featureFlags';
import { PageContent, PageTitle, PrimaryButton } from '@/styles/blocks';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '@/components/Breadcrumbs';

import CSVUploadModal from './modals/CSVUploadModal';
import CSVHeadersModal from './modals/CSVHeadersModal';
import PickupInfoModal from './modals/PickupInfoModal';
import SaveTemplateModal from './modals/SaveTemplateModal';
import EditOrderModal from './modals/EditOrderModal';
import PdfUploadModal from './modals/PDFUploadModal';

import Loader from './Loader';
import { Context, ContextProvider } from './store';
import { Body1, H1 } from './blocks';
import { SHIPMENT_FORM_TYPES, SHIPMENT_FORM_STATE, EDIT_ORDER_TABS } from './constants';
import Snackbar from '../Snackbar';
import ReviewOrders from './Review';
import Footer from './Footer';
import PdfPreview from './PdfPreview';
import JobsTable from './JobsTable';
import MultiPickupModal from './modals/MultiPickupModal';
import { genAttributes } from '@onward-delivery/core';
import { getEditMultiPickupRequiredFields } from './utilities/requiredFields';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Alert, AlertTitle } from '@material-ui/lab';
import { UserContext } from '../App';

const ShipmentForm = () => {
    const { client_id, circles } = useClientUser();
    const { callbacks, state, errors } = useContext(Context);
    const { admin_clients } = useContext(UserContext);
    const START_CUTOFF = addDays(new Date(), -14);

    const tabs = useMemo(() => {
        const defaultTabs = [
            ...(state.pickupInfo?.is_middle_mile_origin ? [EDIT_ORDER_TABS.ORIGIN] : []),
            ...(state.groupedPickup ? [EDIT_ORDER_TABS.PICKUP] : []),
            EDIT_ORDER_TABS.CUSTOMER,
            EDIT_ORDER_TABS.ORDER_DETAILS,
            EDIT_ORDER_TABS.ITEMS,
            ...(state.pickupInfo?.job_type !== 'WILL_CALL' ? [EDIT_ORDER_TABS.HAUL_AWAY] : []),
            EDIT_ORDER_TABS.NOTES,
            EDIT_ORDER_TABS.WAREHOUSE,
        ];

        return defaultTabs;
    }, [state.groupedPickup, state.pickupInfo]);

    useEffect(() => {
        if (client_id) {
            callbacks.fetchJobs({ variables: { client_ids: [client_id], start_cutoff: START_CUTOFF } });
        } else if (admin_clients?.length > 0) {
            callbacks.fetchJobs({
                variables: { client_ids: admin_clients.map((client) => client.client_id), start_cutoff: START_CUTOFF },
            });
        }
    }, [client_id]);

    let content = null;
    switch (state.formState) {
        case SHIPMENT_FORM_STATE.CREATE:
            content = (
                <PageContent
                    css={css`
                        padding-bottom: 120px;
                    `}
                >
                    <PageTitle
                        css={css`
                            padding-top: 40px;
                        `}
                    >
                        Create Orders
                    </PageTitle>

                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <H1>Create Manual Order or Upload File</H1>
                    </Grid>

                    <Grid
                        item
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <Body1>
                            Input shipment order manually or pre-populate a new shipment order with a CSV file. CSV must
                            match our template.
                        </Body1>
                    </Grid>

                    {!client_id ? (
                        <Alert
                            severity="warning"
                            css={css`
                                margin-bottom: 24px;
                            `}
                        >
                            <AlertTitle>
                                Select an account to create orders or upload CSV
                                {circles?.[PDF_IMPORT_V1] ? '/PDF' : ''} files
                            </AlertTitle>
                        </Alert>
                    ) : null}

                    <Grid
                        container
                        direction="row"
                        css={css`
                            align-items: center;
                            justify-content: flex-start;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                margin-right: 64px;
                            `}
                        >
                            <PrimaryButton
                                disabled={!client_id}
                                onClick={() => {
                                    callbacks.selectFormType(SHIPMENT_FORM_TYPES.MANUAL);
                                }}
                                css={css`
                                    padding: 6px 28px;
                                `}
                            >
                                Add Order
                            </PrimaryButton>
                        </Grid>
                        <Grid
                            item
                            css={css`
                                margin-right: 64px;
                            `}
                        >
                            <PrimaryButton
                                disabled={!client_id}
                                onClick={() => {
                                    callbacks.selectFormType(SHIPMENT_FORM_TYPES.CSV);
                                }}
                                css={css`
                                    padding: 6px 28px;
                                `}
                            >
                                Upload CSV
                            </PrimaryButton>
                        </Grid>
                        {circles?.[PDF_IMPORT_V1] ? (
                            <Grid item>
                                <PrimaryButton
                                    disabled={!client_id}
                                    onClick={() => {
                                        callbacks.selectFormType(SHIPMENT_FORM_TYPES.PDF);
                                    }}
                                    css={css`
                                        padding: 6px 28px;
                                    `}
                                >
                                    Upload PDF
                                </PrimaryButton>
                            </Grid>
                        ) : null}
                    </Grid>

                    <H1
                        css={css`
                            margin-top: 40px;
                            margin-bottom: 20px;
                        `}
                    >
                        Processed Orders
                    </H1>

                    <Grid container direction="row">
                        <JobsTable jobs={state.jobs?.result || []} />
                    </Grid>

                    <PickupInfoModal callbacks={{ onClose: callbacks.reset }} />
                </PageContent>
            );
            break;
        case SHIPMENT_FORM_STATE.REVIEW:
            content = (
                <Grid
                    direction="row"
                    container
                    css={css`
                        height: 100%;
                    `}
                >
                    <Grid
                        direction="column"
                        css={css`
                            margin: 0 80px;
                            margin-top: 40px;
                            flex: 1;
                            flex-basis: 0;
                            padding-bottom: 120px;
                        `}
                    >
                        <Grid direction="row" container>
                            <PageTitle>Review Orders</PageTitle>
                        </Grid>

                        <Grid direction="row" container>
                            <Grid
                                direction="column"
                                css={css`
                                    flex-basis: 0;
                                    flex: 2;
                                `}
                            >
                                <ReviewOrders />
                            </Grid>
                        </Grid>

                        <PickupInfoModal callbacks={{ onClose: callbacks.revert }} />
                    </Grid>
                    {state.formType === SHIPMENT_FORM_TYPES.PDF ? (
                        <PdfPreview pdf={state.pdf.base64} page={state.pdf.page + 1} callbacks={callbacks} />
                    ) : null}
                </Grid>
            );
            break;
    }

    if (state.isProcessingPdf) {
        content = <Loader />;
    }

    return (
        <>
            {state.formState === SHIPMENT_FORM_STATE.REVIEW ? (
                <Grid
                    container
                    css={css`
                        align-items: center;
                        background: white;
                        padding: 12px 30px;
                        box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
                        border-bottom: 1px solid #ececec;
                    `}
                >
                    <OnwardBreadcrumbActive>1. Create</OnwardBreadcrumbActive>
                    <OnwardBreadcrumbSpacer />
                    <OnwardBreadcrumbInactive disabled>2. Review</OnwardBreadcrumbInactive>
                </Grid>
            ) : null}

            {content}

            <MultiPickupModal />
            <SaveTemplateModal />
            <CSVUploadModal />
            <CSVHeadersModal />
            <PdfUploadModal />

            {/* Notifications */}
            <Snackbar
                severity="success"
                open={!!errors.successMsg}
                message={errors.successMsg}
                handleClose={() => callbacks.setSuccessMsg('')}
            />
            <Snackbar
                severity="warning"
                open={!!errors.warningMsg}
                message={errors.warningMsg}
                handleClose={() => callbacks.setWarningMsg('')}
            />
            <Snackbar
                severity="error"
                open={!!errors.errorMsg}
                message={errors.errorMsg}
                handleClose={() => callbacks.setErrorMsg('')}
            />

            <EditOrderModal
                order={state.editing?.order}
                open={!!state.editing}
                initTab={state.editing?.tab}
                formType={state?.formType}
                opt={{
                    disableGeocoding: true,
                    disableDistancingAndPricing: true,
                    customerAvailabilityToggle: true,
                    readOnly: !state.editing?.order.oms && !!state.editing?.order?.carrier_invoice_id,
                }}
                tabs={tabs}
                callbacks={{
                    onSave: ({ errors, order, items, tags, documents }) => {
                        callbacks.editOrder(errors, { ...order, itemsByOrderId: items, tags, documents });
                        callbacks.setEditing(null);
                    },
                    onClose: () => {
                        callbacks.setEditing(null);
                    },
                    onError: (e) => {
                        callbacks.setErrorMsg(e);
                    },
                }}
            />

            <EditOrderModal
                order={state.editingPickup?.orders?.[0]}
                open={!!state.editingPickup}
                initTab={EDIT_ORDER_TABS.PICKUP}
                tabs={[EDIT_ORDER_TABS.PICKUP]}
                formType={state?.formType}
                opt={{
                    title: 'Pickup / Return Warehouse Information',
                    disableGeocoding: true,
                    disableDistancingAndPricing: true,
                    customerAvailabilityToggle: true,
                    enablePickupDateEditing: true,
                    readOnly:
                        !state.editingPickup?.orders?.[0].oms && !!state.editingPickup?.orders?.[0].carrier_invoice_id,
                }}
                requiredAttributes={getEditMultiPickupRequiredFields}
                callbacks={{
                    onSave: ({ order: modified }) => {
                        const modifiedPickupFields = genAttributes(modified, true);
                        const modifiedGenericFields = [
                            'delivery_date',
                            'first_available_date',
                            'pickup_date',
                            'pickup_email',
                            'pickup_name',
                            'pickup_phone',
                            'pickup_window_end',
                        ];

                        callbacks.setOrders((prev) => ({
                            ...prev,
                            ...Object.fromEntries(
                                (state.editingPickup?.orders || []).map((order) => {
                                    const orderPickupFields = genAttributes(order, true);
                                    return [
                                        order.key,
                                        {
                                            ...order,
                                            ...Object.fromEntries(
                                                Object.keys(modifiedPickupFields).map((field) => [
                                                    orderPickupFields[field],
                                                    modified[modifiedPickupFields[field]],
                                                ])
                                            ),
                                            ...Object.fromEntries(
                                                modifiedGenericFields.map((field) => [field, modified[field]])
                                            ),
                                        },
                                    ];
                                })
                            ),
                        }));
                        callbacks.setEditingPickup(null);
                    },
                    onClose: () => {
                        callbacks.setEditingPickup(null);
                    },
                    onError: (e) => {
                        callbacks.setErrorMsg(e);
                    },
                }}
            />

            <Footer />
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(ShipmentForm);
