import { useClientUser } from '@/hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../App';

const withFeatureFlag = (featureFlag) => (Component) => (props) => {
    const navigate = useNavigate();

    const { circles, roles } = useClientUser();
    const [hasFeature, setHasFeature] = useState(false);

    useEffect(() => {
        if (roles?.['ONWARD_ADMIN']) {
            setHasFeature(true);
        } else if (Array.isArray(featureFlag) && featureFlag.some((ff) => circles?.[ff])) {
            setHasFeature(true);
        } else if (circles?.[featureFlag]) {
            setHasFeature(true);
        } else {
            navigate('/account');
        }
    }, [circles]);

    return hasFeature ? <Component {...props} /> : null;
};

export default withFeatureFlag;
