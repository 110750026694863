import { UserContext } from '@/components/App';
import { OnwardCardTab, OnwardCardTabContainer, OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import { PageTitle } from '@/styles/blocks';
import { useMutation, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UPDATE_USER } from './graphql';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from '@/styles';
import ProfileTab from './ProfileTab';
import PasswordTab from './PasswordTab';

const TABS = [
    { label: 'Profile', value: 0 },
    { label: 'Password', value: 1 },
];

const CorporateAccount = () => {
    const { user } = useContext(UserContext);
    const [tab, setTab] = useState(TABS[0].value);

    const [saveUser, { loading }] = useMutation(UPDATE_USER, {
        onError: (error) => {
            captureException(error);
        },
    });

    return (
        <div
            css={css`
                padding: 2rem 5rem;
            `}
        >
            <PageTitle>My Account</PageTitle>
            <OnwardCardTabContainer
                textColor="primary"
                indicatorColor="primary"
                value={tab}
                onChange={(_, tab) => setTab(tab)}
                css={css`
                    margin-top: 2rem;
                `}
            >
                {TABS.map((tabOption) => (
                    <OnwardCardTab key={`corp-profile-tab-${tabOption.label}`} {...tabOption} />
                ))}
            </OnwardCardTabContainer>
            <Grid
                container
                css={css`
                    background-color: white;
                    border: 1px solid ${colors.greys.border};
                    width: 100%;
                    padding: 1rem 2rem;
                    ${tab === 0 ? 'border-top-left-radius: 0;' : ''}
                `}
            >
                {tab === 0 && <ProfileTab user={user} callbacks={{ saveUser }} loading={loading} />}
                {tab === 1 && <PasswordTab user={user} callbacks={{ saveUser }} loading={loading} />}
            </Grid>
        </div>
    );
};

export default CorporateAccount;
