import { gql } from '@apollo/client';
import { CSV_MAPPINGS_FIELDS } from '@/graphql/queries/csv_mappings';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { JOB_FIELDS } from '@/graphql/queries/jobs';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';

export const QUERY_MAPPINGS_BY_USER = gql`
    ${CSV_MAPPINGS_FIELDS}

    query CsvMappingByUserIdAndType($user_id: uuid!, $freight_type: String!) {
        results: csv_mappings(where: { _and: { user_id: { _eq: $user_id }, freight_type: { _eq: $freight_type } } }) {
            ...CsvMappingsFields
        }
    }
`;

export const QUERY_CLIENTS_BY_USER = gql`
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}

    query ClientByUuid($client_id: uuid!) {
        result: clients(where: { client_id: { _eq: $client_id } }) {
            ...ClientFields
            locations {
                ...LocationFields
            }
        }
    }
`;

export const QUERY_CLIENTS_BY_USERS = gql`
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}

    query ClientsByUuid($client_ids: [uuid!]!) {
        results: clients(where: { client_id: { _in: $client_ids } }) {
            ...ClientFields
            locations {
                ...LocationFields
            }
        }
    }
`;

export const QUERY_JOBS_BY_CLIENT = gql`
    ${JOB_FIELDS}
    ${ORDER_FIELDS}

    query JobsByClientId($client_ids: [uuid!]!, $start_cutoff: timestamptz!) {
        result: jobs(where: { _and: [{ client_id: { _in: $client_ids } }, { created_at: { _gte: $start_cutoff } }] }) {
            ...JobFields

            orders {
                ...OrderFields
            }
        }
    }
`;

export const FETCH_LOGOS = gql`
    query PricingOverridesById($override_ids: [uuid!]!) {
        results: pricing_overrides(where: { pricing_override_id: { _in: $override_ids } }) {
            pricing_override_id

            partner_client {
                client_id
                business_name
                profile_img
            }

            assign {
                client_id
                business_name
                profile_img
            }
        }
    }
`;

export const QUERY_JOB_BY_ID = gql`
    ${JOB_FIELDS}
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}

    query JobsByClientId($job_id: uuid!) {
        result: jobs(where: { job_id: { _eq: $job_id } }) {
            ...JobFields

            orders(
                where: {
                    _or: [
                        {
                            _and: [
                                {
                                    order_status: {
                                        _in: ["pending_confirmation", "incomplete", "processing", "duplicate"]
                                    }
                                }
                                { source_form: { _neq: "APIv2" } }
                            ]
                        }
                        {
                            _and: [
                                { order_status: { _in: ["pending_confirmation", "incomplete", "processing"] } }
                                { source_form: { _eq: "APIv2" } }
                            ]
                        }
                    ]
                }
                order_by: { order_number: asc }
            ) {
                ...OrderFields

                itemsByOrderId {
                    ...ItemFields
                }

                returns @client {
                    ...ItemFields
                }

                items @client {
                    ...ItemFields
                }
            }
        }
    }
`;

export const QUERY_LOCATIONS_BY_CLIENT_ID = gql`
    ${LOCATION_FIELDS}

    query LocationsByClientId($client_id: uuid!) {
        locations(where: { client_id: { _eq: $client_id } }) {
            ...LocationFields
        }
    }
`;
