export const MM_INTEGRATION_META_FIELDS_GLOBAL = [
    {
        attr: 'tracking_number',
        label: 'Tracking #',
    },
    {
        attr: 'carrier_name',
        label: 'Carrier',
    },
];

export const MM_INTEGRATION_META_FIELDS = {
    ROCKET_SHIPPING: [
        {
            attr: 'loadsId',
            label: 'Load ID',
        },
    ],
};
