import React, { useEffect, useState } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { Row, Button, Container, Card, Form, Col, Modal } from 'react-bootstrap';
import { css } from '@emotion/react';
import { TextField, InputLabel, FormControl, Select } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { useMutation } from '@apollo/client';
import {
    UPDATE_TEAMMATE_PERSONAL_INFO,
    UPDATE_TEAMMATE_USER_PERSONAL_INFO,
    TEAMMATE_UPDATE_MAIN_SHIPPER_INFO,
} from '../mutations';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import FleetSizeInput from '../FleetSizeInput';

export default function ShipperTeammateProfile(props) {
    const { user = {}, isImposter } = props;

    const [personalName, setPersonalName] = useState('');
    const [personalEmail, setPersonalEmail] = useState('');
    const [personalPhone, setPersonalPhone] = useState('');

    const [supportEmail, setSupportEmail] = useState('');
    const [supportPhone, setSupportPhone] = useState('');
    const [primaryName, setPrimaryName] = useState('');
    const [secondaryName, setSecondaryName] = useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [primaryEmailOriginal, setPrimaryEmailOriginal] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [updateMessage, setUpdateMessage] = useState('');
    const [emailModal, setEmailModal] = useState(false);
    const [mainAccountEmailModal, setMainAccountEmailModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fleetSize, setFleetSize] = useState(null);

    const [UpdateTeammatePersonalInfo] = useMutation(UPDATE_TEAMMATE_PERSONAL_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [UpdateUserPersonalInfo] = useMutation(UPDATE_TEAMMATE_USER_PERSONAL_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const [UpdateMainShipperInfo] = useMutation(TEAMMATE_UPDATE_MAIN_SHIPPER_INFO, {
        onCompleted: (data) => {},
        onError: (error) => {
            console.log(error);
        },
    });

    const AdminEmailModal = (props) => {
        const { show, onHide, isImposter } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>

                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {isImposter ? 'Super admins cannot change account emails at this time.' : ''}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const MainAccountEmailModal = (props) => {
        const { show, onHide, isImposter } = props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header>Email Change</Modal.Header>

                <Modal.Body>
                    Please be aware that changing your primary contact email will also change the login email for this
                    account.
                    <div className="text-danger">
                        {isImposter
                            ? 'Super admins cannot change account emails at this time.'
                            : 'Only the main account holder may update this email. Changes to it will not be saved.'}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const displayMainAccountInfo = user?.teammate?.roles?.admin && user?.circles?.['saas-v1'];

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            try {
                await UpdateTeammatePersonalInfo({
                    variables: {
                        teammate_id: user?.user_id,
                        username: personalName,
                        email: isImposter ? user.email : personalEmail,
                        phone: validate(personalPhone) ? toE164(personalPhone) : personalPhone,
                    },
                });

                await UpdateUserPersonalInfo({
                    variables: {
                        user_id: user?.user_id,
                        username: personalName,
                        email: isImposter ? user.email : personalEmail,
                    },
                });

                if (!isImposter && personalEmail !== user?.email) {
                    const auth = getAuth();
                    const user = auth.currentUser;
                    await updateEmail(user, primaryEmail);
                }

                if (displayMainAccountInfo) {
                    let mainAccountUpdatePayload = {
                        username: primaryName,
                        secondary_username: secondaryName,
                        secondary_email: secondaryEmail,
                        business_phone: validate(primaryPhone) ? toE164(primaryPhone) : primaryPhone,
                        secondary_phone: validate(secondaryPhone) ? toE164(secondaryPhone) : secondaryPhone,
                        support_email: supportEmail,
                        support_phone: validate(supportPhone) ? toE164(supportPhone) : supportPhone,
                        ...(Number.isInteger(fleetSize) ? { fleet_size: fleetSize } : {}),
                    };

                    await UpdateMainShipperInfo({
                        variables: {
                            client_id: user?.teammate?.userByTeamLeaderId?.user_id,
                            ...mainAccountUpdatePayload,
                        },
                    });
                }

                setUpdateMessage('Profile updated!');
            } catch (e) {
                console.log({ e });
                setUpdateMessage(e);
                Sentry.captureException(e);
            }
        } else {
            setUpdateMessage('Please fill out required fields');
        }
        setValidated(true);
    };

    useEffect(() => {
        (async () => {
            setPersonalName(user?.teammate?.username || '');
            setPersonalEmail(user.email || '');

            const _personalPhone = user?.teammate?.phone
                ? validate(user?.teammate?.phone)
                    ? toNational(user?.teammate?.phone)
                    : user?.teammate?.phone
                : '';
            setPersonalPhone(_personalPhone);

            if (displayMainAccountInfo) {
                let mainAccData = user?.teammate?.userByTeamLeaderId?.client;

                const _primaryPhone = mainAccData.business_phone
                    ? validate(mainAccData.business_phone)
                        ? toNational(mainAccData.business_phone)
                        : mainAccData.business_phone
                    : '';
                const _secondaryPhone = mainAccData.secondary_phone
                    ? validate(mainAccData.secondary_phone)
                        ? toNational(mainAccData.secondary_phone)
                        : mainAccData.secondary_phone
                    : '';
                const _supportPhone = mainAccData.support_phone
                    ? validate(mainAccData.support_phone)
                        ? toNational(mainAccData.support_phone)
                        : mainAccData.support_phone
                    : '';

                setPrimaryPhone(_primaryPhone);
                setSecondaryPhone(_secondaryPhone);
                setSupportEmail(mainAccData.support_email || '');
                setSupportPhone(_supportPhone);
                setPrimaryName(mainAccData.username);
                setSecondaryName(mainAccData.secondary_username || '');
                setPrimaryEmail(mainAccData.email);
                setPrimaryEmailOriginal(mainAccData.email);
                setSecondaryEmail(mainAccData.secondary_email || '');
                setFleetSize(mainAccData.fleet_size);
            }
        })();
    }, [user]);

    const addToBlacklist = (carrier) => {
        if (props.currentShipper.blacklisted_carriers) {
            const prevList = props.currentShipper.blacklisted_carriers;
            if (!prevList.find((e) => carrier.key === e.key)) {
                props.firebase.shipper(props.currentShipperRef).update({
                    blacklisted_carriers: [...prevList, carrier],
                });
            }
        } else {
            props.firebase.shipper(props.currentShipperRef).update({
                blacklisted_carriers: [carrier],
            });
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    return (
        <Card className="tabCard">
            <Card.Body className="overflow-auto">
                <Container fluid>
                    <ThemeProvider theme={theme}>
                        <Form id="accountDetails" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="d-flex">
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            required
                                            fullWidth
                                            className=""
                                            value={personalName}
                                            variant="outlined"
                                            label={'Name'}
                                            onChange={(e) => setPersonalName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            required
                                            fullWidth
                                            className=""
                                            value={personalEmail}
                                            variant="outlined"
                                            label={'Email'}
                                            onChange={(e) => (e) => setPersonalEmail(e.target.value)}
                                            onClick={(e) => setEmailModal(true)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="my-2 w-100">
                                        <TextField
                                            label="Phone"
                                            variant="outlined"
                                            className="w-100"
                                            required
                                            onChange={(e) => setPersonalPhone(e.target.value)}
                                            name="phone"
                                            value={personalPhone}
                                            id="formatted-phone-input"
                                            InputProps={{
                                                inputComponent: PhoneNumberInput,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {displayMainAccountInfo && (
                                <>
                                    <Form.Label className="align-self-center font-weight-bold mt-3">
                                        Main Account Info
                                    </Form.Label>
                                    <Row
                                        css={css`
                                            display: flex;
                                        `}
                                    >
                                        <Col>
                                            <Form.Group
                                                css={css`
                                                    margin-top: 8px;
                                                    margin-bottom: 16px;
                                                `}
                                            >
                                                <TextField
                                                    fullWidth
                                                    value={supportEmail || ''}
                                                    variant="outlined"
                                                    label={'Support Contact Email'}
                                                    onChange={(e) => setSupportEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group
                                                css={css`
                                                    margin-top: 8px;
                                                    margin-bottom: 16px;
                                                `}
                                            >
                                                <TextField
                                                    fullWidth
                                                    value={supportPhone || ''}
                                                    variant="outlined"
                                                    label={'Support Contact Phone'}
                                                    onChange={(e) => setSupportPhone(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="d-flex">
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    error={!primaryName && validated}
                                                    fullWidth
                                                    className=""
                                                    value={primaryName || ''}
                                                    variant="outlined"
                                                    label={'Name'}
                                                    onChange={(e) => setPrimaryName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    className=""
                                                    error={!primaryEmail && validated}
                                                    value={primaryEmail || ''}
                                                    variant="outlined"
                                                    label={'Email'}
                                                    onChange={(e) => setPrimaryEmail(e.target.value)}
                                                    onClick={(e) => setMainAccountEmailModal(true)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    label="Phone"
                                                    variant="outlined"
                                                    className="w-100"
                                                    required
                                                    onChange={(e) => setPrimaryPhone(e.target.value)}
                                                    name="phone"
                                                    error={!primaryPhone && validated}
                                                    value={primaryPhone}
                                                    id="formatted-phone-input"
                                                    InputProps={{
                                                        inputComponent: PhoneNumberInput,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex">
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    fullWidth
                                                    className=""
                                                    value={secondaryName || ''}
                                                    variant="outlined"
                                                    label={'Secondary Contact Name'}
                                                    onChange={(e) => setSecondaryName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    fullWidth
                                                    className=""
                                                    value={secondaryEmail || ''}
                                                    variant="outlined"
                                                    label={' Secondary Email'}
                                                    onChange={(e) => setSecondaryEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-2 w-100">
                                                <TextField
                                                    label="Secondary Phone"
                                                    variant="outlined"
                                                    className="w-100"
                                                    onChange={(e) => setSecondaryPhone(e.target.value)}
                                                    name="Secondary Phone"
                                                    value={secondaryPhone}
                                                    id="formatted-phone-input"
                                                    InputProps={{
                                                        inputComponent: PhoneNumberInput,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FleetSizeInput fleetSize={fleetSize} setFleetSize={setFleetSize} />
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Button variant="success" type="submit" form="accountDetails" className="my-2 ml-3">
                                Save
                            </Button>
                            {updateMessage && <div>{updateMessage}</div>}
                        </Form>
                        {/* removed for the time being at Drew's request. May add back in later.
                        <h3>Blacklist</h3>
                        <div>
                          <p>
                            Here you may select carrier companies that do not wish to work with.
                            Orders that you place will not be visible to blacklisted carriers.
                          </p>
                        </div>
                        <Blacklist
                            blacklistType='shipper'
                            firebase={props.firebase}
                            currentUser={props.currentShipperRef}
                            update={addToBlacklist}
                            /> */}
                    </ThemeProvider>
                </Container>
            </Card.Body>
            <AdminEmailModal show={emailModal} onHide={() => setEmailModal(false)} isImposter={isImposter} />
            <MainAccountEmailModal
                show={mainAccountEmailModal}
                onHide={() => setMainAccountEmailModal(false)}
                isImposter={isImposter}
            />
        </Card>
    );
}
