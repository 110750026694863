import React, { useCallback } from 'react';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';

export const useExceptionCallbacks = (
    { order, exceptionParams, route_id },
    { insertExceptionEvent, insertException, updateException }
) => {
    const createException = useCallback(
        (exception) => {
            const { __typename, items, images, ...rest } = exception;

            if (exceptionParams.action) {
                const orderUpdates = exceptionParams.orderUpdates || {
                    ltl_dropoff_arrival: null,
                    del_window_start: null,
                    del_window_end: null,
                };
                return insertExceptionEvent({
                    variables: {
                        exception_event: {
                            ev_action: exceptionParams.action,
                            ev_status: 'NOT_DELIVERED',
                            ex_call_or_text: rest.call_or_text,
                            ex_images: toArrayLiteral(images || []),
                            ex_instruction: rest.instruction,
                            ex_notes: rest.notes,
                            ex_reason: rest.reason,
                            ex_reported_at: rest.reported_at,
                            ex_resolution: rest.resolution,
                            ex_ring_or_knock: rest.ring_or_knock,
                            ex_route_id: route_id,
                            ex_type: rest.type,
                            ex_wait_time: rest.wait_time,
                            mapping_exception_type: items ? 'ITEM' : 'ORDER',
                            mapping_items: items
                                ? toArrayLiteral(
                                      Object.values(items)
                                          .map((item) => item?.item_id)
                                          .filter((item_id) => !!item_id)
                                  )
                                : toArrayLiteral([]),
                            mapping_order: order.order_id,
                        },
                        order: {
                            order_id: order.order_id,
                            ...orderUpdates,
                        },
                        order_update_cols: Object.keys(orderUpdates),
                    },
                });
            } else if (rest.exception_id) {
                return updateException({
                    variables: {
                        exception_id: rest.exception_id,
                        exception: {
                            ...rest,
                            images: toArrayLiteral(images || []),
                        },
                    },
                });
            } else {
                return insertException({
                    variables: {
                        exceptions:
                            items && Object.values(items).length > 0
                                ? Object.values(items).map((item) => ({
                                      exception_type: 'ITEM',
                                      order_id: item.order_id,
                                      item_id: item.item_id,
                                      exception: {
                                          data: { ...rest, images: toArrayLiteral(images || []) },
                                      },
                                  }))
                                : [
                                      {
                                          exception_type: 'ORDER',
                                          order_id: order.order_id,
                                          exception: {
                                              data: { ...rest, images: toArrayLiteral(images || []) },
                                          },
                                      },
                                  ],
                    },
                });
            }
        },
        [order, exceptionParams]
    );

    return { createException };
};
