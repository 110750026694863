import React, { useEffect, useMemo, useState } from 'react';
import {
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../DispatchPlan/blocks';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS } from '../DispatchPlan/graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';
import { post } from '@/utilities/onwardClient';
import { SEND_GIG_CANCELLATION_EMAIL } from '@/constants/apiRoutes';
import { OnwardCheckbox } from '../ShipmentForm/blocks';
import {
    INSERT_EXCEPTION,
    INSERT_EXCEPTION_EVENT,
    INSERT_EXCEPTION_WITH_MAPPINGS,
    UPDATE_EXCEPTION,
} from './graphql/mutations';
import { captureException } from '@sentry/react';
import {
    INSTRUCTIONS,
    INSTRUCTIONS_DISPLAY_MAPPING,
} from '../admin/AdminExceptions/modals/ExceptionResolutionModal/constants';
import { EXCEPTION_TYPES } from '@onward-delivery/core';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { useExceptionCallbacks } from './hooks';
import { ORDER_BY_ID } from './queries';
import { QUERY_BY_ORDER } from '@/graphql/queries/order_wh_events';

const CancelOrderModal = ({ route, open, handleClose, order, refetchOrder, setNotification, isOnRoute }) => {
    const { user_id, default_end_location } = useClientUser();

    const [exceptionInstruction, setExceptionInstruction] = useState(INSTRUCTIONS.DISPOSE_ITEMS);
    const [exceptionParams, setExceptionParams] = useState({});
    const [selectedItems, setSelectedItems] = useState({});

    useEffect(() => {
        const allItems = {};
        order.itemsByOrderId.forEach((item) => {
            allItems[item.item_id] = item;
        });
        setSelectedItems(allItems);
    }, [order.itemsByOrderId]);

    const allItemsSelected = useMemo(() => {
        return Object.keys(selectedItems).length === order.itemsByOrderId.length;
    }, [selectedItems, order.itemsByOrderId]);

    const isOrderCarrier = useMemo(() => {
        return user_id === order?.carrier_id;
    }, [user_id, order]);

    const isGigOrder = useMemo(() => {
        return ['FRAYT', 'BUNGII'].includes(order?.shipper_rate_override?.type);
    }, [order]);

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS, {});

    const [updateOrder, { loading: cancelLoading }] = useMutation(UPDATE_ORDER_BY_ID, {});

    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to add exception. Please try again' });
        },
    });

    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to update exception. Please try again' });
        },
    });

    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        update: (cache, { data: { mappings, order } }) => {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order.order_id,
                    },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }

                    const clone = {
                        ...data.orders[0],
                        ...order,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_BY_ORDER,
                    variables: { order_id: order.order_id },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }
                    const clone = [...order.wh_events];

                    return {
                        ...data,
                        results: clone,
                    };
                }
            );
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to insert event. Please try again' });
        },
    });

    const { createException } = useExceptionCallbacks(
        { order, exceptionParams, user_id },
        { insertExceptionEvent, insertException, updateException }
    );

    const handleItemCheck = (item) => {
        setSelectedItems((prevSelectedItems) => {
            const newSelectedItems = { ...prevSelectedItems };
            if (newSelectedItems[item.item_id]) {
                delete newSelectedItems[item.item_id];
            } else {
                newSelectedItems[item.item_id] = item;
            }
            return newSelectedItems;
        });
    };

    const handleAllItemsCheck = () => {
        setSelectedItems((prevSelectedItems) => {
            if (Object.keys(prevSelectedItems).length === order.itemsByOrderId.length) {
                return {};
            } else {
                const allItems = {};
                order.itemsByOrderId.forEach((item) => {
                    allItems[item.item_id] = item;
                });
                return allItems;
            }
        });
    };

    const handleRemoveOrder = async () => {
        let newStops = cloneDeep(route?.stopsByRouteId || []);
        newStops = await FTLStopHelpers.removeStop(order, { ...route, stopsByRouteId: newStops }, default_end_location);

        const pickups = order.type === 'PICKUP' ? [order] : [];
        const deliveries = order.type !== 'PICKUP' ? [order] : [];

        updateStops({
            variables: {
                route_id: route?.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                events: [
                    ...pickups.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:REMOVE_PU`,
                        notes: `Removed route ${route.route_number}`,
                    })),
                    ...deliveries.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:${order.event_state === 'PICKED_UP' ? 'REMOVE' : 'REMOVE_DO'}`,
                        notes: `Removed route ${route.route_number}`,
                    })),
                ],
                order_updates:
                    deliveries?.map((order) => ({
                        where: { order_id: { _eq: order.order_id } },
                        _set: {
                            delivery_time_confirmed: null,
                            del_window_start: null,
                            del_window_end: null,
                            original_del_window_start: null,
                            original_del_window_end: null,
                            order_status: isOrderCarrier ? 'rejected' : 'cancelled',
                        },
                    })) || [],
                ...FTLStopHelpers.gqlStopUpdates(newStops, route),
            },
            onError: (error) => {
                setNotification({
                    severity: 'error',
                    message: 'There was an error when attempting to remove the order from the route.',
                });
                Sentry.captureException(error);
                console.error(error);
            },
            onCompleted: async () => {
                try {
                    await createException({
                        reported_at: 'PICKUP',
                        type: EXCEPTION_TYPES.CARRIER_CANCELLATION,
                        instruction: exceptionInstruction,
                    });
                } finally {
                    handleClose();
                    refetchOrder();
                }
            },
        });
    };

    const handleCancelOrder = async () => {
        try {
            if (isGigOrder) {
                const { status } = await post(SEND_GIG_CANCELLATION_EMAIL, {
                    order_id: order.order_id,
                });

                if (status !== 200) {
                    setNotification({
                        severity: 'error',
                        message: 'There was an error when attempting to send the cancellation email.',
                    });
                    return;
                }
            } else {
                if (order.carrier_id) {
                    await createException({
                        reported_at: 'PICKUP',
                        type: EXCEPTION_TYPES.CARRIER_CANCELLATION,
                        instruction: exceptionInstruction,
                    });
                }

                if (allItemsSelected) {
                    await updateOrder({
                        variables: {
                            order_id: order.order_id,
                            update: {
                                order_status: isOrderCarrier ? 'rejected' : 'cancelled',
                            },
                        },
                        onError: (error) => {
                            setNotification({
                                severity: 'error',
                                message: 'There was an error when attempting to cancel the order.',
                            });
                            Sentry.captureException(error);
                            console.error(error);
                        },
                    });
                }
            }
        } finally {
            handleClose();
            refetchOrder();
        }
    };

    return (
        <NavResponsiveModal open={open} onClose={handleClose}>
            <ModalTitle>
                {isOnRoute
                    ? `Remove order ${order.order_number} from route ${route?.route_number} and cancel order`
                    : `Cancel Order ${order.order_number}`}
            </ModalTitle>
            <ModalContent>
                {isGigOrder || !order.carrier_id || isOrderCarrier ? null : (
                    <>
                        <GridItemRow>
                            <Body2>Which items would you like to cancel?</Body2>
                        </GridItemRow>
                        <GridItemRow>
                            <OnwardCheckbox
                                label={'All Items'}
                                checked={allItemsSelected}
                                onChange={handleAllItemsCheck}
                            />
                        </GridItemRow>
                        {order.itemsByOrderId.map((item, i) => (
                            <GridItemRow key={i}>
                                <OnwardCheckbox
                                    label={`${item.description || item.item_type_details || 'NO DESCRIPTION'}${
                                        item.sku ? ` / ${item.sku}` : ''
                                    }`}
                                    checked={!!selectedItems[item.item_id]}
                                    onChange={() => handleItemCheck(item)}
                                />
                            </GridItemRow>
                        ))}

                        <GridItemRow>
                            <Body2>What would you like to do with the items?</Body2>
                        </GridItemRow>
                        {Object.values(INSTRUCTIONS).map((instruction) => (
                            <GridItemRow key={instruction}>
                                <OnwardCheckbox
                                    label={
                                        instruction === 'NOOP'
                                            ? 'No resolution needed'
                                            : INSTRUCTIONS_DISPLAY_MAPPING[instruction]
                                    }
                                    checked={exceptionInstruction === instruction}
                                    onChange={() => setExceptionInstruction(instruction)}
                                />
                            </GridItemRow>
                        ))}
                    </>
                )}
                <GridItemRow>
                    <Body2>
                        {isOnRoute
                            ? 'This order is currently on a route in the Planning page. By canceling this order and removing this stop from the route, the timeframes of the stops after this will be affected. Confirm to recalculate these timeframes.'
                            : `This order is currently scheduled for ${order.delivery_date_formatted}. Are you sure you want to cancel this order? This cannot be undone.`}
                    </Body2>
                </GridItemRow>
                {isGigOrder || !order.carrier_id ? null : (
                    <GridItemRow>
                        <Body2>
                            *This will create an exception that you can further action on and resolve in the exceptions
                            manager.
                        </Body2>
                    </GridItemRow>
                )}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading || cancelLoading} onClick={handleClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    disabled={loading || cancelLoading}
                    onClick={isOnRoute ? handleRemoveOrder : handleCancelOrder}
                >
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default CancelOrderModal;
