import { useMemo, useCallback } from 'react';
import generateCSV from '@/utilities/createCSV';

export const useAdminExceptionHooks = (
    { filtered, selected, where, exceptions },
    { fetchMore, setHasMore, retrieveCsv }
) => {
    const TODAY = new Date().setHours(0, 0, 0, 0);

    const cursor = useMemo(() => {
        if (exceptions.length === 0) {
            return null;
        }

        return exceptions[exceptions.length - 1].created_at;
    }, [exceptions]);

    const loadMore = useCallback(() => {
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: { _lte: cursor } }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                const prev = Object.fromEntries(
                    (data.results || []).map((exception) => [exception.exception_id, true])
                );
                const clone = [
                    ...(data.results || []),
                    ...(fetchMoreResult.results || []).filter((exception) => !prev[exception.exception_id]),
                ];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const exportCSV = useCallback(() => {
        return retrieveCsv({ query: { _and: where } }).then((result) => {
            const TODAY = new Date();
            const blob = new Blob([result.data]);

            const filename = `Exceptions ${TODAY.getTime()}.csv`;
            const autoclick = document.createElement('a');
            const payload = URL.createObjectURL(blob);
            autoclick.setAttribute('href', payload);
            autoclick.setAttribute('download', filename);
            autoclick.style.visibility = 'hidden';
            document.body.appendChild(autoclick);
            autoclick.click();
            document.body.removeChild(autoclick);
        });
    }, [where, retrieveCsv]);

    return {
        exportCSV,
        loadMore,
        cursor,
    };
};
