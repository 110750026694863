import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from './clients';
import { LOCATION_FIELDS } from './locations';
import { SHIPPING_PARTNER_FIELDS } from './shipping_partners';
import { TEAMMATE_FIELDS } from './teammates';
import { PRICING_OVERRIDE_FIELDS } from '../fragments/pricing_overrides';
import { TRUCK_FIELDS } from './trucks';
import { PARTNERSHIP_FIELDS } from '../fragments/partnerships';
import { SERVICE_LEVEL_FIELDS } from '../fragments/service_levels';
import { SURVEY_QUESTION_FIELDS } from '../fragments/surveys';
import { TAG_FIELDS, TAG_MAPPING_FIELDS } from '../fragments/tags';
import { DOCUMENT_FIELDS } from './documents';
import { INTEGRATION_OVERRIDE_FIELDS } from '../fragments/integration_overrides';

export const USER_FIELDS = gql`
    fragment UserFields on users {
        circles
        device_settings
        email
        geolocation
        geolocation_expiration
        legacy_user_id
        roles
        username
        last_modified_at
        created_at
        user_id
    }
`;

export const FULL_QUERY = gql`
    ${USER_FIELDS}

    query GetUsers {
        users {
            ...UserFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${USER_FIELDS}
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}
    ${TEAMMATE_FIELDS}
    ${TRUCK_FIELDS}
    ${SHIPPING_PARTNER_FIELDS}
    ${PRICING_OVERRIDE_FIELDS}
    ${PARTNERSHIP_FIELDS}
    ${SERVICE_LEVEL_FIELDS}
    ${SURVEY_QUESTION_FIELDS}
    ${TAG_FIELDS}
    ${TAG_MAPPING_FIELDS}
    ${DOCUMENT_FIELDS}
    ${INTEGRATION_OVERRIDE_FIELDS}

    query UserById($user_id: uuid!) {
        user: users_by_pk(user_id: $user_id) {
            ...UserFields
            client {
                ...ClientFields
                locations {
                    ...LocationFields
                }
                trucks {
                    ...TruckFields
                }
                documents {
                    ...DocumentFields
                }
                service_levels(order_by: { service_level: asc }) {
                    ...ServiceLevelFields
                    tags {
                        ...TagMappingFields
                    }
                }
                shipper_integrations: shipper_integration_overrides {
                    ...IntegrationOverrideFields
                }
                survey_questions {
                    ...SurveyQuestionFields
                }
                tags {
                    ...TagFields
                }
                carrier_partnerships(order_by: { shipper: { business_name: asc } }) {
                    ...PartnershipFields
                    shipper {
                        ...ClientFields
                    }
                }
                shipper_partnerships(order_by: { carrier: { business_name: asc } }) {
                    ...PartnershipFields
                    carrier {
                        ...ClientFields
                        user {
                            ...UserFields
                            teammatesByLeaderId {
                                ...TeammateFields
                                user {
                                    ...UserFields
                                }
                            }
                        }
                    }
                }
            }
            teammate {
                ...TeammateFields
                userByTeamLeaderId {
                    ...UserFields
                    client {
                        ...ClientFields
                        locations {
                            ...LocationFields
                        }
                        trucks {
                            ...TruckFields
                        }
                        service_levels(order_by: { service_level: asc }) {
                            ...ServiceLevelFields
                        }
                        survey_questions {
                            ...SurveyQuestionFields
                        }
                        tags {
                            ...TagFields
                        }
                        carrier_partnerships(order_by: { shipper: { business_name: asc } }) {
                            ...PartnershipFields
                            shipper {
                                ...ClientFields
                            }
                        }
                        shipper_partnerships(order_by: { carrier: { business_name: asc } }) {
                            ...PartnershipFields
                            carrier {
                                ...ClientFields
                                user {
                                    ...UserFields
                                    teammatesByLeaderId {
                                        ...TeammateFields
                                        user {
                                            ...UserFields
                                        }
                                    }
                                }
                            }
                        }
                    }
                    teammatesByLeaderId {
                        ...TeammateFields
                    }
                }
            }
            teammatesByLeaderId {
                ...TeammateFields
            }
        }
    }
`;

export const CLIENT_BY_ID = gql`
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${LOCATION_FIELDS}
    ${SERVICE_LEVEL_FIELDS}
    ${TAG_MAPPING_FIELDS}
    ${SURVEY_QUESTION_FIELDS}
    ${TAG_FIELDS}
    ${TRUCK_FIELDS}
    ${PARTNERSHIP_FIELDS}
    ${TEAMMATE_FIELDS}
    ${DOCUMENT_FIELDS}
    query ClientById($client_id: uuid!) {
        client: clients_by_pk(client_id: $client_id) {
            ...ClientFields
            user {
                ...UserFields
                teammatesByLeaderId {
                    ...TeammateFields
                }
            }
            carrier_partnerships(order_by: { shipper: { business_name: asc } }) {
                ...PartnershipFields
                shipper {
                    ...ClientFields
                }
            }
            locations {
                ...LocationFields
            }
            service_levels(order_by: { service_level: asc }) {
                ...ServiceLevelFields
                tags {
                    ...TagMappingFields
                }
            }
            shipper_partnerships(order_by: { carrier: { business_name: asc } }) {
                ...PartnershipFields
                carrier {
                    ...ClientFields
                    user {
                        ...UserFields
                        teammatesByLeaderId {
                            ...TeammateFields
                            user {
                                ...UserFields
                            }
                        }
                    }
                }
            }
            survey_questions {
                ...SurveyQuestionFields
            }
            tags {
                ...TagFields
            }
            trucks {
                ...TruckFields
            }
            documents {
                ...DocumentFields
            }
        }
    }
`;

export const TEAMMATE_CLIENT_BY_ID = gql`
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${LOCATION_FIELDS}
    ${SERVICE_LEVEL_FIELDS}
    ${TAG_MAPPING_FIELDS}
    ${SURVEY_QUESTION_FIELDS}
    ${TAG_FIELDS}
    ${TRUCK_FIELDS}
    ${PARTNERSHIP_FIELDS}
    ${TEAMMATE_FIELDS}
    query TeammateClientById($teammate_id: uuid!) {
        teammate: teammates_by_pk(teammate_id: $teammate_id) {
            ...TeammateFields
            userByTeamLeaderId {
                user_id
                client {
                    ...ClientFields
                    user {
                        ...UserFields
                        teammatesByLeaderId {
                            ...TeammateFields
                        }
                    }
                    carrier_partnerships(order_by: { shipper: { business_name: asc } }) {
                        ...PartnershipFields
                        shipper {
                            ...ClientFields
                        }
                    }
                    locations {
                        ...LocationFields
                    }
                    service_levels(order_by: { service_level: asc }) {
                        ...ServiceLevelFields
                        tags {
                            ...TagMappingFields
                        }
                    }
                    shipper_partnerships(order_by: { carrier: { business_name: asc } }) {
                        ...PartnershipFields
                        carrier {
                            ...ClientFields
                            user {
                                ...UserFields
                                teammatesByLeaderId {
                                    ...TeammateFields
                                    user {
                                        ...UserFields
                                    }
                                }
                            }
                        }
                    }
                    survey_questions {
                        ...SurveyQuestionFields
                    }
                    tags {
                        ...TagFields
                    }
                    trucks {
                        ...TruckFields
                    }
                }
            }
        }
    }
`;

export const ADMIN_CLIENTS_BY_ID = gql`
    ${CLIENT_FIELDS}
    query AdminClientsById($admin_id: uuid!) {
        admin_client_mappings(where: { admin_id: { _eq: $admin_id } }) {
            admin_id
            client_id
            client {
                ...ClientFields
            }
        }
    }
`;

export const QUERY_BY_FIREBASE_ID = gql`
    ${USER_FIELDS}
    query UsersByFirebaseId($legacy_user_id: String) {
        users(where: { legacy_user_id: { _eq: $legacy_user_id } }) {
            ...UserFields
        }
    }
`;

export const QUERY_BY_EMAIL = gql`
    ${USER_FIELDS}
    query UserByEmail($email: String) {
        users(where: { email: { _eq: $email } }) {
            ...UserFields
        }
    }
`;
