import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid, Menu, MenuItem, IconButton } from '@material-ui/core';
import {
    Clear,
    MoreVert,
    StoreOutlined,
    WhereToVoteOutlined,
    DragHandle,
    AddCircle,
    RemoveCircle,
    Edit,
    CheckCircle,
} from '@material-ui/icons';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { OnwardSwitch } from '@/styles/blocks';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useTotalCubes, useTotalWeight, useItemQuantity, useExchangeCubes } from '@/hooks';
import { poundFormatter, integerFormatter, minuteString, hourStringShort } from '@/constants/formats';
import routeStatusOptions from '@/constants/routeStatusOptions';
import { delWindowToFormattedDateRange, dateToFormattedDate } from '@/utilities/delWindowToFormattedDate';
import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { isOrderCancelled } from '../util/isOrderCancelled';
import { PlanningContext } from '../context';
import { MODALS } from '../constants';
import { useClientUser } from '@/hooks/useClientUser';
import { captureException } from '@sentry/react';
import {
    Body1,
    Body2,
    circleStyle,
    ConfirmationText,
    greyText,
    GridItemRow,
    iconStyle,
    editiconStyle,
    PlanningListItem,
} from '../blocks';
import { colors } from '@/styles';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDERS, UPDATE_ROUTE_STOPS, UPDATE_ROUTE } from '../graphql/mutations';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { useCallbacks } from '../hooks';
import { cloneDeep } from 'lodash';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { addRefs, removeRefs } from '@/graphql/util';
import { TIMEFRAME_CONFIRMATION_SUBSCRIPTION } from '../graphql/queries';
import { format } from 'date-fns';

const IconPlaceholder = styled.div`
    width: 20px;
    height: 20px;
`;

const IconPlaceholder24 = styled.div`
    width: 24px;
    height: 24px;
`;
const ItemTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: 12,
            border: '1px solid #dadde9',
        },
    })
);

const StopListItem = ({ stop, route, isLocked, subscribeToMore, truck, stopSequenceEnriched }) => {
    const { circles, preferences_service_time_incr, user_id, ...timeframeOptions } = useClientUser();

    const [stopOrders, stopOrdersByKey] = useMemo(() => {
        const byKey = Object.fromEntries(
            (route?.orders || [])
                .filter((mapping) => (stop?.orders || []).includes(mapping.order_id))
                .map((mapping) => [mapping.order_id, mapping.order])
        );
        return [Object.values(byKey), byKey];
    }, [route, stop]);

    const [updateStops, { loading: stopsLoading }] = useMutation(UPDATE_ROUTE_STOPS, {
        update: (cache, { data }) => {
            const newStops = data?.insert_stops?.returning || [];
            const removedStops = data?.delete_stops?.returning || [];

            cache.modify({
                id: cache.identify(route),
                fields: {
                    stopsByRouteId: (prev, { toReference }) => {
                        const withNew = addRefs(prev, newStops, { toReference });
                        return removeRefs(withNew, removedStops, { toReference });
                    },
                },
            });

            removedStops.forEach((stop) => cache.evict(cache.identify(stop)));
        },
    });
    const [updateOrders] = useMutation(UPDATE_ORDERS);

    const [updateRoute, { loading: updateRouteLoading }] = useMutation(UPDATE_ROUTE, {
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const { setActionableRoute, setActionableStop, setActionableOrder, setModalOpen, setNotification, setError } =
        useContext(PlanningContext);

    const { pushRouteToTracking } = useCallbacks();

    const totalVolume = useTotalCubes(stopOrders);
    const totalWeight = useTotalWeight(stopOrders);
    const totalItems = useItemQuantity(stopOrders);
    const truckCapacity = useMemo(() => {
        return truck?.truck_capacity || 1200;
    }, [truck]);

    const truckCubes = useMemo(() => {
        let currentStop = stopSequenceEnriched.find((s) => s.ordering === stop.ordering);
        return currentStop && currentStop.totalTruckCubes ? currentStop.totalTruckCubes : 0;
    }, [stop, route, stopSequenceEnriched]);

    const serviceTime = useMemo(() => {
        // If the stop already has a service time, return that.
        if (stop.service_time === 0 || stop.service_time) {
            return stop.service_time;
        }

        const { hours, minutes } = calcDefaultServiceTime(stop, stopOrdersByKey, timeframeOptions);
        return ((hours || 0) * 60 + (minutes || 0)) * 60000;
    }, [stop, stopOrdersByKey]);

    const status = React.useMemo(() => {
        return stopOrders.reduce((status, order) => {
            if (isOrderCancelled(order)) {
                if (status) return status;
                return order.delivery_time_confirmed;
            }

            if (status === 'recalculate' || order.delivery_time_confirmed === 'recalculate') {
                return 'recalculate';
            }

            // If all statuses are falsey, no badge is displayed
            if (!status) {
                if (order.delivery_time_confirmed === 'unsure') {
                    return 'response unclear - call customer';
                }
                return order.delivery_time_confirmed;
            }

            // Rejected if any order is rejected
            if (status === 'rejected' || order.delivery_time_confirmed === 'rejected') return 'rejected';

            if (status === 'SMS failed' || order.delivery_time_confirmed === 'SMS failed') return 'SMS failed';

            // Confirmed if all orders are confirmed
            if (status === 'confirmed' && order.delivery_time_confirmed === 'confirmed') return 'confirmed';

            // Pending for any other combination of pending/confirmed
            return 'pending';
        }, null);
    }, [stopOrders]);

    const inProgress = useMemo(() => {
        const ordersInProgress = (route.stopsByRouteId || []).reduce((acc, s) => {
            return [...acc, ...(s.stop_completion_time ? s.orders || [] : [])];
        }, []);
        return (stop.orders || []).some((order_id) => ordersInProgress.includes(order_id));
    }, [stop, route]);

    useEffect(() => {
        let unsubscribe;

        unsubscribe = subscribeToMore({
            document: TIMEFRAME_CONFIRMATION_SUBSCRIPTION,
            variables: { order_ids: Object.keys(stopOrdersByKey) },
        });

        // Unsubscribe
        if (unsubscribe) return () => unsubscribe();
    }, [stopOrders, subscribeToMore]);

    let cancelMap = {};
    const allCanceled = React.useMemo(() => {
        let everyOrderCancelled = stopOrders.reduce((_allCancelled, _o) => {
            if (isOrderCancelled(_o)) {
                cancelMap[_o.order_number] = true;
                if (_allCancelled) return true;
            }
            return false;
        }, true);
        return everyOrderCancelled;
    }, [stopOrders]);

    const modifyServiceTime = useCallback(
        (time, stop, route) => {
            let newServiceTime = serviceTime + time;
            if (newServiceTime < 0) newServiceTime = 0;

            updateStops({
                variables: {
                    route_id: route.route_id,
                    route_update: {
                        need_to_optimize: true,
                    },
                    updates: [
                        {
                            where: { stop_id: { _eq: stop.stop_id } },
                            _set: {
                                service_time: newServiceTime,
                            },
                        },
                    ],
                },
                optimisticResponse: {
                    update_stops_many: [
                        {
                            affected_rows: 1,
                            returning: {
                                ...stop,
                                service_time: newServiceTime,
                            },
                        },
                    ],
                    delete_stops: [{ affected_rows: 0, returning: [] }],
                    insert_stops: [{ affected_rows: 0, returning: [] }],
                    update_orders_many: [{ affected_rows: 0, returning: [] }],
                    update_routes_by_pk: {
                        ...route,
                        need_to_optimize: true,
                    },
                },
                onError: (error) => {
                    setError(error, 'Error updating stop service time');
                },
            });
        },
        [serviceTime, stop]
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const editTimeframe = () => {
        setModalOpen(MODALS.CUSTOM_TIMEFRAME);
        setActionableRoute(route);
        setActionableStop({ ...stop, stopOrders });
    };

    const handleAction = (e, option) => {
        setAnchorEl(null);
        e.stopPropagation();

        let status = 'pending';
        switch (option) {
            case 'Confirm timeframe':
                status = 'confirmed';
                break;
            case 'Reject timeframe':
                status = 'rejected';
                break;
            case 'Remove':
                return onRemoveStop();
            case 'Communications Log':
                setModalOpen(MODALS.COMMUNICATIONS);
                setActionableRoute(route);
                setActionableStop(stop);
                return;
            // Todo in future - re-add ability to add a note here if desired.
            // case 'Add Note':
            //     setModalOpen(MODALS.NOTES);
            //     setActionableRoute(route);
            //     setActionableStop(stop);
            //     return;
            default:
                status = 'pending';
        }

        updateOrders({
            variables: {
                order_ids: stop.orders,
                update: {
                    delivery_time_confirmed: status,
                },
            },
            onError: (error) => {
                setError(error, 'Error updating stop confirmation status');
            },
            onCompleted: () => {
                // If all other orders are confirmed, push route to tracking
                if (status === 'confirmed') {
                    const ordersByKey = Object.fromEntries(
                        (route.orders || []).map((mapping) => [mapping.order_id, mapping.order])
                    );
                    if (
                        (route.stopsByRouteId || []).every((s) => {
                            // skip this stop and other non customer stops
                            if (s.stop_id === stop.stop_id || !FTLStopHelpers.isCustomerStop(s, route)) {
                                return true;
                            }
                            return (s.orders || []).every(
                                (order_id) =>
                                    ordersByKey[order_id]?.order_status === 'cancelled' ||
                                    ordersByKey[order_id]?.delivery_time_confirmed === 'confirmed'
                            );
                        })
                    ) {
                        pushRouteToTracking(route, route.sending_to);
                    }
                }
            },
        });
    };

    const onRemoveStop = () => {
        if (stop.stop_completion_time) {
            setNotification({
                severity: 'warning',
                message: 'This stop has already been completed.',
            });
        } else if (inProgress) {
            setNotification({
                severity: 'warning',
                message:
                    'Orders have already been picked up and cannot be removed. Reschedule the delivery from the Mobile App or Tracking instead.',
            });
        } else if (route.status !== routeStatusOptions.PLANNING.value) {
            setNotification({
                severity: 'warning',
                message:
                    'Cannot remove an order from a route that is in progress. If you would like to make edits, unlock the route first.',
            });
        } else {
            setModalOpen(MODALS.ROUTE_REMOVE_STOP);
            setActionableRoute(route);
            setActionableStop({ ...stop, stopOrders });
        }
    };

    const removeLunchStop = () => {
        const clonedStops = cloneDeep(route.stopsByRouteId);
        const idxOfLunch = clonedStops.findIndex((s) => s.type === 'LUNCH');
        const lunchStop = clonedStops.splice(idxOfLunch, 1)?.[0];

        const stopsToUpdate = clonedStops.filter((stop) => stop.ordering > lunchStop.ordering);
        const ordersThatNeedRecalculating = stopsToUpdate.reduce((acc, stop) => {
            return [...acc, ...(stop?.orders || [])];
        }, []);

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: { need_to_optimize: true },
                order_updates: [
                    {
                        where: { order_id: { _in: ordersThatNeedRecalculating } },
                        _set: {
                            delivery_time_confirmed: 'recalculate',
                        },
                    },
                ],
                updates: stopsToUpdate.map((updatedStop) => ({
                    where: { stop_id: { _eq: updatedStop.stop_id } },
                    _set: {
                        ordering: updatedStop.ordering - 1,
                        orders: updatedStop.orders?.length ? toArrayLiteral(updatedStop.orders) : null,
                        returns: updatedStop.returns?.length ? toArrayLiteral(updatedStop.returns) : null,
                        exchanges: updatedStop.exchanges?.length ? toArrayLiteral(updatedStop.exchanges) : null,
                        ...Object.fromEntries(
                            FTLStopHelpers.stopFieldsToUpdate.map((key) => [key, updatedStop[key] || null])
                        ),
                    },
                })),
                deletes: lunchStop.stop_id,
            },
            onError: (error) => {
                setError(error, 'Error removing lunch stop');
            },
        });
    };

    const options = useMemo(() => {
        const optional = isLocked ? [] : ['Remove'];
        if (FTLStopHelpers.isCustomerStop(stop, route)) {
            if (isLocked) {
                return [...optional, 'Communications Log'];
            }
            return [
                ...(status !== 'confirmed' ? ['Confirm timeframe'] : []),
                ...(status !== 'rejected' ? ['Reject timeframe'] : []),
                ...optional,
                'Communications Log',
            ];
        }
        return optional;
    }, [stop, route, status, isLocked, inProgress]);

    const isFinalDropoff = useMemo(() => {
        return stop.type === 'DROPOFF' && stop.returns?.length;
    }, [stop]);
    const isNonFinalDropoff = useMemo(() => {
        return stop.type === 'DROPOFF' && !stop.returns?.length;
    }, [stop]);
    const isExchangeDropoff = useMemo(() => {
        return stop.type === 'DROPOFF' && stop.exchanges?.length;
    }, [stop]);
    const isNormalDropoff = useMemo(() => {
        return stop.type === 'DROPOFF' && !stop.returns?.length && !stop.exchanges?.length;
    }, [stop]);

    const isReturnPickup = useMemo(() => {
        return stop.type === 'PICKUP' && stop.returns?.length;
    }, [stop]);
    const isNormalPickup = useMemo(() => {
        return stop.type === 'PICKUP' && !stop.returns?.length;
    }, [stop]);
    const isLunchStop = useMemo(() => {
        return stop.type === 'LUNCH';
    }, [stop]);

    const orderLocationTypes = stopOrders.reduce((types, order) => {
        const type = order.order_type === 'return' ? order.pickup_location_type : order.dropoff_location_type;
        if (type && !types.includes(type)) {
            return [...types, type];
        }
        return types;
    }, []);

    const poNumbers = stopOrders.filter((order) => order.po_number).map((order) => order.po_number);

    const isClaimed = stopOrders?.some((order) => !order.oms);

    const stopListItemHeader = useMemo(() => {
        if (isLunchStop) {
            return `${stop.ordering}. Lunch`;
        }

        let name = 'Stop';
        if (FTLStopHelpers.isCustomerStop(stop, route)) {
            name = stopOrders[0]?.dropoff_name || 'Customer Name';
        } else if (FTLStopHelpers.isCrossdockStop(stop, route)) {
            name = 'Cross-dock';
        } else if (isFinalDropoff) {
            name = 'Final Return';
        } else if (stop.type === 'PICKUP') {
            name = 'Pickup';
        } else if (stop.type === 'DROPOFF') {
            name = 'Dropoff';
        }
        return `${stop.ordering}. ${name}`;
    });

    const serviceTimeText = useMemo(() => {
        if (isLunchStop) {
            return `${serviceTime / 60000} min`;
        }
        return `${isNormalPickup ? 'Load' : 'Service'} Time: ${minuteString.format(serviceTime / 60000)}`;
    });

    const setOvernight = (stop_id, newValue) => {
        const ordersToRecalculate = (route?.orders || [])
            .map((mapping) => mapping.order)
            .reduce((toRecal, o) => {
                const orderStop = route?.stopsByRouteId?.find((s) => {
                    return s?.orders?.includes(o.order_id) && !FTLStopHelpers.isWarehousePickup(s);
                });
                if (o?.delivery_time_confirmed && orderStop.ordering > stop.ordering) {
                    return [...toRecal, o.order_id];
                }
                return toRecal;
            }, []);

        updateStops({
            variables: {
                route_id: route.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                order_updates: [
                    {
                        where: { order_id: { _in: ordersToRecalculate } },
                        _set: {
                            delivery_time_confirmed: 'recalculate',
                        },
                    },
                ],
                updates: [
                    {
                        where: { stop_id: { _in: [stop_id] } },
                        _set: {
                            del_window_end: null,
                            del_window_start: null,
                            stop_start_time: null,
                            stop_end_time: null,
                            overnight: newValue,
                        },
                    },
                ],
            },
            onError: (error) => {
                setError(error, 'Error changing route to overnight');
            },
        });
    };

    return (
        <PlanningListItem
            selected={open}
            css={css`
                flex-wrap: nowrap !important;
                flex-direction: row;
            `}
        >
            {!isLocked && (
                <Grid
                    item
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-right: 1rem !important;
                    `}
                >
                    {!isFinalDropoff ? <DragHandle fontSize="small" /> : <IconPlaceholder />}
                </Grid>
            )}
            <Grid
                item
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                `}
            >
                {/* Stop # + Customer Name / Pickup / Final Return + Icons/Options */}
                <GridItemRow>
                    <Body1>
                        {stopListItemHeader}{' '}
                        {isClaimed && (
                            <Body2
                                css={css`
                                    color: ${colors.greens.primary};
                                    font-style: italic;
                                `}
                            >
                                (Marketplace)
                            </Body2>
                        )}
                    </Body1>
                    <Grid
                        item
                        css={css`
                            display: flex;
                        `}
                    >
                        {isLunchStop ? (
                            <IconButton
                                onClick={removeLunchStop}
                                css={css`
                                    padding: 0 !important;
                                `}
                            >
                                <Clear css={iconStyle} />
                            </IconButton>
                        ) : (
                            <>
                                {FTLStopHelpers.isCrossdockStop(stop, route) ? (
                                    <WarehouseOutlinedIcon css={iconStyle} />
                                ) : isNormalPickup || isFinalDropoff ? (
                                    <StoreOutlined css={iconStyle} />
                                ) : (
                                    <WhereToVoteOutlined css={iconStyle} />
                                )}
                                <IconButton
                                    onClick={handleClick}
                                    css={css`
                                        padding: 0 !important;
                                    `}
                                >
                                    <MoreVert css={iconStyle} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    css={css`
                                        inset: 0px calc(1rem + 50px) !important;
                                    `}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option} onClick={(e) => handleAction(e, option)}>
                                            <Body2>{option}</Body2>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Grid>
                </GridItemRow>

                {/* Order Type + Delivery Type */}
                {FTLStopHelpers.isCustomerStop(stop, route) ? (
                    <>
                        <GridItemRow>
                            <Body2>
                                {isExchangeDropoff ? 'Exchange' : isReturnPickup ? 'Return Pickup' : 'Delivery'}{' '}
                                <span css={greyText}>
                                    -{' '}
                                    {orderLocationTypes
                                        .map((location) => LOCATION_TYPES[location] || location)
                                        .join(', ')}
                                </span>
                            </Body2>
                        </GridItemRow>
                        <GridItemRow>
                            {/* <Body2 css={greyText}>
                                {orderLocationTypes.map((location) => LOCATION_TYPES[location] || location).join(', ')}
                            </Body2> */}
                        </GridItemRow>
                    </>
                ) : null}

                {stop.stop_completion_time ? (
                    <GridItemRow>
                        <Body2
                            css={css`
                                color: ${colors.greens.primary};
                                font-weight: 700;
                            `}
                        >
                            Completed: {format(new Date(stop.stop_completion_time), 'EEE, MMM d @ hh:mm a')}
                        </Body2>
                    </GridItemRow>
                ) : null}

                {/* Driving Time */}
                {!isNormalPickup && !isLunchStop && stop.driving_time && (
                    <GridItemRow>
                        <Body2>
                            {`Driving Time: ${
                                stop.driving_time >= 3600000
                                    ? hourStringShort.format(Math.floor(stop.driving_time / 3600000))
                                    : ''
                            } ${minuteString.format((stop.driving_time % 3600000) / 60000)}`}
                        </Body2>
                    </GridItemRow>
                )}

                {!isLunchStop && (
                    <GridItemRow>
                        <Body2>{stop.address}</Body2>
                    </GridItemRow>
                )}

                {/* Arrival Time */}
                {!isLunchStop && (stop.stop_start_time || stop.del_window_start) && (
                    <GridItemRow>
                        <Body2>
                            {`Arrival Time: ${dateToFormattedDate(
                                stop.stop_start_time || stop.del_window_start,
                                stopOrders[0]?.pickup_zip
                            )}`}
                        </Body2>
                    </GridItemRow>
                )}

                {/* Load / Service Time */}
                {!(stop.overnight && isNormalPickup) && (
                    <GridItemRow>
                        <Body2>{serviceTimeText}</Body2>
                        {!isLocked && !isLunchStop && (
                            <Grid
                                item
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <IconButton
                                    onClick={() =>
                                        modifyServiceTime(preferences_service_time_incr * -60000, stop, route)
                                    }
                                    css={css`
                                        padding: 0;
                                    `}
                                >
                                    <RemoveCircle css={circleStyle} />
                                </IconButton>
                                <Body1
                                    css={css`
                                        padding: 0 0.25rem;
                                    `}
                                >
                                    {minuteString.format(preferences_service_time_incr)}
                                </Body1>
                                <IconButton
                                    onClick={() =>
                                        modifyServiceTime(preferences_service_time_incr * 60000, stop, route)
                                    }
                                    css={css`
                                        padding: 0;
                                    `}
                                >
                                    <AddCircle css={circleStyle} />
                                </IconButton>
                            </Grid>
                        )}
                    </GridItemRow>
                )}

                {/* Dispatch / End Time */}
                {!isLunchStop && (stop.stop_start_time || stop.del_window_start) && (
                    <GridItemRow>
                        <Body2>
                            {`${isNormalPickup ? 'Dispatch' : 'End'} Time:
                                ${dateToFormattedDate(
                                    new Date(
                                        new Date(stop.stop_start_time || stop.del_window_start).getTime() + serviceTime
                                    ),
                                    stopOrders[0]?.pickup_zip
                                )}
                            `}
                        </Body2>
                    </GridItemRow>
                )}

                {/* Items / Cubes / Pounds */}
                {!isLunchStop && (
                    <GridItemRow>
                        <Body2>
                            {[
                                `${integerFormatter.format(totalItems)} items`,
                                `${integerFormatter.format(totalVolume)} cu ft.`,
                                poundFormatter.format(totalWeight),
                            ].join(' / ')}
                            <ItemTooltip
                                title={
                                    <>
                                        <Typography
                                            css={css`
                                                font-size: 14 !important;
                                                font-weight: 600 !important;
                                            `}
                                            color="inherit"
                                        >
                                            Item Information
                                        </Typography>
                                        {stopOrders &&
                                            stopOrders?.map((order) =>
                                                order.itemsByOrderId.length ? (
                                                    order.itemsByOrderId.map((item) => (
                                                        <React.Fragment key={item.item_id}>
                                                            {item.quantity} x {item.description || 'No description'}
                                                            <br />
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <React.Fragment>
                                                        No items found <br />
                                                    </React.Fragment>
                                                )
                                            )}
                                    </>
                                }
                            >
                                <InfoIcon css={editiconStyle} fontSize="small" />
                            </ItemTooltip>

                            {window.innerWidth > 1599 ? (
                                <Body2
                                    style={{
                                        color: truckCubes > truckCapacity ? 'red' : 'grey',
                                        fontSize: 12,
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {' '}
                                    (Cubes on Truck: {integerFormatter.format(truckCubes)})
                                </Body2>
                            ) : (
                                <>
                                    <br />
                                    <Body2
                                        style={{
                                            color: truckCubes > truckCapacity ? 'red' : 'grey',
                                            fontSize: 12,
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {' '}
                                        (Cubes on Truck: {integerFormatter.format(truckCubes)})
                                    </Body2>
                                </>
                            )}
                        </Body2>
                    </GridItemRow>
                )}

                {/* Timeframe */}
                {!isNormalPickup && stop.del_window_start && stop.del_window_end && (
                    <GridItemRow>
                        <Body2>
                            {'Time Frame: '}
                            {delWindowToFormattedDateRange(
                                stop.del_window_start,
                                stop.del_window_end,
                                stopOrders[0]?.dropoff_zip
                            )}
                            {status && FTLStopHelpers.isCustomerStop(stop, route) && (
                                <ConfirmationText status={status}> ({status})</ConfirmationText>
                            )}
                            {!isLocked && circles['custom-timeframes-beta'] && (
                                <IconButton
                                    onClick={editTimeframe}
                                    css={css`
                                        padding: 0 !important;
                                    `}
                                >
                                    <Edit css={editiconStyle} fontSize="small" />
                                </IconButton>
                            )}
                        </Body2>
                    </GridItemRow>
                )}

                {(stop.type === 'LUNCH' || FTLStopHelpers.isCustomerStop(stop, route)) &&
                    circles?.['plan-page-mapbox'] && (
                        <GridItemRow>
                            <Body2
                                css={css`
                                    color: ${colors.greys.primary};
                                    cursor: pointer;
                                    text-decoration: underline;
                                `}
                                onClick={() => {
                                    setModalOpen(MODALS.STOP_WINDOW);
                                    setActionableRoute(route);
                                    setActionableStop(stop);
                                }}
                            >
                                {'Optimized Window: '}
                                {stop.earliest_start && stop.latest_end
                                    ? delWindowToFormattedDateRange(
                                          stop.earliest_start,
                                          stop.latest_end,
                                          stopOrders?.[0]?.dropoff_zip
                                      )
                                    : stop.type === 'LUNCH'
                                    ? 'NOT SET'
                                    : 'No Preference'}
                            </Body2>
                        </GridItemRow>
                    )}

                {/* Order Numbers */}
                {!isNormalPickup && (
                    <GridItemRow
                        css={css`
                            justify-content: flex-start;
                            flex-wrap: wrap;
                        `}
                    >
                        {stopOrders.map((order, idx) => (
                            <React.Fragment key={order.order_id}>
                                <Body2
                                    css={css`
                                        color: ${colors.greens.primary};
                                        text-decoration: underline;
                                        cursor: pointer;
                                        font-weight: 600;
                                    `}
                                    onClick={() => {
                                        setModalOpen(MODALS.ORDER_VIEW);
                                        setActionableOrder(order);
                                    }}
                                >
                                    {order.order_number}
                                </Body2>
                                {!allCanceled && cancelMap[order.order_number] && (
                                    <Body2
                                        css={css`
                                            color: ${colors.reds.primary};
                                        `}
                                    >
                                        &nbsp;(Cancelled)
                                    </Body2>
                                )}
                                {idx < stopOrders.length - 1 && <Body2 css={greyText}>,&nbsp;</Body2>}
                            </React.Fragment>
                        ))}
                    </GridItemRow>
                )}

                {/* PO Numbers */}
                {poNumbers.length > 0 && (
                    <GridItemRow>
                        <Body2>{poNumbers.join(', ')}</Body2>
                    </GridItemRow>
                )}

                {isFinalDropoff ? (
                    <GridItemRow
                        css={css`
                            justify-content: flex-start;
                        `}
                    >
                        <OnwardSwitch
                            label={
                                <span
                                    css={css`
                                        font-size: 14px;
                                        font-weight: 500;
                                    `}
                                >
                                    Next Day Return
                                </span>
                            }
                            checked={route?.finish_returns_next_day}
                            onChange={(e) =>
                                updateRoute({
                                    variables: {
                                        route_id: route.route_id,
                                        update: {
                                            finish_returns_next_day: !route.finish_returns_next_day,
                                            need_to_optimize: true,
                                        },
                                    },
                                })
                            }
                            disabled={stopsLoading || updateRouteLoading || isLocked}
                        />
                        <ItemTooltip
                            title={
                                <>
                                    <Typography
                                        css={css`
                                            font-size: 14px !important;
                                            font-weight: 500 !important;
                                            line-height: 17px;
                                        `}
                                        color="inherit"
                                    >
                                        Final return will be scheduled for the next day. This will move the end location
                                        prior to the final return and be the starting point to finish the return the
                                        following day.
                                    </Typography>
                                </>
                            }
                            placement="top"
                            arrow
                            css={css`
                                .MuiTooltip-arrow {
                                    // color: #f5f5f9;
                                }
                            `}
                        >
                            <InfoOutlinedIcon
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 5px !important;
                                `}
                                style={{ fontSize: 20 }}
                            />
                        </ItemTooltip>
                    </GridItemRow>
                ) : null}

                {circles?.['overnight-routes'] && isNormalPickup && (
                    <GridItemRow
                        css={css`
                            justify-content: flex-start;
                        `}
                    >
                        <OnwardSwitch
                            label={
                                <span
                                    css={css`
                                        font-size: 14px;
                                        font-weight: 500;
                                    `}
                                >
                                    Overnight Stop
                                </span>
                            }
                            checked={stop.overnight}
                            onChange={(e) => setOvernight(stop.stop_id, !stop.overnight)}
                            disabled={stopsLoading}
                        />
                        <ItemTooltip
                            title={
                                <>
                                    <Typography
                                        css={css`
                                            font-size: 14px !important;
                                            font-weight: 500 !important;
                                            line-height: 17px;
                                        `}
                                        color="inherit"
                                    >
                                        This pickup stop will be loaded the day before delivery. This stop will be
                                        skipped for navigation and optimization. Enter an overnight location as Start
                                        Location for correct driver navigation.
                                    </Typography>
                                </>
                            }
                            placement="top"
                            arrow
                            css={css`
                                .MuiTooltip-arrow {
                                    // color: #f5f5f9;
                                }
                            `}
                        >
                            <InfoOutlinedIcon
                                css={css`
                                    color: ${colors.greens.primary};
                                    margin-left: 5px !important;
                                `}
                                style={{ fontSize: 20 }}
                            />
                        </ItemTooltip>
                    </GridItemRow>
                )}
            </Grid>
        </PlanningListItem>
    );
};

export default StopListItem;
