import React, { useContext, useMemo, useState, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { SectionSubtitle } from '@/styles/blocks';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { Body1, Incrementor, OnwardCheckbox, TextField } from '@/components/ShipmentForm/blocks';
import { ModalContext } from '../';
import { SectionSubtitle2, GreyBody } from '@/styles/blocks';
import { useClientUser, useOrderNotes } from '@/hooks';
import { capitalize } from 'lodash';
import { UserContext } from '@/components/App';
import { greys } from '@/styles/colors';
import { colors } from '@/styles';
import { PrimaryButton } from '@/styles/blocks';
import { MenuItem, Typography, Grid } from '@material-ui/core';
import { HR } from '@/components/OrderDetailsPage/Notes/Notes';
import { X } from 'react-bootstrap-icons';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const timestamp = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

const Note = (note, isLast, accountTypeCapital, username, email) => {
    // Notes for invoices
    if (note?.invoice_id) {
        return (
            <>
                <Grid container direction="row">
                    <Grid container item direction="column" xs={4}>
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                System
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {dateShort.format(new Date(note.created_at || Date.now()))}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {timestamp.format(new Date(note.created_at || Date.now()))}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            css={css`
                                font-weight: 500;
                                font-style: italic;
                            `}
                        >
                            <a
                                href={`${window.location.origin}/carrier/accounting/marketplace/invoice/${note?.invoice_id}`}
                                target="_blank"
                            >
                                An invoice was added to this order
                            </a>
                        </Typography>
                    </Grid>
                </Grid>

                {!isLast && <HR />}
            </>
        );
    }

    const source_user_type = note?.source_user_type;

    return (
        <>
            <Grid container direction="row">
                <Grid container item direction="column" xs={4}>
                    {note?.private_to === accountTypeCapital && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: orange;
                                    font-weight: 500;
                                `}
                            >
                                Private
                            </Typography>
                        </Grid>
                    )}

                    {note?.requires_acknowledgement && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${colors.greens.primary};
                                    font-weight: 500;
                                `}
                            >
                                Onward/Shipper Notified
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                line-break: anywhere;
                            `}
                        >
                            {source_user_type
                                ? source_user_type === 'Admin'
                                    ? 'Onward'
                                    : `(${source_user_type})`
                                : 'System'}
                        </Typography>
                    </Grid>

                    {source_user_type === 'Admin' && note?.user?.username && (
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                {`(${note?.user?.username})`}
                            </Typography>
                        </Grid>
                    )}

                    {note.source_user_type !== 'Admin' && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                {email}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {dateShort.format(new Date(note.created_at || Date.now()))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {timestamp.format(new Date(note.created_at || Date.now()))}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" item xs={8}>
                    {note?.type && (
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                `}
                            >
                                {note?.type}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                ${note?.is_acknowledgement &&
                                `
                                    font-style: italic;    
                                `}
                            `}
                        >
                            {note?.is_acknowledgement ? `Acknowledged by ${note?.source_user_type}` : note.note}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {!isLast && <HR />}
        </>
    );
};

const initNote = {
    private: false,
    notify: false,
    note: '',
};

const AdminNotes = () => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { user_id, userType, client_id } = useClientUser();
    const { order } = modalState;
    const { username, email } = useContext(UserContext);

    const [noteInfo, setNoteInfo] = useState(initNote);
    const [viewAdminNotes, setViewAdminNotes] = useState(false);

    const handleAddNote = () => {
        callbacks.addNote({
            note: noteInfo.note,
            source_user_type: 'Admin',
            source_user_id: user_id,
            is_acknowledgement: false,
            requires_acknowledgement: false,
            acknowledged_by_shipper: false,
            acknowledged_by_admin: false,
            private_to: 'Admin',
            type: null,
            order_id: order.order_id,
        });
        setNoteInfo({
            ...initNote,
        });
    };

    const orderNotes = useOrderNotes([order], 'Admin');

    const notesForViewing = useMemo(() => {
        if (!viewAdminNotes) return (orderNotes?.all || []).sort((a, b) => new Date(b.created_at || Date.now()) - new Date(a.created_at || Date.now()));

        return (orderNotes?.all || []).filter((note) => note.private_to === 'Admin').sort((a, b) => new Date(b.created_at || Date.now()) - new Date(a.created_at || Date.now()));
    }, [orderNotes, viewAdminNotes, order]);

    return (
        <Grid container direction="column">
            <Grid item>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={noteInfo.note || ''}
                    onChange={(e) =>
                        setNoteInfo((prevState) => ({
                            ...prevState,
                            note: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                    css={css`
                        textarea {
                            font-weight: 500;
                        }
                    `}
                />
            </Grid>

            <Grid
                item
                css={css`
                    margin-top: 20px;
                `}
            >
                <PrimaryButton disabled={!noteInfo.note} onClick={handleAddNote} >
                    Add Note
                </PrimaryButton>
            </Grid>

            {!!orderNotes?.all?.length && (
                <Grid item css={css`
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                `}>
                    <OnwardCheckbox
                        label="View only Onward Admin notes"
                        checked={viewAdminNotes}
                        onChange={() =>
                            setViewAdminNotes(!viewAdminNotes)
                        }
                        labelPlacement="end"
                    />
                </Grid>
            )}

            {!notesForViewing?.length ? (
                <Grid item>
                    <Typography>No {viewAdminNotes ? 'admin ' : ''}notes</Typography>
                </Grid>
            ) : (
                notesForViewing.map((note, idx, arr) =>
                    Note(note, arr.length - 1 === idx, capitalize(userType), username, email)
                )
            )}
        </Grid>
    );
};

export default AdminNotes;
