import { gql } from '@apollo/client';

import { INTEGRATION_ERROR_FIELDS } from '@/graphql/fragments/integration_errors';
import { INTEGRATION_FIELDS } from '@/graphql/fragments/integrations';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const LIST_FAILED_INTEGRATIONS = gql`
    ${INTEGRATION_ERROR_FIELDS}
    ${INTEGRATION_FIELDS}
    ${CLIENT_FIELDS}

    query ListFailedIntegrations($where: integration_errors_bool_exp!) {
        results: integration_errors(where: $where, order_by: [{ created_at: desc }], limit: 50) {
            ...IntegrationErrorFields

            override {
                shipper {
                    ...ClientFields
                }

                carrier {
                    ...ClientFields
                }

                type
            }

            integration {
                ...IntegrationFields
            }
        }
    }
`;
