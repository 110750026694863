import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { addDays, getWeek, setWeek, startOfWeek, endOfWeek } from 'date-fns';

import { genAccessorials } from '@/components/Account/Tariffs/utils';
import { useClientUser } from '@/hooks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';

import { CARRIER_INVOICES, SET_INVOICE_STATUS, GET_CARRIERS, SET_INVOICE_FLAG } from './graphql';
import { QUICKBOOKS_CSV_COLUMNS } from './columns';
import { useColumns } from './columns';
import { genCallback } from './hooks';
import { FILTERS } from './constants';
import { set } from 'lodash';

export const Context = createContext();

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });
const lastWeek = setWeek(TODAY, thisWeek - 1, { weekStartsOn: 1 });
const sow = startOfWeek(lastWeek, { weekStartsOn: 1 });

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const [hasMore, setHasMore] = useState(false);
    const [orderBy, setOrderBy] = useState({ created_at: 'desc_nulls_last' });
    const [selectedMap, setSelected] = useState({});
    const [filter, setFilter] = useState({
        status: FILTERS.ALL,
        carriers: [],
    });

    const SEARCHABLE = ['description'];

    const [getInvoices, { data, loading: initInflight, fetchMore }] = useLazyQuery(CARRIER_INVOICES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [getCarriers, { data: carrierData }] = useLazyQuery(GET_CARRIERS, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [setInvoiceStatus, { loading: invoiceStatusInflight }] = useMutation(SET_INVOICE_STATUS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [setInvoiceFlag] = useMutation(SET_INVOICE_FLAG, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [fetchOverrides, { loading: exportInflight }] = useLazyQuery(GET_PRICING_OVERRIDES);

    const carriers = useMemo(() => {
        const carriers =
            (carrierData?.results || [])
                .map((invoice) => {
                    return {
                        label: invoice?.client?.business_name,
                        value: invoice.client_id,
                    };
                })
                .sort((l, r) => l.label?.localeCompare(r.label)) || [];

        return carriers;
    }, [carrierData]);

    useEffect(() => {
        switch (filter.status) {
            case FILTERS.ALL:
                break;
            case FILTERS.PAID:
                break;
            case FILTERS.SUBMITTED:
                setOrderBy({ created_at: 'asc_nulls_last' });
                break;
            case FILTERS.APPROVED:
                setOrderBy({ created_at: 'asc_nulls_last' });
                break;
        }
    }, [filter.status]);

    const where = useMemo(() => {
        let conditions = [{ type: { _eq: 'PAYABLE' } }, { client: { test_acc: { _eq: false } } }];
        switch (filter.status) {
            case FILTERS.ALL:
                break;
            case FILTERS.SUBMITTED:
                conditions.push({ status: { _nin: ['PAID', 'APPROVED'] } });
                break;
            case FILTERS.APPROVED:
                conditions.push({ status: { _eq: 'APPROVED' } });
                break;
            case FILTERS.PAID:
                conditions.push({ status: { _eq: 'PAID' } });
                break;
        }

        if (filter?.week?.week_number) {
            conditions.push({ week_number: { _eq: filter?.week?.week_number } });
        }

        if (filter?.carriers?.length > 0) {
            conditions.push({ client_id: { _in: filter?.carriers } });
        }

        if (filter?.searchTerm?.length > 0) {
            const orConditions = [
                { orders: { order_number: { _ilike: `%${filter?.searchTerm}%` } } },
                { orders: { po_number: { _ilike: `%${filter?.searchTerm}%` } } },
            ];

            const parsedInt = parseInt(filter?.searchTerm, 10);
            if (!isNaN(parsedInt) && Number.isInteger(parsedInt)) {
                orConditions.push({
                    invoice_number: { _eq: parsedInt },
                });
            }

            conditions.push({
                _or: orConditions,
            });
        }

        return conditions;
    }, [filter]);

    const invoices = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    const invoicesMap = useMemo(() => {
        return Object.fromEntries(invoices.map((invoice) => [invoice.carrier_invoice_id, invoice]));
    }, [invoices]);

    const selectedObj = useMemo(() => {
        return selected.map((id) => invoicesMap[id]);
    }, [selected, invoicesMap]);

    const getInvoicesDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getInvoices(payload);
            }, 500),
        []
    );

    const filtered = useMemo(() => {
        return invoices.filter((invoice) => {
            let status = false;
            switch (filter.status) {
                case FILTERS.ALL:
                    status = true;
                    break;
                case FILTERS.SUBMITTED:
                    status = invoice.status === 'UNPAID';
                    break;
                case FILTERS.APPROVED:
                    status = invoice.status === 'APPROVED';
                    break;
                case FILTERS.PAID:
                    status = invoice.status === 'PAID';
                    break;
            }

            return status && (!filter?.week || invoice.week_number === filter?.week?.week_number);
        });
    }, [invoices, filter]);

    const weeks = useMemo(() => {
        const startDate = new Date('2024-07-01');
        const weeksSinceStart = Math.floor((TODAY - startDate) / (7 * 24 * 60 * 60 * 1000));

        return Array(weeksSinceStart)
            .fill(0)
            .map((_, idx) => idx + 1)
            .map((mod) => {
                const day = addDays(TODAY, -1 * mod * 7);
                const week = setWeek(day, -1 * mod + thisWeek, { weekStartsOn: 1 });
                const sow = startOfWeek(week, { weekStartsOn: 1 });
                const eow = endOfWeek(week, { weekStartsOn: 1 });

                return {
                    sow,
                    eow,
                    week_number: -1 * mod + thisWeek,
                    label: `${dateNumeric.format(sow)} - ${dateNumeric.format(eow)} (${-1 * mod + thisWeek})`,
                };
            });
    }, []);

    useEffect(() => {
        getInvoices({
            variables: {
                order_by: orderBy,
                where: {
                    _and: where,
                },
            },
        });

        getCarriers({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getInvoicesDebounced({
                variables: {
                    order_by: orderBy,
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getInvoicesDebounced, initInflight]);

    const cursor = useMemo(() => {
        if (invoices.length === 0) {
            return null;
        }

        return invoices[invoices.length - 1].created_at;
    }, [invoices]);

    const loadMore = useCallback(() => {
        const orderCondition = orderBy.created_at === 'asc_nulls_last' ? { _lte: cursor } : { _gte: cursor };
        fetchMore({
            variables: {
                where: {
                    _and: [...where, { created_at: orderCondition }],
                },
            },
            updateQuery: (data, { fetchMoreResult }) => {
                if (!data.results) {
                    return fetchMoreResult;
                }
                const prev = Object.fromEntries(
                    (data.results || []).map((invoice) => [invoice.carrier_invoice_id, true])
                );
                const clone = [
                    ...(data.results || []),
                    ...(fetchMoreResult.results || []).filter((invoice) => !prev[invoice.carrier_invoice_id]),
                ];
                return {
                    results: clone,
                };
            },
        }).then((result) => {
            if (result.data.results.length < 100) {
                setHasMore(false);
            }
        });
    }, [where, cursor, fetchMore]);

    const callbacks = {
        exportCsv: () => {
            fetchOverrides({
                variables: {
                    shipper_ids: selectedObj.reduce((acc, invoice) => {
                        return [...acc, ...invoice.orders.map((order) => order.shipper_id)];
                    }, []),
                    carrier_ids: selectedObj.reduce((acc, invoice) => {
                        return [...acc, ...invoice.orders.map((order) => order.carrier_id)];
                    }, []),
                    client_ids: [],
                    partner_client_ids: [],
                },
            }).then(({ data: { carrier_defaults, carrier } }) => {
                let types = [...(carrier_defaults || []), ...(carrier || [])].map((override) => override.algo_type);
                if (types.length === 0) {
                    types = ['DEFAULT'];
                }

                const accessorials = types.reduce((acc, type) => {
                    return [...acc, ...genAccessorials(type)];
                }, []);

                return genCallback({
                    accessorials,
                    invoices: selectedObj,
                    breakdown: 'carrierBreakdown',
                    columns: QUICKBOOKS_CSV_COLUMNS,
                })();
            });
        },
        setFilter,
        loadMore,
        onRowClick: (invoice) => {
            return navigate(`/carrier/accounting/marketplace/invoice/${invoice.carrier_invoice_id}`);
        },
        getRowId: (invoice) => {
            return invoice.carrier_invoice_id;
        },
        setPaid: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'PAID',
                },
            });
        },
        setApproved: () => {
            setInvoiceStatus({
                variables: {
                    ids: selected,
                    status: 'APPROVED',
                },
            });
        },
        payId: (id) => {
            setInvoiceStatus({
                variables: {
                    ids: [id],
                    status: 'PAID',
                },
            });
        },
        flagRowToggle: (invoice) => {
            setInvoiceFlag({
                variables: {
                    ids: [invoice.carrier_invoice_id],
                    flag: !invoice.flagged,
                },
            });
        },
        selectRows: setSelected,
        hasMore,
    };

    const COLUMNS = useColumns({ callbacks });

    return (
        <Context.Provider
            value={{
                state: {
                    weeks,
                    hasMore,
                    carriers,
                    selected,
                    invoices: filtered,
                    filter,
                    columns: COLUMNS,
                },
                loading: {
                    export: exportInflight,
                    init: initInflight,
                    paid: invoiceStatusInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
