import React, { useEffect, createContext, useState, useMemo, useRef } from 'react';
import { useParams, useLocation } from 'react-router';
import { QUERY_BY_ID } from '@/graphql/queries/orders';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { useClientUser, useOrderCarrierPricing } from '@/hooks';
import { READY_TO_INVOICE } from '../CarrierAccountingOrders/graphql/mutations';
import { useAccessorials } from '@/components/Account/Tariffs/utils';
import { FIXED_CHARGES } from './constants';
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import { calcOrderInvoiceTotals } from '@/utilities/calcOrderInvoiceTotals';
export const Context = createContext();

const DEFAULT_CHARGE = {
    type: 'Custom',
    rate: 0,
    quantity: 1,
    total: 0,
};

export const ContextProvider = ({ children }) => {
    const { order_id, perspective } = useParams();
    const [notification, setNotification] = useState({});

    const [updates, setUpdates] = useState({});
    const [orderQboTag, setOrderQboTag] = useState('');
    const [newComment, setNewComment] = useState('');
    const { client_id, roles, teammateRoles, isImposter, username, tags } = useClientUser();

    const invoicingAccess = useMemo(() => {
        return roles['ONWARD_ADMIN'] || teammateRoles?.invoicing || isImposter ? true : false;
    }, [roles, teammateRoles, isImposter]);

    const { data, loading: orderInflight } = useQuery(QUERY_BY_ID, {
        variables: {
            order_id: order_id,
        },
        onError: (err) => {
            console.log(err);
            Sentry.captureException(err);
        },
    });

    const order = useMemo(() => {
        const _order = data?.orders?.[0] || {};
        if (_order?.order_revenue_adjustments?.[perspective]?.length) {
            setUpdates((prev) => {
                return {
                    ...prev,
                    order_revenue_adjustments: [..._order.order_revenue_adjustments[perspective]],
                };
            });
        }

        return _order;
    }, [data]);

    useEffect(() => {
        if (order) {
            setOrderQboTag(order.qbo_tag || '');
        }
    }, [order]);

    const isInternal = useMemo(() => {
        return order?.oms === true || roles['ONWARD_ADMIN'];
    }, [order]);

    const [fetchOverrides, { data: resp }] = useLazyQuery(GET_PRICING_OVERRIDES);

    useEffect(() => {
        if (order) {
            const client_id = order.carrier_id || order.shipper_id;
            const partner_client_id = order.oms && order.carrier_id !== order.shipper_id ? order.shipper_id : null;
            fetchOverrides({
                variables: {
                    shipper_ids: [order.shipper_id],
                    carrier_ids: order.carrier_id ? [order.carrier_id] : [],
                    client_ids: [client_id],
                    partner_client_ids: partner_client_id ? [partner_client_id] : [],
                },
            });
        }
    }, [order]);

    const [overrideType, rates] = useMemo(() => {
        if (!order || !resp) {
            return ['DEFAULT', {}];
        }

        const { shipper, internal, carrier, shipper_defaults, carrier_defaults } = resp;
        let overrides = shipper[0];
        let defaults = shipper_defaults[0];

        if (order.oms && internal?.[0]) {
            overrides = internal[0];
            defaults = {};
        }

        if (order?.carrier_id === client_id && !!carrier?.[0]) {
            overrides = carrier[0];
            defaults = carrier_defaults[0];
        }

        const rates = overrides?.tariff?.rates || [];
        const defaultRates = defaults?.tariff?.rates || [];
        const grouped = (rates || []).reduce((acc, rate) => {
            const next = [...(acc[rate.type] || []), rate];
            acc[rate.type] = next.sort((l, r) => l.min - r.min);

            return acc;
        }, {});

        const defaultGrouped = defaultRates.reduce((acc, rate) => {
            const next = [...(acc[rate.type] || []), rate];
            acc[rate.type] = next.sort((l, r) => l.min - r.min);

            return acc;
        }, {});

        return [
            overrides ? overrides?.algo_type : 'DEFAULT',
            {
                ...defaultGrouped,
                ...grouped,
            },
        ];
    }, [order, resp, client_id]);

    const breakdown = useMemo(() => {
        switch (perspective) {
            case 'internal':
                return order?.price_breakdown?.internalBreakdown;
            case 'carrier':
                return order?.price_breakdown?.carrierBreakdown;
            case 'shipper':
                return order?.price_breakdown?.shipperBreakdown;
        }
        return undefined;
    }, [order, perspective]);

    const accessorials = useAccessorials(overrideType, perspective === 'internal' ? tags : []);

    const filteredAccessorials = useMemo(() => {
        if (isInternal) {
            return accessorials;
        }

        return accessorials.filter((accessorial) => !!rates[accessorial.type]);
    }, [isInternal, accessorials, rates]);

    const updated = useMemo(() => {
        const fromBreakdown = (breakdown?.accessorials || []).map(({ type, rate, quantity }) => {
            return {
                type,
                rate,
                quantity,
                total: rate * quantity,
            };
        });

        return {
            ...order,
            ...updates,
            invoice_notes: order?.invoice_notes || [],
            order_revenue_adjustments: {
                ...(order?.order_revenue_adjustments || {}),
                [perspective]: updates?.order_revenue_adjustments || fromBreakdown || [],
            },
        };
    }, [updates, order, breakdown]);

    const [readyToInvoice, { loading: invoiceLoading }] = useMutation(READY_TO_INVOICE, {
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const [updateOrder, { loading: updateLoading }] = useMutation(UPDATE_ORDER_BY_ID, {
        update(cache, { data }) {},
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const subtotal = useMemo(() => {
        return calcOrderSubtotal(updated);
    }, [updated]);

    const accessorialSubtotal = useMemo(() => {
        return (updated?.order_revenue_adjustments?.[perspective] || []).reduce((acc, charge) => {
            return acc + charge.quantity * charge.rate;
        }, 0);
    }, [updated, perspective]);

    const total = useMemo(() => {
        return calcOrderInvoiceTotals(updated);
    }, [updates, updated]);

    return (
        <Context.Provider
            value={{
                state: {
                    invoicingAccess,
                    isInternal,
                    editable:
                        roles['ONWARD_ADMIN'] ||
                        perspective === 'internal' ||
                        (perspective === 'carrier' && !order.carrier_invoice_id) ||
                        (perspective === 'shipper' && !order.shipper_invoice_id),
                    rates,
                    updated,
                    perspective,
                    accessorials: filteredAccessorials,
                    notification,
                    breakdown,
                    subtotal,
                    accessorialSubtotal,
                    total,
                    comment: newComment,
                    orderQboTag,
                },
                loading: {
                    initializing: orderInflight,
                    updatingOrder: updateLoading,
                    invoice: invoiceLoading,
                },
                callbacks: {
                    setNewComment,
                    updateQboTag: (newTag) => setOrderQboTag(newTag),
                    removeCharge: ({ idx }) => {
                        setUpdates((prev) => {
                            const clone = [...(updated?.order_revenue_adjustments?.[perspective] || [])];
                            clone.splice(idx, 1);

                            return {
                                ...prev,
                                order_revenue_adjustments: clone,
                            };
                        });
                    },
                    editCharge: ({ idx, charge }) => {
                        const clone = [...(updated?.order_revenue_adjustments?.[perspective] || [])];
                        clone.splice(idx, 1, charge);

                        setUpdates((prev) => {
                            return {
                                ...prev,
                                order_revenue_adjustments: clone,
                            };
                        });
                    },
                    addCharge: () => {
                        setUpdates((prev) => {
                            return {
                                ...prev,
                                order_revenue_adjustments: [
                                    ...(updated?.order_revenue_adjustments?.[perspective] || []),
                                    DEFAULT_CHARGE,
                                ],
                            };
                        });
                    },
                    handleReadyToInvoice: () => {
                        readyToInvoice({
                            variables: {
                                order_ids: [updated.order_id],
                            },
                        });
                    },
                    handleSaveQBOClass: (order_id, invoice_class) => {
                        updateOrder({
                            variables: {
                                order_id: order_id,
                                update: {
                                    invoice_class: invoice_class,
                                },
                            },
                        });
                    },
                    saveOrder: () => {
                        let toUpdate = {
                            shipper_rate: total,
                        };
                        if (isInternal) {
                            toUpdate = {
                                order_revenue: total,
                            };
                        }

                        if (order?.carrier_id === client_id) {
                            toUpdate = {
                                carrier_rate: total,
                            };
                        }

                        updateOrder({
                            variables: {
                                order_id: order_id,
                                update: {
                                    order_revenue_adjustments: updated.order_revenue_adjustments,
                                    ...toUpdate,
                                    qbo_tag: orderQboTag,
                                },
                            },
                        });
                    },
                    saveComments: () => {
                        updateOrder({
                            variables: {
                                order_id: order_id,
                                update: {
                                    invoice_notes: [
                                        ...updated.invoice_notes,
                                        {
                                            comment: newComment,
                                            created_by: username,
                                            created_at: new Date().toISOString(),
                                        },
                                    ],
                                },
                            },
                        }).then(() => {
                            setNewComment('');
                        });
                    },
                    setNotification,
                    clearNotification: () => {
                        setNotification({});
                    },
                },
                errors: {},
            }}
        >
            {children}
        </Context.Provider>
    );
};
