import withAdminRights from '@/components/Auth/withAdminRights';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useTabs } from './constants';
import STATES from '@/constants/states';
import { ADMIN_ORDERS, CLIENTS } from './graphql';
import { captureException } from '@sentry/react';
import { useMutation, useQuery } from '@apollo/client';
import useAction from '@/utilities/useQuery';
import { PrimaryButton } from '@/styles/blocks';
import { Grid, FormControlLabel, InputAdornment, LinearProgress, Snackbar, Switch, TextField } from '@material-ui/core';
import { css } from '@emotion/react';
import { Container, OuterContainer, Row, Title } from './blocks';
import { Search } from '@material-ui/icons';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import FilterPopover from './FilterPopover';
import OrdersTable from './OrdersTable';
import { Alert, Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { asUTCDate } from '@/utilities/convertToISO';
import { addDays, addYears } from 'date-fns';
import EditOrderModal from '@/components/ShipmentForm/modals/EditOrderModal';
import { exportCsv } from './queries/exportCsv';
import { isObject } from 'lodash';
import { UPSERT_ORDERS } from '@/components/ShipmentForm/graphql/mutations';
import OrderPODModal from '@/components/CarrierAccountingOrders/modals/OrderPODModal';
import { EDIT_ORDER_TABS } from '@/components/ShipmentForm/constants';
import useShippersAndCarriers from '@/hooks/useShippersAndCarriers';
import { SEARCHABLE } from './constants';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import { MILESTONE_OPTIONS } from '@onward-delivery/core';

const AdminOrders = () => {
    const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

    // Misc State
    const [notification, setNotification] = useState({});
    const [editingOrder, editOrder] = useState(null);
    const [podOrder, viewOrderPod] = useState(null);

    const TABS = useTabs({ page: 'admin' }, { viewOrderPod });
    useEffect(() => {
        setTab(
            Math.max(
                TABS.findIndex((t) => t.value === 'ALL_ACTIVE'),
                0
            )
        );
    }, [TABS]);

    // Basic filters
    const [tab, setTab] = useState(0);
    const [search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncer, setDebouncer] = useState(null);
    const [test, setTest] = useState(false);

    // Advanced filters
    const [shipperIds, setShipperIds] = useState([]);
    const [carrierIds, setCarrierIds] = useState([]);
    const [states, setStates] = useState([]);
    const [deliveryTypes, setDeliveryTypes] = useState([]);
    const [startDate, setStartDate] = useState(new Date(addYears(TODAY, -1)).toISOString());
    const [endDate, setEndDate] = useState(null);
    const [completedStart, setCompletedStart] = useState(null);
    const [completedEnd, setCompletedEnd] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [marketplace, setMarketplace] = useState(true);

    const [sortBy, setSortBy] = useState({ id: 'created_at', desc: true });
    const [middleMileStatus, setMiddleMileStatus] = useState(null);
    const [warehouseStatus, setWarehouseStatus] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [schedDelStart, setSchedDelStart] = useState(null);
    const [schedDelEnd, setSchedDelEnd] = useState(null);
    const [estShipStart, setEstShipStart] = useState(null);
    const [estShipEnd, setEstShipEnd] = useState(null);
    const [actualReceivedStart, setActualReceivedStart] = useState(null);
    const [actualReceivedEnd, setActualReceivedEnd] = useState(null);
    const [warehouseStartDate, setWarehouseStartDate] = useState(null);
    const [warehouseEndDate, setWarehouseEndDate] = useState(null);
    const [milestone, setMilestone] = useState(null);

    const addOneDay = (date) => {
        const alteredDate = add(new Date(date), { days: 2 });
        return formatISO(alteredDate, { representation: 'date' });
    };

    const [retrieveCsv, { loading: csvInflight }] = useAction(exportCsv, {
        onError: (err) => {
            captureException(err);
        },
    });

    // Build where gql
    const where = useMemo(() => {
        let filters = [...TABS[tab].filters];

        if (milestone) {
            const milestoneFilter = MILESTONE_OPTIONS.find((o) => o.value === milestone)?.filter;
            if (milestoneFilter) {
                filters.push(milestoneFilter);
            }
            filters.push(milestoneFilter);
        }

        if (marketplace) {
            filters.push({ oms: { _eq: false } });
        } else {
            filters.push({ oms: { _eq: true } });
        }

        if (searchTerm?.length) {
            filters.push({ _or: SEARCHABLE.map((field) => ({ [field]: { _ilike: `%${searchTerm}%` } })) });
        }
        if (test) {
            filters.push({ order_shipper: { test_acc: { _eq: true } } });
        } else {
            filters.push({
                _or: [
                    { order_shipper: { test_acc: { _is_null: true } } },
                    { order_shipper: { test_acc: { _eq: false } } },
                ],
            });
        }
        if (shipperIds.length > 0) {
            filters.push({ shipper_id: { _in: shipperIds } });
        }
        if (carrierIds.length > 0) {
            filters.push({ carrier_id: { _in: carrierIds } });
        }
        if (startDate) {
            const startCutoff = asUTCDate(startDate);
            filters.push({ created_at: { _gte: startCutoff } });
        }
        if (endDate) {
            const endCutoff = addDays(asUTCDate(endDate), 1);
            filters.push({ created_at: { _lt: endCutoff } });
        }
        if (completedStart) {
            const startCutoff = asUTCDate(completedStart);
            filters.push({ completion_time: { _gte: startCutoff } });
        }
        if (completedEnd) {
            const endCutoff = addDays(asUTCDate(completedEnd), 1);
            filters.push({ completion_time: { _lt: endCutoff } });
        }

        if (schedDelStart) {
            filters.push({ delivery_date: { _gte: schedDelStart } });
        }

        if (schedDelEnd) {
            filters.push({ delivery_date: { _lt: addOneDay(schedDelEnd) } });
        }

        if (estShipStart) {
            filters.push({
                wh_events: {
                    _and: [{ action: { _eq: 'START:RECEIVING' } }, { est_ship_date: { _gte: estShipStart } }],
                },
            });
        }

        if (estShipEnd) {
            filters.push({
                wh_events: {
                    _and: [{ action: { _eq: 'START:RECEIVING' } }, { est_ship_date: { _lte: addOneDay(estShipEnd) } }],
                },
            });
        }

        if (actualReceivedStart) {
            filters.push({
                wh_events: {
                    _and: [{ action: { _eq: 'START:RECEIVING' } }, { received_date: { _gte: actualReceivedStart } }],
                },
            });
        }

        if (actualReceivedEnd) {
            filters.push({
                wh_events: {
                    _and: [{ action: { _eq: 'START:RECEIVING' } }, { received_date: { _gte: actualReceivedEnd } }],
                },
            });
        }

        if (warehouseStartDate) {
            filters.push({
                wh_events: {
                    _and: [{ action: { _eq: 'START:RECEIVING' } }, { est_received_date: { _gte: warehouseStartDate } }],
                },
            });
        }

        if (warehouseEndDate) {
            filters.push({
                wh_events: {
                    _and: [
                        { action: { _eq: 'START:RECEIVING' } },
                        { est_received_date: { _lte: addOneDay(warehouseEndDate) } },
                    ],
                },
            });
        }

        if (middleMileStatus) {
            filters.push({
                middle_mile_integration_status: { _eq: middleMileStatus },
            });
        }

        if (warehouseStatus && warehouseStatus === 'NOT_DELIVERED') {
            filters.push({
                _not: {
                    wh_events: {
                        action: { _eq: 'START:RECEIVING' },
                        status: { _eq: 'RECEIVED' },
                    },
                },
            });
        } else if (warehouseStatus) {
            filters.push({
                wh_events: { _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: warehouseStatus } }] },
            });
        }

        if (orderType) {
            filters.push({ order_type: { _eq: orderType } });
        }

        if (paymentType) {
            if (paymentType === 'Invoice') {
                filters.push({ listing: { payment_intent_id: { _is_null: true } } });
            } else {
                filters.push({ listing: { payment_intent_id: { _is_null: false } } });
            }
        }

        if (states?.length) {
            filters.push({
                _or: ['pickup_state', 'dropoff_state'].map((field) => ({
                    [field]: {
                        _in: [...states, ...states.map((stateCode) => STATES[stateCode])],
                    },
                })),
            });
        }
        if (deliveryTypes?.length) {
            filters.push({
                _or: ['dropoff_location_type', 'pickup_location_type'].map((field) => ({
                    [field]: {
                        _in: deliveryTypes,
                    },
                })),
            });
        }
        return {
            _and: [
                ...filters,
                { created_at: { _gt: '2023-06-01T00:00:00.000Z' } },
                { shipper_id: { _nin: ['ecf513c1-aa84-454d-b5e2-7a216a229244'] } }, // ABC Warehouse & Hawthorne Appliance - prod
            ],
        };
    }, [
        tab,
        searchTerm,
        test,
        shipperIds,
        carrierIds,
        startDate,
        endDate,
        states,
        deliveryTypes,
        completedEnd,
        completedStart,
        paymentType,
        schedDelStart,
        schedDelEnd,
        warehouseEndDate,
        warehouseStartDate,
        estShipStart,
        estShipEnd,
        actualReceivedStart,
        actualReceivedEnd,
        middleMileStatus,
        warehouseStatus,
        orderType,
        marketplace,
        milestone,
    ]);

    const order_by = useMemo(() => {
        const ascOrDesc = sortBy?.desc ? 'desc_nulls_last' : 'asc_nulls_last';
        switch (sortBy?.id) {
            case 'delivery_date':
                return [{ delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_ship_date':
                return [{ warehouse_estimated_ship_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_received_date':
                return [{ warehouse_estimated_delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'received_date':
                return [{ warehouse_actual_received_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'order_number':
                return [{ created_at: ascOrDesc }];
            case 'warehousestatus':
                return [{ warehouse_delivery_status: ascOrDesc }];
            default:
                return [{ delivery_date: 'desc_nulls_last' }, { order_number: 'desc_nulls_last' }];
        }
    }, [sortBy]);

    // Pagination
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);

    // Queries
    const {
        loading: queryLoading,
        data,
        error,
    } = useQuery(ADMIN_ORDERS, {
        variables: {
            where,
            order_by,
            offset: page * pageSize,
            limit: pageSize,
        },
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({
                severity: 'error',
                message: error.message,
            });
        },
    });

    const exportCSV = useCallback(() => {
        return retrieveCsv({ query: where }).then((result) => {
            const TODAY = new Date();
            const blob = new Blob([result.data]);

            const filename = `Orders ${TODAY.getTime()}.csv`;
            const autoclick = document.createElement('a');
            const payload = URL.createObjectURL(blob);
            autoclick.setAttribute('href', payload);
            autoclick.setAttribute('download', filename);
            autoclick.style.visibility = 'hidden';
            document.body.appendChild(autoclick);
            autoclick.click();
            document.body.removeChild(autoclick);
        });
    }, [where, retrieveCsv]);

    const orders = useMemo(() => {
        const orders = data?.orders || [];

        return orders;
    }, [data]);

    const totalCount = useMemo(() => {
        return data?.orders_aggregate?.aggregate?.count || 0;
    }, [data]);

    const [shippers, carriers] = useShippersAndCarriers(test);

    const handleSearch = (event) => {
        const input = event.target.value;
        setSearch(input);
        if (debouncer) {
            clearTimeout(debouncer);
        }
        setDebouncer(
            setTimeout(() => {
                setSearchTerm(input);
            }, 300)
        );
    };

    const handleSortBy = (newSortBy) => {
        if (newSortBy?.id !== sortBy.id || newSortBy.desc !== sortBy.desc) {
            setSortBy(newSortBy);
            setPage(0);
        }
    };

    const [submitOrder, { loading: submissionLoading }] = useMutation(UPSERT_ORDERS, {
        onError: (e) => {
            setNotification({ severity: 'error', message: 'Failed to update order. Please try again.' });
            captureException(e);
        },
        refetchQueries: [ADMIN_ORDERS],
        awaitRefetchQueries: true,
    });

    const onSave = useCallback(
        ({ errors, order: modified, items: itemsModified, itemsRemoved, tags, tagsRemoved, documents }) => {
            if (errors?.hasMissingField?.length) {
                setNotification({
                    severity: 'error',
                    message: `Order has missing fields: ${errors.hasMissingField.join(', ')}.`,
                });
            } else if (errors?.phoneInvalid) {
                setNotification({
                    severity: 'error',
                    message: 'Order has an invalid phone number.',
                });
            } else {
                if (errors?.failedPricing?.length) {
                    let message = `Pricing not calculated for ${modified.order_number}.`;
                    if (errors.failedPricing.includes('miles')) {
                        message = `${message} Cannot find distance between pickup and dropoff locations.`;
                    }
                    if (errors.failedPricing.includes('delivery_type')) {
                        message = `${message} ${
                            modified.order_type === 'return' ? 'Return Pickup' : 'Delivery'
                        } type not set.`;
                    }
                    setNotification({ severity: 'warning', message });
                }

                const {
                    warehouse_estimated_delivery_date,
                    warehouse_estimated_ship_date,
                    completion_date_formatted,
                    billing_completion_date_formatted,
                    notes,
                    ...rest
                } = modified;

                submitOrder({
                    variables: {
                        orders: [
                            {
                                ...rest,
                                notes: {
                                    data: notes,
                                    on_conflict: {
                                        constraint: 'notes_pkey',
                                        update_columns: ['note'],
                                    },
                                },
                                ...(warehouse_estimated_delivery_date || warehouse_estimated_ship_date
                                    ? {
                                          wh_events: {
                                              data: [
                                                  {
                                                      action: 'START:RECEIVING',
                                                      est_received_date: warehouse_estimated_delivery_date,
                                                      est_ship_date: warehouse_estimated_ship_date,
                                                  },
                                              ],
                                          },
                                      }
                                    : {}),
                            },
                        ],
                        items: itemsModified,
                        removals: itemsRemoved,
                        tags,
                        tag_removals: tagsRemoved,
                        documents: documents,
                    },
                });
                editOrder(null);
            }
        },
        []
    );

    const loading = useMemo(() => {
        return queryLoading || submissionLoading;
    }, [queryLoading, submissionLoading]);

    return (
        <OuterContainer>
            <Snackbar
                open={!!notification?.message}
                onClose={() => setNotification({})}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification({})}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                </Alert>
            </Snackbar>
            <OrderPODModal
                order={podOrder}
                callbacks={{
                    onClose: () => {
                        viewOrderPod(null);
                    },
                }}
            />
            <EditOrderModal
                order={editingOrder}
                open={isObject(editingOrder)}
                callbacks={{
                    onSave,
                    onClose: () => editOrder(null),
                    onError: (error) =>
                        setNotification({
                            severity: 'error',
                            message: error?.message || error || 'Something went wrong.',
                        }),
                }}
                initTab={EDIT_ORDER_TABS.ADMIN}
                opt={{
                    enableAdminTab: true,
                    enableAdminTestAccounts: test,
                }}
            />
            <OnwardTabContainer
                value={tab}
                onChange={(e, tab) => {
                    setTab(tab);
                    setPage(0);
                }}
                textColor="primary"
                indicatorColor="primary"
                centered
                css={css`
                    background-color: white;
                `}
            >
                {TABS.map((tab, idx) => (
                    <OnwardTab key={`admin-orders-tab-${tab.value}`} label={tab.label} value={idx} error={tab.red} />
                ))}
            </OnwardTabContainer>
            <Container>
                <Row>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            margin-right: 12px;
                        `}
                    >
                        <Title>Admin Orders</Title>
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <Grid direction="row" container>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    align-content: center;
                                    justify-content: center;
                                    margin-right: 8px;
                                `}
                            >
                                <TextField
                                    value={search}
                                    placeholder="Search (OD# / PO#)"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    css={css`
                                        background-color: white;
                                    `}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    align-content: center;
                                    justify-content: center;
                                    margin-right: 8px;
                                `}
                            >
                                <FormControlLabel
                                    label="Test Accounts"
                                    disabled={loading}
                                    control={<Switch checked={test} onChange={() => setTest((prev) => !prev)} />}
                                    color="primary"
                                    css={css`
                                        .Mui-checked {
                                            color: ${colors.greens.primary};
                                            & + .MuiSwitch-track {
                                                background-color: ${colors.greens.primary};
                                            }
                                        }
                                    `}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    align-content: center;
                                    justify-content: center;
                                    margin-right: 8px;
                                `}
                            >
                                <FormControlLabel
                                    label={marketplace ? 'Marketplace' : 'OMS'}
                                    disabled={loading}
                                    control={
                                        <Switch
                                            checked={marketplace}
                                            onChange={() => setMarketplace((prev) => !prev)}
                                        />
                                    }
                                    color="primary"
                                    css={css`
                                        .Mui-checked {
                                            color: ${colors.greens.primary};
                                            & + .MuiSwitch-track {
                                                background-color: ${colors.greens.primary};
                                            }
                                        }
                                    `}
                                />
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    align-content: center;
                                    justify-content: center;
                                `}
                            >
                                <PrimaryButton loading={csvInflight} onClick={exportCSV}>
                                    Export
                                </PrimaryButton>
                            </Grid>
                            <Grid
                                direction="column"
                                container
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    align-content: center;
                                    justify-content: center;
                                `}
                            >
                                <FilterPopover
                                    shippers={shippers}
                                    shipperIds={shipperIds}
                                    setShipperIds={setShipperIds}
                                    carriers={carriers}
                                    carrierIds={carrierIds}
                                    setCarrierIds={setCarrierIds}
                                    states={states}
                                    setStates={setStates}
                                    deliveryTypes={deliveryTypes}
                                    setDeliveryTypes={setDeliveryTypes}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    completedStart={completedStart}
                                    setCompletedStart={setCompletedStart}
                                    completedEnd={completedEnd}
                                    setCompletedEnd={setCompletedEnd}
                                    tab={tab}
                                    paymentType={paymentType}
                                    setPaymentType={setPaymentType}
                                    warehouseStartDate={warehouseStartDate}
                                    warehouseEndDate={warehouseEndDate}
                                    setWarehouseEndDate={setWarehouseEndDate}
                                    setWarehouseStartDate={setWarehouseStartDate}
                                    schedDelStart={schedDelStart}
                                    setSchedDelStart={setSchedDelStart}
                                    schedDelEnd={schedDelEnd}
                                    setSchedDelEnd={setSchedDelEnd}
                                    estShipStart={estShipStart}
                                    setEstShipStart={setEstShipStart}
                                    estShipEnd={estShipEnd}
                                    setEstShipEnd={setEstShipEnd}
                                    actualReceivedStart={actualReceivedStart}
                                    setActualReceivedStart={setActualReceivedStart}
                                    actualReceivedEnd={actualReceivedEnd}
                                    setActualReceivedEnd={setActualReceivedEnd}
                                    middleMileStatus={middleMileStatus}
                                    setMiddleMileStatus={setMiddleMileStatus}
                                    warehouseStatus={warehouseStatus}
                                    setWarehouseStatus={setWarehouseStatus}
                                    orderType={orderType}
                                    setOrderType={setOrderType}
                                    milestone={milestone}
                                    setMilestone={setMilestone}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Row>
                <Row
                    css={css`
                        flex-grow: 1;
                        min-height: 400px;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        ${loading &&
                        `
                            pointer-events: none;
                            opacity: 0.7;
                        `}
                    `}
                >
                    {loading && (
                        <LinearProgress
                            color="primary"
                            css={css`
                                width: 100%;
                            `}
                        />
                    )}
                    <OrdersTable
                        tab={TABS[tab]}
                        orders={orders}
                        editOrder={editOrder}
                        sortBy={[sortBy]}
                        handleSortBy={handleSortBy}
                    />
                </Row>
                <Row
                    css={css`
                        justify-content: center;
                    `}
                >
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        css={css`
                            .Mui-selected {
                                background-color: ${colors.greens.primary};
                                color: white;
                            }
                        `}
                        count={Math.ceil(totalCount / pageSize)}
                        page={page + 1}
                        onChange={(_, p) => setPage(p - 1)}
                        disabled={loading}
                    />
                </Row>
            </Container>
        </OuterContainer>
    );
};

export default compose(withAdminRights)(AdminOrders);
