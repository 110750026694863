import React, { useMemo, useState, useContext } from 'react';
import { AppBar, Toolbar, Menu, MenuItem, Badge, Drawer, Grid, TextField } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import * as ROUTES from '../../constants/routes';
import * as Sentry from '@sentry/react';
import { getAuth, signOut } from 'firebase/auth';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useSubscription, useApolloClient } from '@apollo/client';
import { NOTIFICATIONS_SUBSCRIPTION } from '../../graphql/subscriptions/notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClientUser } from '@/hooks';
import NotificationsModal from '../NotificationsModal';
import { asUTCDate } from '@/utilities/convertToISO';
import { addDays, format } from 'date-fns';
import { UserContext } from '../App';
import { startCase } from 'lodash';
import { ADMIN_PATH_LOGINS, ADMIN_PATH_RESETS } from './constants';
import { css } from '@emotion/react';
import { sansSerif } from '@/styles/typography';
import { colors } from '@/styles';

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { client_id, businessName, roles, accountType, isImposter } = useClientUser();
    const [notifications, setNotifications] = useState([]);
    const client = useApolloClient();
    const { user, setClientId, admin_clients, clientId, sidebarCollapsed } = useContext(UserContext);

    const start = useMemo(() => {
        return addDays(asUTCDate(format(new Date(), 'yyyy-MM-dd')), -7);
    }, []);

    const { loading, error, data } = useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
        skip: !client_id,
        variables: {
            user_id: client_id,
            from_date: start,
        },
        onData: ({ data }) => {
            const notificationList = data?.data?.notifications || [];
            setNotifications(notificationList);
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const doSignOut = async () => {
        window?.sessionStorage?.removeItem('client_id');
        setClientId(null);
        await client.clearStore();
        signOut(getAuth());
    };

    const [openDrawer, setOpenDrawer] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const role = useMemo(() => {
        if (user?.roles?.['ONWARD_ADMIN']) {
            return 'Onward Admin';
        } else if (user?.roles?.['ADMIN']) {
            return 'Admin';
        } else if (user?.roles?.['SHIPPER']) {
            return 'Shipper';
        } else if (user?.roles?.['CARRIER']) {
            return 'Carrier';
        } else if (user?.roles?.['TEAMMATE']) {
            return `${startCase(accountType || '')} Teammate`;
        }
    }, [user, accountType]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickNotification = () => {
        setOpenDrawer(true);
    };

    const handleCloseNotification = () => {
        setOpenDrawer(false);
    };

    const handleProfileRedirect = () => {
        navigate(ROUTES.ACCOUNT);
        handleClose();
    };

    return (
        <AppBar position="sticky">
            <Toolbar
                css={css`
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    min-height: 50px;
                    height: 50px;
                    max-height: 50px;
                    background: #ffffff;
                    color: #4c4c4c;
                    padding-left: ${sidebarCollapsed ? '72px' : '272px'};
                `}
            >
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    {isImposter && !admin_clients?.length ? (
                        <span
                            css={css`
                                ${sansSerif}
                                font-weight: 500;
                            `}
                        >
                            {businessName} ({startCase(accountType || '')})
                        </span>
                    ) : admin_clients?.length > 0 ? (
                        <TextField
                            select
                            variant="outlined"
                            size="small"
                            value={clientId || 'ALL'}
                            onChange={(e) => {
                                let next = e.target.value;
                                if (next === 'ALL') {
                                    setClientId(null);
                                    const reset = ADMIN_PATH_RESETS.find((match) =>
                                        match.pathRx.test(location.pathname)
                                    );
                                    if (reset) {
                                        navigate(reset.redirect);
                                    } else {
                                        navigate(ROUTES.CORPORATE_USERS);
                                    }
                                } else {
                                    setClientId(next);
                                    const login = ADMIN_PATH_LOGINS.find((match) =>
                                        match.pathRx.test(location.pathname)
                                    );
                                    if (login) {
                                        navigate(login.redirect);
                                    }
                                }
                            }}
                            css={css`
                                width: 300px;
                            `}
                        >
                            <MenuItem key="all" value={'ALL'}>
                                All Accounts
                            </MenuItem>
                            {admin_clients.map((client) => (
                                <MenuItem key={client.client_id} value={client.client_id}>
                                    {client.business_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                </Grid>

                {client_id ? (
                    <Grid item>
                        <Badge
                            overlap="rectangular"
                            badgeContent={
                                notifications?.filter((n) => n.notification_status === 'UNRESOLVED')?.length || 0
                            }
                            color="error"
                            onClick={handleClickNotification}
                            css={css`
                                margin-right: 0.5rem;
                                pointer: cursor;
                            `}
                        >
                            <NotificationsNoneIcon
                                css={css`
                                    margin-right: 0.5rem;
                                `}
                            />
                        </Badge>
                        <Drawer
                            css={css`
                                width: 50vw;
                            `}
                            anchor="right"
                            open={openDrawer}
                            onClose={handleCloseNotification}
                        >
                            <NotificationsModal
                                handleCloseNotification={handleCloseNotification}
                                notifications={notifications}
                            />
                        </Drawer>
                    </Grid>
                ) : null}

                <Grid item>
                    <span
                        css={css`
                            ${sansSerif}
                            margin-right: 0.5rem;
                            font-weight: 500;
                        `}
                    >
                        {user.username} ({role})
                    </span>
                    <AccountCircleIcon onClick={handleClick} />

                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MenuItem onClick={handleProfileRedirect}>Account Settings</MenuItem>
                        <MenuItem onClick={doSignOut}>Logout</MenuItem>
                    </Menu>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
