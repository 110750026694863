import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';
import { startCase } from 'lodash';
import { toNational } from '@/utilities/formatPhoneNumber';
import { format } from 'date-fns';
import { PrimaryButton } from '@/styles/blocks';
import useQuery from '@/utilities/useQuery';
import { post } from '@/utilities/onwardClient';
import { TRIGGER_ORDER_EVENT } from '@/constants/apiRoutes';
import { Alert } from '@material-ui/lab';
import { MM_INTEGRATION_TYPES } from '@/constants/integrationTypes';
import { MM_INTEGRATION_META_FIELDS, MM_INTEGRATION_META_FIELDS_GLOBAL } from '@/constants/integrationMetaFields';

const MiddleMileCard = ({ order, isOnwardAdmin }) => {
    const statusTs =
        order?.middle_mile_integration_meta?.[`${order?.middle_mile_integration_status?.toLowerCase()}_time`];

    const canRetryCreate = useMemo(() => {
        return (
            isOnwardAdmin &&
            order.has_middle_mile_origin &&
            !order.middle_mile_is_direct &&
            !order.middle_mile_integration_status
        );
    }, [order]);

    const [retryCreate, { loading }] = useQuery(({ order_id, event }) => {
        return post(TRIGGER_ORDER_EVENT, { order_id, event });
    });

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>Middle Mile Origin Facility</CardTitle>
                {order.middle_mile_is_direct ? (
                    <CardItem
                        className="my-1"
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        Direct Shipment - Integrations Disabled
                    </CardItem>
                ) : canRetryCreate ? (
                    <>
                        <CardItem className="my-1">
                            <PrimaryButton
                                disabled={loading}
                                onClick={() => retryCreate({ order_id: order.order_id, event: 'order_created' })}
                            >
                                Retry Integrations
                            </PrimaryButton>
                            <Alert
                                severity="info"
                                css={css`
                                    margin-top: 12px;
                                `}
                            >
                                Integration status and information may take up to 30 seconds after retrying
                            </Alert>
                        </CardItem>
                    </>
                ) : (
                    <>
                        <CardItem
                            className="my-1"
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            {order.middle_mile_integration_status
                                ? startCase(order.middle_mile_integration_status.toLowerCase().replace('_', '-'))
                                : 'No Status Available'}
                            {!!statusTs ? `: ${format(new Date(statusTs), 'EEE MMM dd, yyyy h:mm a')}` : null}
                        </CardItem>
                        <CardItem className="my-1">
                            Integration:{' '}
                            {MM_INTEGRATION_TYPES[order.middle_mile_integration_type] ||
                                order.middle_mile_integration_type}
                        </CardItem>
                        {MM_INTEGRATION_META_FIELDS_GLOBAL.map(({ attr, label }) => (
                            <CardItem className="my-1">
                                {label}: {order.middle_mile_integration_meta?.[attr] || '--'}
                            </CardItem>
                        ))}
                        {(MM_INTEGRATION_META_FIELDS[order.middle_mile_integration_type] || []).map(
                            ({ attr, label }) => (
                                <CardItem className="my-1">
                                    {label}: {order.middle_mile_integration_meta?.[attr] || '--'}
                                </CardItem>
                            )
                        )}
                    </>
                )}
                <CardItem className="my-1">Origin Name: {order.middle_mile_origin_name || '--'}</CardItem>
                <CardItem className="my-1">Origin Email: {order.middle_mile_origin_email || '--'}</CardItem>
                <CardItem className="my-1">
                    Origin Phone: {order.middle_mile_origin_phone ? toNational(order.middle_mile_origin_phone) : '--'}
                </CardItem>
                <CardItem className="my-1">
                    {[
                        order.middle_mile_origin_address,
                        ...(order.middle_mile_origin_unit ? [order.middle_mile_origin_unit] : []),
                        order.middle_mile_origin_city,
                        order.middle_mile_origin_state,
                        order.middle_mile_origin_zip,
                    ].join(', ')}
                </CardItem>
                <CardItem className="mt-1 mb-4">
                    {startCase(order.middle_mile_origin_location || '')} -{' '}
                    {startCase(order.middle_mile_origin_location_type || '')}
                </CardItem>
            </Grid>
        </OnwardCard>
    );
};

export default MiddleMileCard;
