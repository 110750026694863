import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Row, Container, Card, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import {
    Button,
    InputAdornment,
    IconButton,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
    Grid,
    Checkbox,
    Typography,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import * as Sentry from '@sentry/react';
import './ShipperAccount/styles.css';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import { USER_BY_EMAIL } from './queries';
import { REMOVE_TEAMMATE, UPDATE_TEAMMATE } from './mutations';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { post } from '@/utilities/onwardClient';
import { INVITE_TEAMMATE } from '@/constants/apiRoutes';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { MoreVert } from '@material-ui/icons';
import { css } from '@emotion/react';
import FileDropZone from '@/components/OrderDetailsPage/FileDropZone';
import {
    ModalContent,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SecondaryButton,
    SectionTitle,
    StickyModalActions,
} from '@/styles/blocks';
import { OnwardToggle } from '../ShipmentForm/blocks';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import anonProfile from 'src/assets/anonProfile.jpg';
import { getTariffName } from './Tariffs/utils';

const capitalize = (string = '') => string.slice(0, 1).toUpperCase() + string.slice(1);

export const teammateRoles = {
    admin: {
        text: 'Admin',
        description: 'The user has full access to the account, including all other permissions.',
        isExclusive: true,
    },
    viewer: {
        text: 'Viewer',
        description:
            'The user has limited access to the account. They may view information about routes and orders but may not make many modifications to them.',
    },
    driver: {
        text: 'Driver',
        description:
            'The user can only view and update orders that are on routes that they are assigned to as drivers.',
    },
    helper: {
        text: 'Helper',
        description: 'The user can only view and update orders that are assigned to them.',
    },
    operations: {
        text: 'Operations',
        description: 'The user will have access to most features and will be able to manage orders and routes.',
    },
    warehouse: {
        text: 'Warehouse',
        description: 'The user has access to WMS features.',
    },
    invoicing: {
        text: 'Invoice Editing',
        description: 'The user will have access to invoicing features for the account.',
        exclusiveTo: ['carrier'],
    },
};

const RolesRowText = (props) => (
    <Typography
        css={css`
            // font-size: 14px;
        `}
    >
        {props.children}
    </Typography>
);

const TeammateRow = ({ teammate, callbacks }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const roles = useMemo(() => {
        if (!!teammate?.roles?.admin) {
            return ['Admin'];
        }

        return Object.entries(teammate?.roles || {})?.reduce((roleTitles, [roleValue, roleEnabled]) => {
            if (('none', 'superadmin').includes(roleValue)) {
                return roleTitles;
            }
            if (!!roleEnabled) {
                roleTitles.push(teammateRoles?.[roleValue]?.text);
            }
            return roleTitles;
        }, []);
    }, [teammate]);

    const menuOptions = useMemo(
        () => [
            {
                display: 'Edit',
                callback: () => {
                    callbacks.setTeammateToEdit(teammate);
                    setAnchorEl(null);
                },
            },
            {
                display: 'Remove Teammate',
                callback: () => {
                    callbacks.removeTeammate(teammate.teammate_id);
                    setAnchorEl(null);
                },
            },
        ],
        [teammate, callbacks]
    );

    return (
        <tr key={teammate.teammate_id}>
            <td>
                {' '}
                {teammate.profile_image ? (
                    <Image
                        src={teammate.profile_image}
                        css={css`
                            height: 2.5rem;
                            width: 2.5rem;
                        `}
                        roundedCircle
                    />
                ) : (
                    <Image
                        src={anonProfile}
                        css={css`
                            height: 2.5rem;
                            width: 2.5rem;
                        `}
                        roundedCircle
                    />
                )}
            </td>
            <td>{teammate.username}</td>
            <td style={{ textAlign: 'start' }}>{teammate.email}</td>
            <td style={{ textAlign: 'start' }}>{teammate.teammate_type ? teammate.teammate_type : '-'}</td>
            <td style={{ textAlign: 'start' }}>{teammate.hourly_rate ? `$${teammate.hourly_rate.toFixed(2)}` : '-'}</td>
            <td>
                {roles.map((r) => (
                    <React.Fragment>
                        {r}
                        <br />
                    </React.Fragment>
                ))}
            </td>
            <td style={{ textAlign: 'start' }}>
                {capitalize(teammate.user?.device_settings?.location?.foreground.status || 'Undetermined')}
            </td>
            <td style={{ textAlign: 'start' }}>
                {capitalize(teammate.user?.device_settings?.location?.background.status || 'Undetermined')}
            </td>
            <td style={{ textAlign: 'start' }}>
                <>
                    <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                        {menuOptions.map((option, i) => (
                            <MenuItem
                                key={i}
                                onClick={(e) => {
                                    option.callback();
                                }}
                                disabled={option.disabled}
                            >
                                {option.display}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            </td>
        </tr>
    );
};

const PermissionsRow = (roleValue, roleInfo, teammateToEdit, setUpdate, update) => {
    if (roleValue === 'invoicing' && teammateToEdit?.team_leader_type === 'shipper') return null;

    const isAdmin = !!update?.roles?.admin;

    const disabled = () => {
        if (isAdmin) {
            if (roleValue === 'admin') {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    const isChecked = () => {
        if (isAdmin) {
            return true;
        }

        return !!update?.roles?.[roleValue];
    };
    const checkVal = isChecked();

    return (
        <Grid item container flexDirection="row">
            <Grid item xs={2} alignContent="center">
                <OnwardToggle
                    checked={disabled() ? true : checkVal}
                    disabled={disabled()}
                    onChange={(e) => {
                        setUpdate((prev) => ({
                            ...prev,
                            roles: {
                                ...(update.roles || {}),
                                [roleValue]: e.target.checked,
                            },
                        }));
                    }}
                />
            </Grid>
            <Grid item xs={2} alignContent="center">
                <RolesRowText>{roleInfo?.text || ''}</RolesRowText>
            </Grid>
            <Grid item xs={8} alignContent="center">
                <RolesRowText>{roleInfo?.description || ''}</RolesRowText>
            </Grid>
        </Grid>
    );
};

export default function Teammates({ teamLeaderType, tariffs, driverMappings, callbacks }) {
    const [teammateEmail, setTeammateEmail] = useState('');
    const [teammateRole, setTeammateRole] = useState('none');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [teammateToRemoveUid, setTeammateToRemoveUid] = useState(null);
    const { user_id, teammates } = useClientUser();
    const [teammateToEdit, setTeammateToEdit] = useState(null);
    const [update, setUpdate] = useState({});
    const [invitingTeammate, setInvitingTeammate] = useState(null);
    const [tariffId, setTariffId] = useState('');

    const mapping = useMemo(() => {
        return (driverMappings || []).find((m) => m.driver_id === teammateToEdit?.teammate_id);
    }, [teammateToEdit, driverMappings]);

    const [updateTeammate] = useMutation(UPDATE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [removeTeammate] = useMutation(REMOVE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
        update: (cache, { data: { update_teammates } }) => {
            const removed = update_teammates?.returning?.[0];
            cache.evict(cache.identify(removed));
        },
    });

    const handleClickOpen = (_uid) => {
        setDialogOpen(true);
        setTeammateToRemoveUid(_uid);
    };

    const handleRemoveTeammateClose = () => {
        setDialogOpen(false);
        setTeammateToRemoveUid(null);
    };

    const handleEditTeammateClose = () => {
        setInvitingTeammate(null);
        setTeammateToEdit(null);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const [getUserByEmail] = useLazyQuery(USER_BY_EMAIL);

    const sendEmailInvite = async () => {
        if (!teammateEmail) {
            setError('You must enter an email!');
            return;
        }

        setLoading(true);

        const userResponse = await getUserByEmail({ variables: { email: teammateEmail.toLowerCase().trim() } });
        if (userResponse?.data?.users?.length > 0) {
            setError('A user with this email already exists!');
            setLoading(false);
            return;
        }

        setSuccessMessage('Sending invite ...');
        return post(INVITE_TEAMMATE, {
            teamLeaderId: user_id,
            teammateRoles: {
                ...update?.roles,
            },
            teamLeaderType,
            teammateEmail: teammateEmail.trim(),
        })
            .then(() => {
                setError(null);
                setSuccessMessage(`Your email invite was successfully sent to ${teammateEmail}!`);
            })
            .catch(({ response }) => {
                setError(
                    response?.data?.error || `Error inviting teammate` + `. Contact your Onward rep for assistance`
                );
            })
            .finally(() => {
                setLoading(false);
                setTeammateEmail('');
                setInvitingTeammate(null);
                setUpdate({});
            });
    };

    const handleRemoveTeammate = () => {
        removeTeammate({
            variables: {
                teammate_id: teammateToRemoveUid,
            },
            onCompleted: () => {
                handleRemoveTeammateClose();
            },
        });
    };

    useEffect(() => {
        setUpdate({
            roles: teammateToEdit?.roles || {},
            teammate_type: teammateToEdit?.teammate_type || '',
            hourly_rate: teammateToEdit?.hourly_rate || 0,
            profile_image: teammateToEdit?.profile_image,
        });
    }, [teammateToEdit]);

    const role = useMemo(() => {
        return Object.keys(teammateRoles).find((role) => update?.roles?.[role]);
    }, [update]);

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, 'profile_image', fileType);
                const fbFile = fbFileUrl;

                setUpdate((prev) => ({ ...prev, profile_image: fbFile }));
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Row className="d-flex flex-column mt-3">
                                <h5>
                                    Invite new teammates
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip placement="right">
                                                Use the text input below to invite a new team member to help you manage
                                                your Onward Delivery account. Privilages can be granted post
                                                registration.
                                            </Tooltip>
                                        }
                                    >
                                        <InfoCircleFill size={17} className="mx-1 mb-1" />
                                    </OverlayTrigger>
                                </h5>

                                <Form.Group>
                                    <TextField
                                        type="text"
                                        value={teammateEmail}
                                        variant="outlined"
                                        label="Teammate Email"
                                        onChange={(e) => setTeammateEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        onClick={() => {
                                            setInvitingTeammate({
                                                email: teammateEmail,
                                            });
                                        }}
                                        className="my-1 account-btn"
                                        disabled={loading}
                                        style={{ fontWeight: '600' }}
                                    >
                                        Send Email Invite
                                    </Button>
                                    {error && (
                                        <p
                                            style={{
                                                color: 'red',
                                                width: 'fit-content',
                                                paddingTop: '6px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {error}
                                        </p>
                                    )}

                                    {successMessage && (
                                        <p
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'green',
                                                width: 'fit-content',
                                                padding: '6px',
                                                borderRadius: '5px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {successMessage}
                                        </p>
                                    )}
                                </Form.Group>
                            </Row>

                            {!!teammates.length && (
                                <>
                                    <h5 className="mt-5">Manage your Teammates</h5>
                                    <Row>
                                        <Table hover style={{ width: '100%' }}>
                                            <thead>
                                                <tr
                                                    style={{
                                                        textAlign: 'start',
                                                        whitespace: 'nowrap',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    <th></th>
                                                    <th>Teammate</th>
                                                    <th>Email</th>
                                                    <th>Type</th>
                                                    <th>Hourly Rate</th>
                                                    <th>Roles / Permissions</th>
                                                    <th>Foreground Location Permission</th>
                                                    <th>Background Location Permission</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teammates.map((teammate) => (
                                                    <TeammateRow
                                                        teamLeaderType={teamLeaderType}
                                                        teammate={teammate}
                                                        callbacks={{
                                                            updateTeammate: (teammate_id, update) =>
                                                                updateTeammate({ variables: { teammate_id, update } }),
                                                            removeTeammate: (teammate_id) =>
                                                                handleClickOpen(teammate_id),
                                                            setTeammateToEdit: (teammate) =>
                                                                setTeammateToEdit(teammate),
                                                        }}
                                                    />
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Container>
                </Card.Body>

                {/* Remove Teammate Modal */}
                <Dialog open={dialogOpen} onClose={handleRemoveTeammateClose}>
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this teammate?</DialogTitle>
                    <DialogActions>
                        <Button className="account-btn" onClick={handleRemoveTeammate}>
                            Yes
                        </Button>
                        <Button className="account-btn" onClick={handleRemoveTeammateClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Invite or Edit Teammate Modal */}
                <ResponsiveSidebarDialog
                    open={teammateToEdit || !!invitingTeammate?.email}
                    onClose={handleEditTeammateClose}
                >
                    <ModalContent
                        css={css`
                            width: auto;
                        `}
                    >
                        {!invitingTeammate && (
                            <Grid>
                                <Typography
                                    css={css`
                                        font-weight: 600;
                                        font-size: 22px;
                                        padding-bottom: 10px;
                                    `}
                                >
                                    Teammate Details
                                </Typography>
                            </Grid>
                        )}
                        <Grid container direction="row" spacing={2}>
                            {!invitingTeammate && (
                                <>
                                    <Grid
                                        item
                                        css={css`
                                            width: 240px;
                                        `}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Type"
                                            variant="outlined"
                                            size="small"
                                            value={update?.teammate_type || ''}
                                            onChange={(e) =>
                                                setUpdate((prev) => ({ ...prev, teammate_type: e.target.value }))
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        css={css`
                                            width: 300px;
                                        `}
                                    >
                                        <TextField
                                            fullWidth
                                            select
                                            label="Driver Tariff"
                                            variant="outlined"
                                            size="small"
                                            value={tariffId || mapping?.tariff_id || ''}
                                            onChange={(e) => setTariffId(e.target.value)}
                                        >
                                            {(tariffs || [])
                                                .filter((t) => t.tariff_type === 'DRIVER')
                                                .map((tariff, idx) => (
                                                    <MenuItem key={tariff.tariff_id} value={tariff.tariff_id}>
                                                        {getTariffName(tariff, idx)}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid>
                                </>
                            )}
                            <Grid
                                item
                                container
                                flexDirection="column"
                                justifyContent="center"
                                css={css`
                                    padding-top: 28px !important;
                                    padding-bottom: 28px !important;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        margin-right: auto;
                                    `}
                                >
                                    <Typography
                                        css={css`
                                            font-weight: 600;
                                            font-size: 22px;
                                            padding-bottom: 10px;
                                        `}
                                    >
                                        Teammate Roles and Permissions
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    css={css`
                                        margin-right: auto;
                                    `}
                                >
                                    <Typography
                                        css={css`
                                            font-weight: 500;
                                            font-size: 18px;
                                            padding-bottom: 4px;
                                        `}
                                    >
                                        Exclusive Permissions - These permissions are unique and often cannot be
                                        combined with additional permissions below
                                    </Typography>
                                </Grid>

                                <Grid item container flexDirection="row">
                                    <Grid item xs={2}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Enabled
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Role
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Permissions
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {Object.entries(teammateRoles)
                                    .filter(([roleValue, roleInfo]) => roleInfo?.isExclusive)
                                    .map(([roleValue, roleInfo]) =>
                                        PermissionsRow(roleValue, roleInfo, teammateToEdit, setUpdate, update)
                                    )}

                                <Grid
                                    item
                                    css={css`
                                        margin-right: auto;
                                    `}
                                >
                                    <Typography
                                        css={css`
                                            font-weight: 500;
                                            font-size: 18px;
                                            padding-bottom: 4px;
                                            margin-top: 12px;
                                        `}
                                    >
                                        Standard Permissions
                                    </Typography>
                                </Grid>

                                <Grid item container flexDirection="row">
                                    <Grid item xs={2}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Enabled
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Role
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            css={css`
                                                font-weight: 800;
                                            `}
                                        >
                                            Permissions
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {Object.entries(teammateRoles)
                                    .filter(([roleValue, roleInfo]) => !roleInfo?.isExclusive)
                                    .map(([roleValue, roleInfo]) =>
                                        PermissionsRow(roleValue, roleInfo, teammateToEdit, setUpdate, update)
                                    )}
                            </Grid>

                            {!invitingTeammate && (
                                <>
                                    <Grid
                                        item
                                        css={css`
                                            margin-right: auto;
                                        `}
                                    >
                                        <Typography
                                            css={css`
                                                font-weight: 600;
                                                font-size: 22px;
                                                padding-bottom: 10px;
                                            `}
                                        >
                                            Driver Photo
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        {update?.profile_image ? (
                                            <>
                                                <Image
                                                    src={update.profile_image}
                                                    css={css`
                                                        height: 200px;
                                                        width: 200px;
                                                    `}
                                                    roundedCircle
                                                />
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        color: 'green',
                                                        cursor: 'pointer',
                                                        marginTop: '10px',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => {
                                                        const input = document.createElement('input');
                                                        input.type = 'file';
                                                        input.accept = 'image/*';
                                                        input.onchange = (e) => {
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                handleFileAdd(file, file.type.split('/')[1]);
                                                            }
                                                        };
                                                        input.click();
                                                    }}
                                                >
                                                    Edit
                                                </Typography>
                                            </>
                                        ) : (
                                            <FileDropZone
                                                customLabel={'Upload Driver Photo'}
                                                customStyle={{
                                                    height: '100px',
                                                    width: '100%',
                                                    maxWidth: '400px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: 'white',
                                                    borderRadius: '5px',
                                                    outline: '1px solid #c4c4c4',
                                                    fontFamily: 'Montserrat',
                                                    cursor: 'pointer',
                                                }}
                                                handleFileAdd={handleFileAdd}
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ModalContent>
                    <StickyModalActions>
                        <SecondaryButton
                            onClick={() => {
                                setTeammateToEdit(null);
                            }}
                        >
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() => {
                                if (invitingTeammate) {
                                    sendEmailInvite();
                                } else {
                                    if (tariffId) {
                                        callbacks
                                            .upsertDriverTariff({
                                                variables: {
                                                    mappings: [
                                                        {
                                                            ...(mapping?.mapping_id
                                                                ? { mapping_id: mapping.mapping_id }
                                                                : {}),
                                                            client_id: user_id,
                                                            driver_id: teammateToEdit.teammate_id,
                                                            tariff_id: tariffId,
                                                        },
                                                    ],
                                                },
                                            })
                                            .then(() => setTariffId(null));
                                    }
                                    updateTeammate({
                                        variables: {
                                            teammate_id: teammateToEdit.teammate_id,
                                            update: update,
                                        },
                                        onCompleted: () => {
                                            setUpdate({});
                                            setTeammateToEdit(null);
                                        },
                                    });
                                }
                            }}
                        >
                            {invitingTeammate ? 'Invite Teammate' : 'Save'}
                        </PrimaryButton>
                    </StickyModalActions>
                </ResponsiveSidebarDialog>
            </ThemeProvider>
        </Card>
    );
}
