import React, { useState } from 'react';
import {
    TextField,
    Grid,
    InputAdornment,
    LinearProgress,
    Typography,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import { asBrowserDate } from '@/utilities/convertToISO';
import { format } from 'date-fns';
import Table from './table';
import { css } from '@emotion/react';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { PrimaryHeaderLabel } from './blocks';
import { Search } from '@material-ui/icons';
import { INVENTORY_CSV_COLUMNS, INVENTORY_TABS } from './constants';
import SkuHistoryModal from './modals/SkuHistoryModal';
import { PrimaryButton } from '@/styles/blocks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FilterHeader } from '../DispatchUnassigned/blocks';
import FilterPopover from './FilterPopover';
import ExportCsvTemplateModal from '../ExportCsvTemplateModal';

const InventoryManagement = ({ state, loading, callbacks }) => {
    const { search, itemsBySku, tabIndex, isPalletView } = state;
    const { setSearch, setIsPalletView } = callbacks;
    const [openExportModal, setOpenExportModal] = useState(false);

    return (
        <>
            <Grid
                direction="row"
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                    background-color: white;
                `}
            >
                <div
                    css={css`
                        flex-grow: 1;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <OnwardTabContainer
                        onChange={(e, tab) => callbacks.setTabIndex(tab)}
                        value={tabIndex}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {INVENTORY_TABS.map((tab) => (
                            <OnwardTab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </OnwardTabContainer>
                </div>
                <PrimaryButton
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenExportModal(true)}
                    disabled={loading.export}
                    css={css`
                        width: 100px;
                        height: 35px;
                        align-self: center;
                        margin-right: 50px;
                    `}
                    endIcon={<FileDownloadIcon />}
                >
                    Export
                </PrimaryButton>
            </Grid>

            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                css={css`
                    padding: 0 40px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                `}
            >
                <PrimaryHeaderLabel>Inventory Management</PrimaryHeaderLabel>
                <Grid
                    item
                    xs={7}
                    css={css`
                        flex: 1;
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <TextField
                        value={search}
                        placeholder="Search SKU/Order/PO etc."
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        css={css`
                            width: 60%;
                        `}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <FilterPopover callbacks={callbacks} state={state} />
                <FormControlLabel
                    css={css`
                        margin-left: 2px;
                    `}
                    control={
                        <Switch
                            checked={isPalletView}
                            onChange={(e) => setIsPalletView(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="View by Pallet"
                />
            </Grid>

            {loading.init && (
                <Grid
                    item
                    css={css`
                        margin: 0 2rem;
                    `}
                >
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                </Grid>
            )}

            <Grid
                item
                css={css`
                    overflow: hidden;
                    flex-grow: 1;
                    padding: 0 40px;
                `}
            >
                <Table loading={loading} state={state} callbacks={callbacks} isPalletView={isPalletView} />
            </Grid>
            <SkuHistoryModal state={state} callbacks={callbacks} />
            <ExportCsvTemplateModal
                open={openExportModal}
                setOpen={setOpenExportModal}
                exportType={'INVENTORY'}
                csvExportColumns={{
                    Inventory: INVENTORY_CSV_COLUMNS,
                }}
                callbacks={{
                    onExport: callbacks.exportCsv,
                }}
            />
        </>
    );
};

export default InventoryManagement;
