import React, { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from '@/styles';

import { Body1, Body2 } from './blocks';
import { ACTION_DISPLAY_MAPPING, INSTRUCTIONS_DISPLAY_MAPPING, REQUIRES_CARRIER_INSTRUCTION } from './constants';
import { useLazyQuery } from '@apollo/client';
import { GET_RETURN_ORDER } from '../../graphql';
import { OnwardLink } from '@/styles/blocks';

const ResolutionRadioGroup = ({ exception, order }) => {
    const [getAssociatedReturnOrder, { data: returnOrderData }] = useLazyQuery(GET_RETURN_ORDER, {
        variables: {
            order_id: order?.return_order_id,
        },
    });

    useEffect(() => {
        if (order && exception.resolution === 'CREATE_RETURN') {
            getAssociatedReturnOrder();
        }
    }, [exception]);

    const returnOrderLink = useMemo(() => {
        if (!returnOrderData?.orders?.[0]) return null;
        const returnOrder = returnOrderData.orders[0];
        return {
            label: returnOrder.order_number,
            link: `/order/${returnOrder.order_id}`,
        };
    }, [returnOrderData]);

    return (
        <>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                >
                    Resolved
                </Body2>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body2
                    css={css`
                        color: ${colors.greys[3]};
                    `}
                >
                    Shipper has responded
                </Body2>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                `}
            >
                <Body1>How would you like to resolve this exception?</Body1>
            </Grid>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 8px;
                    margin-left: 20px;
                `}
            >
                <Body1>{ACTION_DISPLAY_MAPPING[exception.resolution]}</Body1>
            </Grid>
            {REQUIRES_CARRIER_INSTRUCTION.includes(exception.type) && exception.instruction ? (
                <>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 8px;
                            margin-top: 12px;
                        `}
                    >
                        <Body1>What would you like to do with the items?</Body1>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        css={css`
                            margin-bottom: 8px;
                            margin-left: 20px;
                        `}
                    >
                        <Body1>{INSTRUCTIONS_DISPLAY_MAPPING[exception.instruction]}</Body1>
                    </Grid>
                </>
            ) : null}
            {returnOrderLink?.link && exception?.resolution === 'CREATE_RETURN' && (
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 8px;
                    `}
                >
                    <Body1>
                        Associated return order:{' '}
                        <OnwardLink
                            href={returnOrderLink.link}
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        >
                            {returnOrderLink.label}
                        </OnwardLink>
                    </Body1>
                </Grid>
            )}
        </>
    );
};

export default ResolutionRadioGroup;
