import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table';
import { useNavigate } from 'react-router-dom';

import { TableContainer } from '@material-ui/core';
import { useColumns } from './columns';
import HeaderCell from '@/components/DispatchUnassigned/table/HeaderCell';
import { IndeterminateCheckbox } from '../DispatchUnassigned/table';
import { useSticky } from 'react-table-sticky';
import { useRowHighlight } from '../admin/AdminOrders/constants';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { sansSerif } from '@/styles/typography';

const Body = styled.span`
    ${sansSerif}
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
`;

const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 24px 6px 16px;
    border-bottom: 1px solid #e0e0e0;

    &[data-sticky-td] {
        background: ${colors.white.primary};
        position: sticky;
        display: flex;
    }

    &[data-sticky-last-left-td] {
        border-left: 2px 0px 3px #ccc;
    }

    &[data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
    }
`;

const TableRow = styled((props) => <div {...props} />)`
    display: flex;
    cursor: pointer;
    color: ${(props) => (props.error ? '#f44336' : '#2b2b2b')};
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

const OrderTable = ({ orders, tab, selectedOrderIds, sortBy, callbacks = {} }) => {
    const navigate = useNavigate();
    const TABLE_COLUMNS = useColumns(orders);

    const highlightRow = useRowHighlight(tab);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            columns: TABLE_COLUMNS,
            data: orders,
            getRowId: (order) => order.order_id,
            autoResetHiddenColumns: false,
            initialState: { selectedRowIds: selectedOrderIds, sortBy },
            manualSortBy: true,
            enableRowSelection: true,
            enableMultiRowSelection: true,
            autoResetSelectedRows: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
            hooks.prepareRow.push(highlightRow);
        }
    );

    useEffect(() => {
        if (state?.sortBy?.length) {
            callbacks.handleSortBy(state.sortBy[0]);
        }
    }, [state?.sortBy]);

    useEffect(() => {
        callbacks.setSelectedOrderIds(state?.selectedRowIds);
    }, [state?.selectedRowIds]);

    const onClick = (order) => {
        switch (tab.value) {
            case 'INCOMPLETE':
                if (order.job_id) {
                    window.open(`/job/${order.job_id}`, '_blank');
                }
                break;
            default:
                navigate(`/order/${order.order_id}`, { state: { order: order, source: 'myorders' } });
                break;
        }
    };

    const BodyCell = ({ cell, ...props }) => (
        <Cell {...props}>
            <Body>{cell.render('Cell')}</Body>
        </Cell>
    );

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => {
                                    return (
                                        <HeaderCell
                                            {...col.getHeaderProps()}
                                            key={col.id}
                                            cell={col}
                                            align={col.align}
                                        />
                                    );
                                }),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <TableRow
                    {...row.getRowProps({ style })}
                    row={row}
                    onClick={() => onClick(row.original)}
                    error={row.red}
                >
                    {row.cells.map((cell) => (
                        <BodyCell {...cell.getCellProps()} cell={cell} align={cell.column.align} />
                    ))}
                </TableRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default OrderTable;
