export const exceptionTypeMap = {
    itemsTooDamaged: 'Items Too Damaged',
    itemDamaged: 'Item Damaged',
    customerNotAvailable: 'Customer Not Available',
    orderNotAvailable: 'Order Not Available',
    customerRefusal: 'Customer Refusal',
    unableToLocateOrder: 'Unable to Locate Order',
    deliveryDidntArrive: "Delivery didn't arrive or was cancelled",
    unableToLocateCustomer: 'Unable to Locate Customer',
    orderDidntFit: "Order Didn't Fit",
    'weather/safety': 'Weather/Safety',
    shortage: 'Shortage',
};

export const EXCEPTION_TYPES = {
    customerNotAvailable: {
        label: 'Customer not Available',
        source: ['dispatch', 'dropoff'],
        level: 'order',
    },
    orderNotAvailable: {
        label: 'Order not Arrived at Warehouse',
        source: ['dispatch', 'pickup'],
        level: 'order',
    },
    unableToLocateOrder: {
        label: 'Warehouse Unable to Locate Order',
        source: ['dispatch', 'pickup'],
        level: 'order',
    },
    deliveryDidntArrive: {
        label: 'Delivery Team did not Arrive',
        source: ['dispatch'],
        level: 'order',
    },
    itemsTooDamaged: {
        label: 'Items too Damaged',
        source: ['dispatch', 'pickup'],
        level: 'order',
    },
    'weather/safety': {
        label: 'Weather / Safety',
        source: ['dispatch', 'pickup', 'dropoff'],
        level: 'order',
    },
    itemDamaged: {
        label: 'Item Damaged',
        source: ['dropoff'],
        level: 'item',
    },
    customerRefusal: {
        label: 'Customer Refusal',
        source: ['dropoff', 'dispatch'],
        level: 'order',
    },
    unableToLocateCustomer: {
        label: 'Unable to Locate Customer',
        source: ['dropoff'],
        level: 'order',
    },
    orderDidntFit: {
        label: `Order Doesn't Fit`,
        source: ['pickup'],
        level: 'order',
    },
    shortage: {
        label: `Shortage`,
        source: ['pickup'],
        level: 'item',
    },
};

export const DISPATCHER_EXCEPTION_TYPES = Object.entries(EXCEPTION_TYPES)
    .map(([key, val]) => ({ key, ...val }))
    .filter((type) => type.source.includes('dispatch'));

export const exceptionReportedAtMap = {
    DROPOFF: 'Dropoff',
    PICKUP: 'Pickup',
    DISPATCH: 'Dispatch',
};
