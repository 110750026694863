import { useMutation, useQuery } from '@apollo/client';
import { isObject } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Snackbar from '../Snackbar';
import { TabCard } from './blocks';
import { TRUCKS_BY_CLIENT } from './queries';
import { css } from '@emotion/react';
import { IconButton, LinearProgress, TextField } from '@material-ui/core';
import { DELETE_TRUCK, UPSERT_TRUCK } from './mutations';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';
import TrucksDaysAvailableModal from './TrucksDaysAvailableModal';
import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Body1 } from './blocks';

const daysAvailableMap = {
    monday: 'Mon',
    tuesday: 'Tues',
    wednesday: 'Wed',
    thursday: 'Thurs',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
};

const Truck = ({ truck, onSave, onDelete, disabled }) => {
    const [truckData, setTruckData] = useState(truck);
    const [modified, setModified] = useState(false);
    const [daysAvailableModalOpen, setDaysAvailableModalOpen] = useState(false);

    const modifyTruck = (update) => {
        setTruckData((prev) => ({ ...prev, ...update }));
        setModified(true);
    };

    useEffect(() => {
        setTruckData(truck);
        setModified(false);
    }, [truck]);

    const daysAvailableText = useMemo(() => {
        const truckDayKeys = Object.keys(daysAvailableMap);

        let isEvery = true;
        let isNone = true;
        const availableDays = [];
        truckDayKeys.forEach((key) => {
            if (truckData?.days_available?.[key]) {
                availableDays.push(daysAvailableMap[key]);
                isNone = false;
            } else {
                isEvery = false;
            }
        });

        if (isNone) return 'None';
        if (isEvery) return 'Everyday';
        if (!availableDays.length) return 'None';
        return availableDays.join(', ');
    }, [truckData]);

    return (
        <>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    padding: 1rem 0rem;
                `}
            >
                <TextField
                    label="Name"
                    variant="outlined"
                    value={truckData.truck_name}
                    onChange={(e) => modifyTruck({ truck_name: e.target.value })}
                />
                <TextField
                    type="number"
                    label="Size (ft)"
                    variant="outlined"
                    value={truckData.truck_size}
                    onChange={(e) => modifyTruck({ truck_size: e.target.value })}
                    css={css`
                        margin-left: 1rem;
                    `}
                />
                <TextField
                    type="number"
                    label="Capacity (cubic ft)"
                    variant="outlined"
                    value={truckData.truck_capacity}
                    onChange={(e) => modifyTruck({ truck_capacity: e.target.value })}
                    css={css`
                        margin-left: 1rem;
                    `}
                />
                <TextField
                    type="number"
                    label="Weight Limit (lbs)"
                    variant="outlined"
                    value={truckData.truck_weight_limit}
                    onChange={(e) => modifyTruck({ truck_weight_limit: e.target.value })}
                    css={css`
                        margin-left: 1rem;
                    `}
                />
                <TextField
                    label="Days Available"
                    variant="outlined"
                    value={daysAvailableText}
                    InputProps={{
                        readOnly: true,
                    }}
                    onClick={() => setDaysAvailableModalOpen(true)}
                    css={css`
                        margin-left: 1rem;
                        & .MuiInputBase-input {
                            text-overflow: ellipsis;
                        }
                    `}
                />
                <IconButton disabled={disabled || !modified} onClick={() => onSave(truckData)}>
                    <SaveOutlined fontSize="large" color={disabled || !modified ? 'disabled' : 'primary'} />
                </IconButton>
                <IconButton disabled={disabled} onClick={() => onDelete(truckData)}>
                    <DeleteForeverOutlined fontSize="large" color={disabled ? 'disabled' : 'error'} />
                </IconButton>
            </div>
            {daysAvailableModalOpen && (
                <TrucksDaysAvailableModal
                    open={daysAvailableModalOpen}
                    onClose={() => {
                        setDaysAvailableModalOpen(false);
                    }}
                    days_available={truckData?.days_available}
                    modifyTruck={modifyTruck}
                />
            )}
        </>
    );
};

const Trucks = ({ client }) => {
    const [notification, setNotification] = useState(null);
    const [savingTrucks, setSavingTrucks] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);

    const savingTruck = (truck_id, saving) => {
        setSavingTrucks((prev) => ({
            ...prev,
            [truck_id]: saving,
        }));
    };

    const { data, loading } = useQuery(TRUCKS_BY_CLIENT, {
        variables: { client_id: client.client_id },
    });
    const trucks = useMemo(() => {
        return data?.trucks || [];
    }, [data]);

    const [upsertTruck] = useMutation(UPSERT_TRUCK, {
        onCompleted: ({ insert_trucks_one }) => {
            savingTruck(insert_trucks_one.truck_id, false);
            setNotification({
                message: `Truck ${insert_trucks_one.truck_name} Saved!`,
                severity: 'success',
            });
        },
        onError: (e) => {
            if (e?.message?.includes('trucks_client_id_truck_name_key')) {
                setNotification({ message: 'A truck with this name already exists!', severity: 'error' });
            } else {
                console.error(e);
                setNotification({
                    message: 'Error saving truck',
                    severity: 'error',
                });
            }
        },
    });

    const [deleteTruck] = useMutation(DELETE_TRUCK, {
        update: (cache, { data: { delete_trucks_by_pk } }) => {
            cache.evict(cache.identify(delete_trucks_by_pk));
        },
    });

    const addTruck = () => {
        let number = trucks.length + 1;
        while (trucks.find((truck) => truck.truck_name === `Truck ${number}`)) {
            number++;
        }

        const newTruck = {
            client_id: client.client_id,
            truck_name: `Truck ${number}`,
        };

        upsertTruck({
            variables: {
                truck: newTruck,
            },
            update: (cache, { data: { insert_trucks_one } }) => {
                cache.modify({
                    fields: {
                        trucks: (existingTrucks = [], { toReference }) => {
                            const newTruckRef = toReference(insert_trucks_one);
                            return [...existingTrucks, newTruckRef];
                        },
                    },
                });
            },
        });
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const handleDeleteTruck = () => {
        setSavingTrucks(deleteModal.truck_id, true);
        deleteTruck({
            variables: {
                truck_id: deleteModal.truck_id,
            },
        });
        setDeleteModal(false);
    }

    return (
        <TabCard>
            <Snackbar
                open={isObject(notification)}
                handleClose={() => setNotification(null)}
                {...(notification || {})}
            />
            <NavResponsiveModal open={!!deleteModal} onClose={closeDeleteModal}>
                <ModalHeader title="Delete Truck?" onClose={closeDeleteModal} />
                <ModalContent>
                    <Body1>Are you sure you want to delete this truck?</Body1>
                </ModalContent>
                <ModalActions>
                    <SecondaryButton onClick={closeDeleteModal}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={handleDeleteTruck}>Delete</PrimaryButton>
                </ModalActions>
            </NavResponsiveModal>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    padding: 1rem 2rem;
                `}
            >
                {loading && (
                    <LinearProgress
                        color="primary"
                        css={css`
                            width: 100%;
                        `}
                    />
                )}
                <div>
                    <PrimaryButton
                        css={css`
                            margin: 1rem 0rem;
                        `}
                        onClick={addTruck}
                    >
                        Add Truck
                    </PrimaryButton>
                    {trucks.map((truck) => (
                        <Truck
                            id={truck.truck_id}
                            truck={truck}
                            onSave={(truck) => {
                                const { __typename, ...modified } = truck;
                                setSavingTrucks(truck.truck_id, true);
                                upsertTruck({
                                    variables: {
                                        truck: modified,
                                    },
                                });
                            }}
                            onDelete={(truck) => {
                                setDeleteModal(truck)
                            }}
                            disabled={Boolean(savingTrucks[truck.truck_id])}
                        />
                    ))}
                </div>
            </div>
        </TabCard>
    );
};

export default Trucks;
