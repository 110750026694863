import { gql } from '@apollo/client';

export const INTEGRATION_FIELDS = gql`
    fragment IntegrationFields on integrations {
        transaction_id
        client_id
        job_id
        status
        edi_key
        artifact_key
        created_at
        last_modified_at
    }
`;
