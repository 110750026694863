import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import Password from '../Password';
import AccountDetails from './AccountDetails';
import LoadPreferences from './LoadPreferences';
import Warehouse from './Warehouse';
import Documents from './Documents';
import Teammates from '../Teammates';
import TariffTable from '../TariffTable';
import AccountCardSetup from '../ShipperAccount/AccountCardSetup';
import QboRefs from './QboRefs';
import api from '@/utilities/api';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { UserContext } from '@/components/App';
import { LIST_TARIFFS_BY_CLIENT_ID, UPSERT_DRIVER_TARIFFS, UPSERT_PRICING_OVERRIDES } from '../Tariffs/graphql';
import { AGGREGATE_RATING_BY_REVIEWEE } from '@/graphql/queries/ratings';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { UPDATE_CARRIER_DEFAULT_STATE_FILTER } from '../mutations';
import Trucks from '../Trucks';
import Stores from '../ShipperAccount/Stores';
import ShippingPartners from '../ShippingPartners';
import ServiceLevels from '../ServiceLevels';
import CustomTags from '../CustomTags';
import CustomMessages from '../CustomMessages';
import Surveys from '../Surveys';
import CustomDocuments from '../CustomDocuments';
import { useClientUser } from '@/hooks';

function CarrierAccount() {
    const { user, sidebarCollapsed, client: currentCarrier } = useContext(UserContext);
    const { client_id } = useClientUser();

    const [fetchTariffs, { data }] = useLazyQuery(LIST_TARIFFS_BY_CLIENT_ID, {
        variables: {
            client_id: client_id,
        },
    });

    const [upsertDriverTariff] = useMutation(UPSERT_DRIVER_TARIFFS, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: client_id,
                    },
                },
                (data) => {
                    let { driver_tariffs, ...rest } = data;
                    let clone = [...driver_tariffs];
                    const update = updated.returning[0];

                    const idx = clone.findIndex((m) => m.mapping_id === update.mapping_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        ...rest,
                        driver_tariffs: clone,
                    };
                }
            );
        },
    });

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: client_id,
                    },
                },
                (data) => {
                    let { overrides, ...rest } = data;
                    let clone = [...overrides];
                    const [update] = updated;

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        ...rest,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const [fetchOverallRating, { data: ratings }] = useLazyQuery(AGGREGATE_RATING_BY_REVIEWEE);

    useEffect(() => {
        if (client_id) {
            fetchOverallRating({ variables: { reviewee_id: client_id } });
            fetchTariffs();
        }
    }, [client_id]);

    const [tariffs, overrides, driverMappings] = useMemo(() => {
        if (data) {
            const { tariffs, overrides, driver_tariffs } = data;
            return [tariffs, overrides, driver_tariffs];
        }

        return [[], [], []];
    }, [data]);

    const rating = useMemo(() => {
        if (ratings?.results?.aggregate?.avg?.rating) {
            return ratings?.results?.aggregate?.avg?.rating;
        }

        return 0;
    }, [ratings]);

    return (
        <div className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive`}>
            <div className="d-flex justify-content-between my-3">
                <h2 className="  accountwidth" style={{ fontWeight: '700' }}>
                    My Account
                </h2>
                {rating >= 3 && (
                    <div className="align-self-center d-flex">
                        <h5 className="text-nowrap">My Rating: </h5>
                        <Tooltip
                            title={rating.toFixed(1) + ' / 5'}
                            arrow
                            PopperProps={{ style: { marginTop: '-10px' } }}
                        >
                            <div>
                                <Rating readOnly value={rating} precision={0.1} />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <Tabs defaultActiveKey="Account Details">
                <Tab tabClassName="tabLink" eventKey="Account Details" title="Account Details">
                    <AccountDetails currentCarrierRef={client_id} currentCarrier={currentCarrier} user={user} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Warehouse Details" title="Warehouse Details">
                    <Stores currentClientRef={client_id} currentClient={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Trucks" title="Trucks">
                    <Trucks client={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Load Preferences" title="Load Preferences">
                    <LoadPreferences currentCarrier={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Teammates" title="Teammates">
                    <Teammates
                        currentUser={{
                            business: currentCarrier.business_name,
                            email: currentCarrier.email,
                        }}
                        teamLeaderType="carrier"
                        userId={client_id}
                        tariffs={tariffs}
                        driverMappings={driverMappings}
                        callbacks={{ upsertDriverTariff }}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Custom Tags" title="Tags">
                    <CustomTags />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Custom Documents" title="Custom Documents">
                    <CustomDocuments client={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Service Types" title="Service Types">
                    <ServiceLevels />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Shippers" title="Shippers">
                    <ShippingPartners tariffs={tariffs} overrides={overrides} callbacks={{ upsertPo }} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Tariffs" title="Tariffs">
                    <TariffTable
                        client={currentCarrier}
                        tariffs={tariffs}
                        overrides={overrides}
                        driverMappings={driverMappings}
                        callbacks={{ upsertPo, upsertDriverTariff }}
                    />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Messages" title="Messages">
                    <CustomMessages />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Surveys" title="Surveys">
                    <Surveys />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Documents" title="Documents">
                    <Documents currentCarrier={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Billing" title="Billing">
                    <AccountCardSetup currentClient={currentCarrier} />
                    <QboRefs currentClient={currentCarrier} />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Password" title="Password">
                    <Password />
                </Tab>
            </Tabs>
        </div>
    );
}

export default CarrierAccount;
