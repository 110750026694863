import React, { useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';

import { colors } from '@/styles';
import { Table, TableBody, TableHead } from '@material-ui/core';

import HeaderCell from './table/HeaderCell';
import BodyCell from './table/BodyCell';
import HeaderRow from './table/HeaderRow';
import BodyRow from './table/BodyRow';
import { useJobColumns } from './columns';
import { UserContext } from '../App';

const JobsTable = ({ jobs }) => {
    const navigate = useNavigate();
    const { admin_clients, clientId, setClientId } = useContext(UserContext);

    const filtered = useMemo(() => {
        return jobs.filter((job) => {
            return (
                job.status !== 'COMPLETE' ||
                job.orders.some((order) => {
                    return [
                        'pending_confirmation',
                        'processing',
                        'incomplete',
                        ...(order.source_form === 'APIv2' ? [] : ['duplicate']),
                    ].includes(order.order_status);
                })
            );
        });
    }, [jobs]);

    const accounts = useMemo(() => {
        if (clientId) return [];
        return admin_clients || [];
    }, [clientId, admin_clients]);
    const TABLE_COLUMNS = useJobColumns(accounts);

    const { headerGroups, prepareRow, rows } = useTable(
        {
            columns: TABLE_COLUMNS,
            data: filtered,
            initialState: { sortBy: [{ id: 'created_at', desc: true }] },
            getRowId: (job) => job.job_id,
        },
        useSortBy
    );

    return (
        <Table
            css={css`
                background: ${colors.white.primary};
            `}
        >
            <TableHead>
                {headerGroups.map((group, idx) => (
                    <HeaderRow key={idx}>
                        {group.headers.map((col) => (
                            <HeaderCell key={col.id} cell={col} align={col.align} />
                        ))}
                    </HeaderRow>
                ))}
            </TableHead>

            <TableBody>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <BodyRow
                            key={row.original.job_id}
                            onClick={() => {
                                if (!clientId) {
                                    setClientId(row.original.client_id);
                                }
                                navigate(`/job/${row.original.job_id}`);
                            }}
                        >
                            {row.cells.map((cell) => (
                                <BodyCell key={cell.column.id} cell={cell} align={cell.column.align} />
                            ))}
                        </BodyRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default JobsTable;
